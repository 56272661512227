import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import QuizIcon from "@mui/icons-material/Quiz";
import Box from "@mui/material/Box";
import {Button, Grid} from "@material-ui/core";
import {Dialog} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {isMobile} from "react-device-detect";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import {Close} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {BASE_URL, baseAxiosCondidature, Version} from "../../repository/baseAxios";
import Swal from "sweetalert2";
import useStyles from "../../pages/login/styles";
import DialogActions from "@mui/material/DialogActions";


export default function HelpEtud(props) {
    const [openTo, setOpenTo] = useState(false);
    const [loadingReset, setLoadingReset] = useState(false);
    const [msgError, setMsgError] = useState(false);
    const [nomFr, setNomFr] = useState('');
    const [nomFrError, setNomFrError] = useState(false);
    const [prenomFr, setPrenomFr] = useState('');
    const [prenomFrError, setprenomFrError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");

    const is_arabic = (value) => {
        var arabic = /[\u0600-\u06FF\u0750-\u077F]/;
        var string = value;
        if (arabic.test(string)) {
            return true
        } else {
            return false
        }
    }
    let {t} = useTranslation();
    const reclamation = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/commun/reclamation`, params)

            .then(async res => {
                if (res.data.status === 200) {
                    setNomFr("")
                    setPrenomFr("")
                    setEmail("")
                    setMsg("")
                    setOpenTo(false)
                    await Swal.fire({
                        title: "Votre demande sera traitée dans les meilleurs délais",
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    })
                    setLoadingReset(false)
                } else if (res.data.status === -1) {
                    setLoadingReset(false)
                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })


                }


            })
    }
    var classes = useStyles();
    const help = () => {

        setNomFrError(false);
        setprenomFrError(false);
        setEmailError(false);
        setMsgError(false);

        if (nomFr === '' ) {
            setNomFrError(true);
            return false;
        }
        if (prenomFr === '') {
            setprenomFrError(true);
            return false;
        }

        if (email === '' || is_arabic(email)) {
            setEmailError(true);
            return false;
        }
        if (msg === '') {
            setMsgError(true);
            return false;
        }
        setLoadingReset(true);
        var data=new FormData();
        data.set('nom',nomFr);
        data.set('prenom',prenomFr);
        data.set('email',email);
        data.set('reclamation',msg);
        reclamation(data)

    }
    return (
        <>
        <Fab variant="extended"   color={props.color ? props.color : "primary"} style={{textAlign:"center",width:"100%"}} onClick={()=> {
            setOpenTo(true)
        }}>
            <QuizIcon sx={{ mr: 1 ,}} />
            {t("besoin_daide")}
        </Fab>
    <Box component="form"  spacing={3} >
        <Grid container spacing={3}>
            <Dialog
                style={{padding:20}}
                open={openTo}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent
                    style={{width : isMobile ? "" : "600px" }}
                >
                    <Grid item xs={12} sm={12} >

                        <TextField

                            required
                            onChange={(event) => {
                                setNomFr(event.target.value)
                            }}
                            value={nomFr || ""}
                            error={nomFrError}
                            helperText={nomFrError ? t('champ_obligatoire_fr') : ' '}
                            id="nom_fr"
                            name="nom_fr"
                            label={t('nom_fr')}
                            fullWidth
                            autoComplete="off"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} >

                        <TextField

                            required
                            onChange={(event) => {
                                setPrenomFr(event.target.value)
                            }}

                            value={prenomFr || ""}
                            error={prenomFrError}
                            helperText={prenomFrError ? t('champ_obligatoire_fr') : ' '}
                            id="prenom_fr"
                            name="prenom_fr"
                            label={t('prenom_fr')}
                            fullWidth
                            autoComplete="off"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} >

                        <TextField
                            required
                            id="email"
                            value={email || ""}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            error={emailError}
                            helperText={emailError ? t('champ_obligatoire') : ' '}
                            name="email"
                            label={t('email')}
                            fullWidth
                            autoComplete="off"
                            variant="outlined"
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} >

                        <TextField
                            required
                            id="outlined-multiline-static"
                            label={"Détails du problème"}
                            fullWidth
                            multiline
                            rows="4"
                            value={msg || " "}
                            onChange={(e) => {
                                setMsg(e.target.value)
                            }}
                            placeholder={"détails du problème"}
                            error={msgError}
                            helperText={msgError ? t('champ_obligatoire') : ' '}
                            variant="outlined"
                            name="msg"
                        />
                    </Grid>



                    <DialogActions style={{textAlign:"center"}}>

                                          <Button

                                variant="contained"
                                style={{background:"#007fa4"}}
                                size="small"
                                endIcon={<Close />}
                                onClick={()=>{ setOpenTo(false)}}

                            >

                                    {t('fermer')}

                            </Button>
                        <Button
                            onClick={help}
                            variant="contained"
                            color="primary"

                            size="small"
                            endIcon={<SendIcon/>}
                            disabled={loadingReset}

                        >
                            <div className={classes.divWith} >

                                {t('envoyer')}
                            </div>
                        </Button>
                    </DialogActions>


                </DialogContent>
            </Dialog>
        </Grid>
    </Box>
        </>

    );
}
