import React, {useState} from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Flag from 'react-world-flags'
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Copyright} from "../../context/UserContext";
import Grid from "@mui/material/Grid";
import {BASE_URL, baseAxios, Version} from "../../repository/baseAxios";
import {Alert, Stack} from "@mui/material";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {useTranslation} from "react-i18next";
import i18n from "../../multilingue/i18n";
import Swal from "sweetalert2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ModalPolicy from "../Widget/Cndp/ModalPolicy";
import HomeIcon from "@mui/icons-material/Home";


const theme = createTheme();

function PrienscriptionAccount() {

    const { t } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }
    const [loading, setloading] = useState(false);

    const [cne, setCne] = useState('')
    const [cin, setCin] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirme, setPasswordConfirme] = useState('')
    const [email, setEmail] = useState('')

    const [date_nais, setDn] = useState('')
    const [cneError, setCneError] = useState(false)
    const [cinError, setCinError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [passwordConfirmeError, setPasswordConfirmeError] = useState(false)

    const [DnError, setDnError] = useState(false)
    const [visible, setvisible] = useState(false)
    const [DataErreur, setDataErreur] = useState(false)
    const [MsgErreur, setMsgErreur] = useState('Erreur !')
    const [checked, setChecked] = React.useState(false);

    const history=useHistory();

    const handlformsubmit = (event) => {
        event.preventDefault();
        setEmailError(false);
        setPasswordError(false);
        setPasswordConfirmeError(false);
        setDataErreur(false)
        if (email === '') {
            setEmailError(true);
            return false;
        }
        if (password === '') {
            setPasswordError(true);
            return false;
        }
        if (password.length < 6) {
            setPasswordError(true);
            setDataErreur(true)
            setMsgErreur("Le mot de passe doit contenir au moins 6 caractères.");
            return false;
        }
        if (passwordConfirme === '') {
            setPasswordConfirmeError(true);
            return false;
        }
        if ( !(password===passwordConfirme)){
            setDataErreur(true)
            setPasswordConfirmeError(true);
            setMsgErreur('confirmation mot de passe est incorrect !')
            return  false;
        }

        const data = new FormData(event.currentTarget);
        if ( !checked){
            setDataErreur(true)
            setMsgErreur("Erreur ! Merci de d'accepte les Conditions générales d'utilisation")
            document.getElementById("accept").style.border="1px red solid"
            return  false;

        }else {
            setDataErreur(false)
            document.getElementById("accept").style.border="none"
            data.append("accept_cndp","yes");
        }

        setloading(true);

        CreateAccount(data);



    }


    const RegisterEtudiante = (params) => {
       baseAxios.post(`${BASE_URL}/inscription/${Version}/preinscription/etudiant/check_by_massar_date_n`, params)

            .then(res => {

                    if (res.data.status === 200 && res.data.params.etudiant.code_massar) {

                        localStorage.setItem('code_massar', res.data.params.etudiant.code_massar);

                        setvisible(true)
                       // document.getElementById("code_massar").readOnly = true;
                      //  document.getElementById("date_naissance").readOnly = true;
                        document.getElementById("date_naissance").style = "background-color : #F5F5F5 ; ";
                        document.getElementById("code_massar").style = "background-color : #F5F5F5 ; ";

                        setloading(false);
                    } else if (res.data.status === -1) {

                        setvisible(false)
                        setDataErreur(true)
                        setMsgErreur(t('error_cne_ddn'))
                        setloading(false);

                        return false;
                    }else if (res.data.status === -2) {

                        setvisible(false)
                        setDataErreur(true)
                        setMsgErreur(t('compte_deja_existe'))
                        setloading(false);

                        return false;
                    }
                    else{
                        Swal.fire({
                            title: "Erreur !",
                            icon: 'error',
                            confirmButtonText: 'Ok',
                            confirmButtonColor:'red',
                            color : 'red'
                        } )
                        setloading(false);

                    }




            })
    }
    const CreateAccount = (params) => {
         baseAxios.post(`${BASE_URL}/inscription/${Version}/preinscription/etudiant/create_account`, params)

            .then(res => {


                if (res.data.status === 200 && res.data.params.access_token ){

                    localStorage.setItem('token', res.data.params.access_token);
                    localStorage.setItem('code_massar', cne);

                    //userDispatch({ type: "LOGIN_SUCCESS" });
                    //window.location.href="/app"
                    window.location.reload();
                    setloading(false);

                } else if (res.data.status === -1) {
                    setDataErreur(true)
                    setMsgErreur(res.data.message)
                    setloading(false);
                    return false;
                }else{
                    Swal.fire({
                        title: "Erreur !",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor:'red',
                        color : 'red'
                    } )
                    setloading(false);
                }


            })

    }
    const handleSubmit = (event, props) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setCneError(false)
        setCinError(false)
        setDnError(false)

        if (cne === '') {
            setCneError(true)
            return false

        }

        if (cin === '') {
            setCinError(true)
            return false

        }

        if (date_nais === '') {
            setDnError(true)
            return false
        }
        if ( !checked){
            setDataErreur(true)
            setMsgErreur("Erreur ! Merci  d'accepte les Conditions générales d'utilisation")
            document.getElementById("accept").style.border="1px red solid"
            return  false;
        }else{
            setDataErreur(false)
            document.getElementById("accept").style.border="none"

        }
        setloading(true);

        RegisterEtudiante(data)


    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Stack spacing={2} direction="row">
                    <Button variant={"contained"} color={"warning"} onClick={()=>{history.push('/')}} sx={{ textAlign: "left",mt:5}} size="medium" >
                        <HomeIcon />
                    </Button>
                </Stack>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack spacing={2} direction="row" sx={{ marginBottom: 8 }} >
                        <Button variant="contained"  onClick={()=>changeLanguage('en')}><Flag code="GB_NIR" height="16" /></Button>
                        <Button variant="contained"  onClick={()=>changeLanguage('fr')}><Flag code="GF" height="16" /> </Button>
                        <Button variant="contained"  onClick={()=>changeLanguage('ar')}><Flag code="MA" height="16" /> </Button>
                    </Stack>
                    {DataErreur ? <Alert variant="filled" severity="error">
                        {MsgErreur}
                    </Alert> : ("")

                    }
                    <Avatar sx={{m: 1, bgcolor: 'success.main', fontSize: 'large'}}>
                        <PersonIcon/>

                    </Avatar>

                    <Typography component="h1" fontWeight={"bold"}  variant="h5" color="primary" >
                        {t("cree_un_compte") }
                    </Typography>
                    <Box component="form" onSubmit={!visible ? handleSubmit : handlformsubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            required
                            style={{marginTop:10}}
                            onChange={(e) => setCne(e.target.value)}
                            margin="normal"
                            fullWidth
                            id="code_massar"
                            helperText={cne === "" ? t('champ_obligatoire') : ' '}
                            label={t('code_massar_cne')}
                            name="code_massar"
                            autoComplete="off"
                            autoFocus
                            error={cneError}

                        />
                        <TextField
                            required
                            style={{marginTop:10}}
                            onChange={(e) => setCin(e.target.value)}
                            margin="normal"
                            fullWidth
                            id="cin"
                            helperText={cin === "" ? t('champ_obligatoire') : ' '}
                            label={t('cin')}
                            name="cin"
                            autoComplete="off"
                            autoFocus
                            error={cinError}

                        />
                        <TextField
                            style={{marginTop:10}}
                            required
                            id="date_naissance"
                            name="date_naissance"
                            label={t('date_naissance')}
                            fullWidth
                            type={"date"}
                            inputProps={{  max: "2020-01-01" }}

                            InputLabelProps={{ shrink: true }}
                            autoComplete="off"
                            variant="outlined"
                            onChange={(e) => setDn(e.target.value)}
                            helperText={date_nais === "" ? t('champ_obligatoire') : ' '}
                            error={DnError}
                        />
                        {visible && (
                            <Grid> <TextField
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                margin="normal"
                                fullWidth
                                id="email"
                                helperText={email === "" ? t('champ_obligatoire') : ' '}
                                label={t('email')}
                                name="email"
                                autoComplete="off"
                                autoFocus
                                error={emailError}

                            />
                                <TextField
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    margin="normal"
                                    fullWidth
                                    type={"password"}
                                    id="password"
                                    helperText={password === "" ? t('champ_obligatoire') : ' '}
                                    label={t('mot_de_passe')}
                                    name="password"
                                    autoComplete="off"
                                    autoFocus
                                    error={passwordError}

                                />
                                <TextField
                                    required
                                    onChange={(e) => setPasswordConfirme(e.target.value)}
                                    margin="normal"
                                    fullWidth
                                    id="password_confirmation"
                                    helperText={passwordConfirme === "" ? t('champ_obligatoire') : ' '}
                                    label={t('mot_de_passe_confirmation')}
                                    name="password_confirmation"
                                    autoComplete="off"
                                    autoFocus
                                    type="password"
                                    error={passwordConfirmeError}

                                />
                            </Grid>
                                )
                                }
                        <FormControlLabel
                            value="top"

                            style={ { marginTop:7,marginBottom:5,float:"left" }}
                            control={<Checkbox value="remember" color="primary" />}
                            checked={checked}  id={"accept"} onChange={(event)=>{setChecked(event.target.checked)}}
                        />
                       <ModalPolicy />

                                {visible ? (     <Button
                                    type="submit"
                                    color="success"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                    disabled={loading}
                                >
                                    Envoyer
                                </Button>
                                   ) : (   <Grid container>
                                    <Button
                                        type="submit"
                                        color="success"
                                        fullWidth
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                        disabled={loading}
                                    >
                                        {t('connexion')}
                                    </Button>

                                    <Grid item  xs={12} sm={12} dir={i18n.language==="ar"   ?"rtl"  : "ltr " }>
                                        <Button

                                            onClick={() =>
                                                history.push("/Prienscription")
                                            }
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            fullWidth
                                        >
                                                { t("vous_avez_deja_un_compte")  }
                                        </Button>
                                    </Grid>

                                </Grid>)}
                    </Box>
                </Box>

            </Container>

                <Copyright sx={{mt: 4, mb: 4}}/>


        </ThemeProvider>

    );

}

export default PrienscriptionAccount;