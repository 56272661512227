import * as React from "react";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import i18n from "../../../multilingue/i18n";
import {
    Alert, AlertTitle,
    CircularProgress,
    Divider,

} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {isMobile} from "react-device-detect";
import Typography from "@mui/material/Typography";
import LogoTitle from "../../../images/logo_title.png";
import Avatar from "@mui/material/Avatar";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import DescriptionIcon from '@mui/icons-material/Description';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import eduVPN from "../../../images/eduVPN.png"
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import VpnLockIcon from '@mui/icons-material/VpnLock';
import {BASE_URL, baseAxiosService, Version} from "../../../repository/baseAxios";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Swal from "sweetalert2";
export default function ServiceTools(props) {


    const [loadingContent, setLoadingContent] = useState(true);

    const {t} = useTranslation();

    const [doctorat, setDoctorat] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };
    const info_doc = (param) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/info_doc`)
            .then(res => {
                if (res.data.status === 200) {
                    if ( res.data.params.ip ) {
                        setDoctorat(true)

                    }else {
                        setDoctorat(false)
                    }
                    setLoadingContent(false)
                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: 'ERROR !',
                        text: 'Merci de réssayée au plus tard !',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                } else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                }


            }).catch(function (error) {
            if (error.response) {
                localStorage.clear();
                window.location.href = "/login"
            }
        })
    }
    useEffect(() => {
        info_doc();
    }, []);

    const theme = createTheme();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>

            {isMobile && (
                <Typography dir={i18n.language === "ar" ? "rtl" : "ltr "} variant={"h6"} fontFamily={"Open Sans"}
                            fontWeight={"bold"} color={"primary"}>
                    <VpnLockIcon/>  eduVPN
                </Typography>)}
            <Container maxWidth={isMobile ? "lg" : "md"} sx={{mb: 4, p: 0}}>

                <Paper
                    variant="outlined"
                    sx={{my: {xs: 1, md: 1}, p: {md: 3}}}
                    dir={i18n.language === "ar" ? "rtl" : "ltr "}
                >
                    <Grid container spacing={3} mt={1} >
                        {!isMobile ? <>  <Grid item align={i18n.language === "ar" ? "right" : "left"} xs={8} sm={8}>
                                <Typography variant={"h5"} fontFamily={"Open Sans"} fontWeight={"bold"} color={"primary"}>
                                    <VpnLockIcon/>  eduVPN
                                </Typography>

                            </Grid>
                                <Grid item align={i18n.language === "ar" ? "left" : "right"} xs={4} sm={4}>
                                    <img
                                        style={{width: 90, height: 60}}

                                        alt="Etablissement logo"
                                        src={LogoTitle}

                                    />
                                </Grid> </> :
                            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                <Grid item align={"centre"} alignContent={"center"} xs={12} sm={12}>
                                    <Avatar
                                        sx={{width: 200, height: 60}}
                                        alt="Etablissement logo"
                                        src={LogoTitle}
                                        variant="square"

                                    />
                                </Grid>
                            </Grid>}
                    </Grid>
                    <Divider sx={{mt: 3, mb: 3}} variant="middle" color={"orange"}/>

                    { !loadingContent ?
                        ( doctorat ?
                            <Grid container spacing={2} paddingX={2}>


                                    <Box mt={2} ml={3}>
                                        <Tabs value={tabIndex} onChange={handleTabChange}>
                                            <Tab label="eduVPN"  style={{background:"#f7941f"}}/>
                                     {/*       <Tab label="Tab 2" />
                                            <Tab label="Tab 3" />*/}
                                        </Tabs>
                                    </Box>
                                    <Box sx={{ padding: 2 }}>
                                        {tabIndex === 0 && (<>
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} sm={4}>
                                                <Card >
                                                    <CardMedia
                                                        sx={{ height: 140 }}
                                                        image={eduVPN}
                                                        title="eduvpn"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h6" component="div">
                                                            {t('eduvpn_d_win')}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {t('eduvpn_win')}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions style={{textAlign:"center"}}>

                                                        <Button size="small" variant={"contained"}  endIcon={<DownloadForOfflineIcon />} onClick={()=>{ window.open("https://app.eduvpn.org/windows/eduVPNClient_latest.exe")}}>{t('telecharger')}</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Card >
                                                    <CardMedia
                                                        sx={{ height: 140 }}
                                                        image={eduVPN}
                                                        title="eduvpn"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h6" component="div">
                                                            {t('eduvpn_d_mac')}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {t('eduvpn_mac')}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>

                                                        <Button size="small" variant={"contained"} onClick={()=>{ window.open("https://apps.apple.com/app/eduvpn-client/id1317704208?mt=12")}} endIcon={<DownloadForOfflineIcon />}>{t('telecharger')}</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Card >
                                                    <CardMedia
                                                        sx={{ height: 140 }}
                                                        image={eduVPN}
                                                        title="eduvpn"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h6" component="div">
                                                            {t('eduvpn_d_linux')}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {t('eduvpn_linux')}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions >

                                                        <Button size="small" variant={"contained"} onClick={()=>{ window.open("https://python-eduvpn-client.readthedocs.io/")}}   endIcon={<DownloadForOfflineIcon />}>{t('telecharger')}</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                            </>
                                        )}
                                      {/*  {tabIndex === 1 && (
                                            <Box>
                                                <Card sx={{ maxWidth: 345 }}>
                                                    <CardMedia
                                                        sx={{ height: 140 }}
                                                        image="/static/images/cards/contemplative-reptile.jpg"
                                                        title="green iguana"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            Lizard
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Lizards are a widespread group of squamate reptiles, with over 6,000
                                                            species, ranging across all continents except Antarctica
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button size="small">Share</Button>
                                                        <Button size="small">Learn More</Button>
                                                    </CardActions>
                                                </Card>
                                            </Box>
                                        )}
                                        {tabIndex === 2 && (
                                            <Box>
                                                <Card sx={{ maxWidth: 345 }}>
                                                    <CardMedia
                                                        sx={{ height: 140 }}
                                                        image="/static/images/cards/contemplative-reptile.jpg"
                                                        title="green iguana"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            Lizard
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Lizards are a widespread group of squamate reptiles, with over 6,000
                                                            species, ranging across all continents except Antarctica
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button size="small">Share</Button>
                                                        <Button size="small" variant={"contained"}>Learn More</Button>
                                                    </CardActions>
                                                </Card>
                                            </Box>
                                        )}*/}
                                    </Box>



                            </Grid>
                                :
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >
                                <Alert variant="filled"  severity="warning">
                                    <AlertTitle> {t('tools_doc_msg')} </AlertTitle>
                                </Alert>
                            </Grid>


                        ) : (  <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                                <CircularProgress/>
                            </Grid>
                        </Grid>)
                    }


                </Paper>
            </Container>
        </ThemeProvider>
    );
}
