import React,{useState,useEffect} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import DonneeCivilOne from "../preinscriptionSteps/steps/DonneeCivilOne";
import DonneeCivilTwo from "../preinscriptionSteps/steps/DonneeCivilTwo";
import CouvMedicale from "../preinscriptionSteps/steps/CouvMedicale";
import Baccalaureat from "../preinscriptionSteps/steps/Baccalaureat";
import ValidatePrienscription from "../preinscriptionSteps/steps/ValidatePrienscription";
import { isMobile} from 'react-device-detect';
import ChoixFilieres from "../preinscriptionSteps/steps/ChoixFilieres";
import {CircularProgress, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import i18n from "../../multilingue/i18n";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";

import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {BASE_URL, baseAxios, baseAxiosService, Version} from "../../repository/baseAxios";
import Swal from "sweetalert2";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {useHistory} from "react-router-dom";
import {Copyright} from "../../context/UserContext";
const theme = createTheme();

var notes_js=[];
var modules =[];
var modules_second_partie=[];
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
    })
}));
export default function DashboardServiceEnLigne() {
    const [expanded, setExpanded] = React.useState(true);
    const [expandedtwo, setExpandedtwo] = React.useState(true);
    const [loadingContent, setLoadingContent] = React.useState(true);

    var history = useHistory()
    const [notes, setNotes] = useState({
        inscription:{},
        first_partie : {},
        second_partie :{}
    });

    const [hasnote, setHasNote] = useState(true);
    const [msgError, setMsgError] = useState("");
    const [scrolTable, setScrolTable] = useState("initial");
    var {t}=useTranslation();
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const handleExpandtwoClick = () => {
        setExpandedtwo(!expandedtwo);
    };
    useEffect(() => {

        // global

            let isMounted = true;
            let token = localStorage.getItem('token');
            let  redirectService =localStorage.getItem('redirectService');
            if (redirectService!=="yes" && token ){

                isMounted = false;

            }

                if (!isMounted) {

                    history.push('/app/compte')
                }

        let scroll =isMobile ?"scroll":"initial";
        setScrolTable(scroll)
        const get_notes = () => {


            baseAxiosService.post(`${BASE_URL}/espace-etudiant/${Version}/get_notes`)

                .then(async res => {

                    if (res.data.status === 200 && res.data.params) {
                        notes_js=res.data.params
                        modules=res.data.params.first_partie.modules
                        modules_second_partie=res.data.params.second_partie.modules
                        setNotes(res.data.params)
                        setHasNote(true)
                        setLoadingContent(false)
                    } else if (res.data.status === -1) {

                       setHasNote(false)
                        setMsgError(res.data.message)
                        setLoadingContent(false)
                    }else if (res.data.status === 403) {
                        localStorage.clear();
                        window.location.href = "/login"

                    }


                })
        }

        get_notes();

    }, []);

    return (

        <ThemeProvider theme={theme}>
            { !loadingContent ? (hasnote  ? <Grid


                alignItems="center"
                justify={"center"}
                
            >
                <Typography variant={"h5"} style={{textAlign: "center", marginBottom: 10}}> <span
                    className="fa fa-bullhorn"
                    style={{fontSize: "16px", color: "white", marginRight: "1%"}}
                ></span>
                    {t('consultation_notes')} <strong
                        style={{color: "red"}}> {notes.inscription.annee_universitaire}</strong>
                </Typography>

                <Grid item xs={12} sm={12} style={{
                    marginTop: 14,
                    background: "#f49524",
                    textAlign: "center",
                    padding: 13,
                    marginBottom: 14,
                    color: 'white',
                    textTransform: "uppercase"
                }}>
                    <Typography variant={"h6"}><AccountBalanceIcon
                        color={"primary"}/> : <strong> {notes.inscription.etablissement}   </strong>

                    </Typography>
                    <Typography variant={"h6"}><AccountBalanceWalletIcon
                        color={"primary"}/> : <strong> {notes.inscription.filiere}   </strong>

                    </Typography>
                    <Typography color={"primary"} fontWeight={"bold"} variant={"h6"}>Année universitaire : <strong
                        style={{color: "white"}}>  {notes.inscription.annee_universitaire}   </strong>

                    </Typography>

                </Grid>
                <Grid item xs={12} sm={9} style={{marginBottom: 20}}>


                    <Card>
                        <CardHeader style={{background: "#5090C1", textAlign: "center", color: "white"}}
                                    action={

                                        <ExpandMore
                                            expand={expanded}
                                            onClick={handleExpandClick}
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon/>
                                        </ExpandMore>

                                    }
                                    title={"Semestre :" + notes.first_partie.semestre
                                    }

                        />

                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent style={{ width:"100%",overflow: scrolTable}}>
                                <Typography variant={"h5"} className="widget-title">Semestre : {
                                    notes.first_partie.semestre
                                }
                                </Typography>
                                <table border="1"
                                       style={{textAlign: "center", border: "2px solid #ddd",
                                           borderCollapse: "collapse", fontSize: 20,width:"100%",overflow: scrolTable}}
                                >
                                    <thead style={{ border: "2px solid #ddd",
                                        borderCollapse: "collapse"}} >
                                    <tr>
                                        <th style={{padding:15}}>Module</th>
                                        <th style={{padding:15}} >Note / 20</th>
                                        <th style={{padding:15}} >Résultat</th>
                                    </tr>
                                    </thead>
                                    <tbody  >
                                    {
                                        Object.values(modules).map((note, index) => {
                                            return (
                                                <tr key={index} style={{ border: "2px solid #ddd",
                                                    borderCollapse: "collapse"}}>

                                                    {!note.element && (
                                                        <td aria-colspan="2" style={{textAlign: "left", width: "90%",padding:15}}
                                                        >
                                                            <span style={{
                                                                fontWeight: "bold",
                                                                color: "#f29100"
                                                            }}>Module: </span> {
                                                            note.module
                                                        }
                                                        </td>)}

                                                    {note.element && (<td aria-colspan="1" style={{
                                                        textAlign: "left",
                                                        width: "10%",
                                                        padding: 25,
                                                        paddingLeft:50
                                                    }}>
                                                            <span style={{
                                                                fontWeight: "bold",
                                                                color: "#21b3ef"
                                                            }}> Élément {
                                                                note.index_elemenet
                                                            }: </span> {note.element}
                                                    </td>)
                                                    }

                                                    <td style={{width: "15%"}}>
                                                        {note.note}
                                                    </td>
                                                    <td style={{width: "15%"}}>
                                                        {note.etat_valide}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <th aria-colspan="2">
                                        </th>
                                        <td style={{
                                            background: "#21b3ef",
                                            fontWeight: "bold",
                                            color: "white",
                                            padding: 8
                                        }}>
                                            Moyenne du Semestre
                                        </td>
                                        <td style={{
                                            background: "#21b3ef",
                                            fontWeight: "bold",
                                            color: "white",
                                            padding: 8
                                        }}>
                                            <b>{notes.first_partie.moyen}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th aria-colspan="2">
                                        </th>
                                        <td style={{
                                            background: "#21b3ef",
                                            fontWeight: "bold",
                                            color: "white",
                                            padding: 8
                                        }}>
                                            Résultat du Semestre
                                        </td>
                                        <td style={{
                                            background: "#21b3ef",
                                            fontWeight: "bold",
                                            color: "white",
                                            padding: 8
                                        }}>
                                            <b>{notes.first_partie.etat_valide}</b>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </CardContent>

                        </Collapse>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={9} style={{marginBottom: 20}}>


                    <Card>
                        <CardHeader style={{background: "#5090C1", textAlign: "center", color: "white"}}
                                    action={

                                        <ExpandMore
                                            expand={expandedtwo}
                                            onClick={handleExpandtwoClick}
                                            aria-expanded={expandedtwo}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon/>
                                        </ExpandMore>

                                    }
                                    title={"Semestre :" + notes.second_partie.semestre
                                    }

                        />

                        <Collapse in={expandedtwo} timeout="auto" unmountOnExit>
                            <CardContent style={{ width:"100%",overflow: scrolTable}}>
                                <Typography variant={"h5"} className="widget-title">Semestre : {
                                    notes.second_partie.semestre
                                }
                                </Typography>
                                <table border="1"
                                       style={{textAlign: "center", border: "2px solid #ddd",
                                           borderCollapse: "collapse", fontSize: 20,width:"100%",overflow: scrolTable}}
                                      >
                                    <thead style={{ border: "2px solid #ddd",
                                        borderCollapse: "collapse"}} >
                                    <tr>
                                        <th style={{padding:15}}>Module</th>
                                        <th style={{padding:15}} >Note / 20</th>
                                        <th style={{padding:15}} >Résultat</th>
                                    </tr>
                                    </thead>
                                    <tbody  >
                                    {
                                        Object.values(modules_second_partie).map((note, index) => {
                                            return (
                                                <tr key={index} style={{ border: "2px solid #ddd",
                                                    borderCollapse: "collapse"}}>

                                                    {!note.element && (
                                                        <td aria-colspan="2" style={{textAlign: "left", width: "90%",padding:15}}
                                                        >
                                                            <span style={{
                                                                fontWeight: "bold",
                                                                color: "#f29100"
                                                            }}>Module: </span> {
                                                            note.module
                                                        }
                                                        </td>)}

                                                    {note.element && (<td aria-colspan="1" style={{
                                                        textAlign: "left",
                                                        width: "10%",
                                                        padding: 25,
                                                        paddingLeft:50
                                                    }}>
                                                            <span style={{
                                                                fontWeight: "bold",
                                                                color: "#21b3ef"
                                                            }}> Élément {
                                                                note.index_elemenet
                                                            }: </span> {note.element}
                                                    </td>)
                                                    }

                                                    <td style={{width: "15%"}}>
                                                        {note.note}
                                                    </td>
                                                    <td style={{width: "15%"}}>
                                                        {note.etat_valide}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <th aria-colspan="2">
                                        </th>
                                        <td style={{
                                            background: "#21b3ef",
                                            fontWeight: "bold",
                                            color: "white",
                                            padding: 8
                                        }}>
                                            Moyenne du Semestre
                                        </td>
                                        <td style={{
                                            background: "#21b3ef",
                                            fontWeight: "bold",
                                            color: "white",
                                            padding: 8
                                        }}>
                                            <b>{notes.second_partie.moyen}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th aria-colspan="2">
                                        </th>
                                        <td style={{
                                            background: "#21b3ef",
                                            fontWeight: "bold",
                                            color: "white",
                                            padding: 8
                                        }}>
                                            Résultat du Semestre
                                        </td>
                                        <td style={{
                                            background: "#21b3ef",
                                            fontWeight: "bold",
                                            color: "white",
                                            padding: 8
                                        }}>
                                            <b>{notes.second_partie.etat_valide}</b>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </CardContent>

                        </Collapse>
                    </Card>

                </Grid>


            </Grid> :  <Grid item xs={12} sm={12} style={{marginTop: 14,background:"#FDEDED",textAlign:"center",padding:13, marginBottom: 14}}>
                <ErrorOutlineIcon sx={{
                    color:'red',fontSize:50
                }} />
                <Typography  variant={"h5"}>
                    <strong> {msgError} </strong>
                </Typography>

            </Grid>) : (
                <Box sx={{display: 'flex'}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Box>
            )

            }
        </ThemeProvider>
    );
}
