import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormGroup from '@mui/material/FormGroup';

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextFeildAR from "../../../components/Widget/TextFeildAR/TextFeildAR";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import {useEffect, useState} from "react";
import {BASE_URL, baseAxiosService, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import SendIcon from '@mui/icons-material/Send';
import {useTranslation} from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import i18n from "../../../multilingue/i18n";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";
import {Autocomplete, CircularProgress, Select} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

var NationalitesJs = [];

export default function ReinDonneeCivilOne(props) {
    const [loadingContent, setloadingContent] = useState(true);
    const [isEtranger, setIsEtranger] = useState(false)
    const [CodeMassar, setCodeMassar] = useState('');
    const [cni, setCni] = useState("");
    const [cniError, setCniError] = useState(false);
    const [ipsValide, setIpsValide] = useState(false);
    const [carteSejour, setCarteSejour] = useState(false);
    const [carteSejourError, setCarteSejourError] = useState(false);
    const [nomprenomfr, setNomPrenomFr] = useState('');
    const [dtn, setDtn] = useState('');
    const [lieuNaisFR, setlieuNaisFR] = useState('');
    const [lieuNaisAR, setlieuNaisAR] = useState('');
    const [nomFr, setnomFr] = useState('');
    const [prenomFr, setprenomFr] = useState('');
    const [nomAr, setnomAr] = useState('');
    const [prenomAr, setprenomAr] = useState('');
    const [nomprenomAr, setNomPrenomAr] = useState('');
    const [lieuNaisFRError, setlieuNaisFRError] = useState(false);
    const [lieuNaisARError, setlieuNaisARError] = useState(false);
    const [nomFrError, setnomFrError] = useState(false);
    const [prenomFrError, setprenomFrError] = useState(false);
    const [nomArError, setnomArError] = useState(false);
    const [prenomArError, setprenomArError] = useState(false);
    const [nomprenomArError, setNomPrenomArError] = useState(false);
    const [sexe, setSexe] = useState('');
    const [loading, setloading] = useState(false);
    const [checkedAr, setCheckedAr] = React.useState(false);
    const [NationaliteValue, setNationaliteValue] = useState({});
    const [etatCivil, setEtatCivil] = useState("c");
    const [etatCivilError, setEtatCivilError] = useState(false);
    const handleChange = (event) => {
        if (prenomAr && nomAr && nomprenomAr) {
            setNomPrenomAr("")
            setnomAr("")
            setprenomAr("")
        }
        setCheckedAr(event.target.checked);

    };
    const {t} = useTranslation();
    const is_arabic = (value) => {
        var arabic = /[\u0600-\u06FF\u0750-\u077F]/;
        var fr = /[a-zA-Z]/;

        var string = value;
        if (arabic.test(string) && !fr.test(string)) {
            return true
        } else {
            return false
        }
    }
    const ValideCin = (value) => {
        var letters = /^[A-Z]{1,2}?[0-9]{1}[0-9]{1,7}[A-Z]{0,1}$/;
        let str = value.replace(/\s/g, '');
        if (letters.test(str)) {
            return true;
        } else {
            return false;
        }
    }

    const is_french = (value) => {
        var letters =  /^([a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+\s)*[a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/;

        if (letters.test(value)) {
            return true;
        } else {
            return false;
        }

    }
    useEffect(() => {
        const list_nationalite_l = () => {
            baseAxiosService.get(`${BASE_URL}/inscription/${Version}/inscription/nationalities`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const allNationalites = res.data.params;
                        NationalitesJs = allNationalites.map(function (item) {
                            return {
                                id_pays : item.id_pays,
                                libelle_pays_fr : item.libelle_pays_fr,
                            }
                        });
                        console.log('natio :' ,NationalitesJs)


                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: t('confirmer'),
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }else if (res.data.status === 403) {
                        localStorage.clear();
                        window.location.href = "/login";
                    }


                })
        }

        const formatDate = (date) => {
            const [day, month, year] = date.split('/');

            const result = [year, month, day].join('-');
            return result;
        }

        list_nationalite_l();
        const information_step_one = (params) => {
            baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/information_by_step`, params)

                .then(async res => {
                    if (res.data.status === 200 && res.data.params.etudiant) {
                        setTimeout(
                            () => {
                        let etudiant = res.data.params.etudiant;
                                let ip = res.data.params.ip;
                                if (ip.validation===true){
                                setIpsValide(true)
                                }

                        const regex = new RegExp("^ETR");
                        let bac_academie=res.data.params.bac_academie;

                            if (regex.test(etudiant.code_massar) || regex.test(bac_academie.code_academie)) {
                                setCarteSejour(etudiant.cni_etudiant);
                                setIsEtranger(true)
                            } else {
                                setCni(etudiant.cni_etudiant);
                                setIsEtranger(false)
                            }


                        if (etudiant.id_pays) {
                            let idpays = parseInt(etudiant.id_pays);

                            let paysNatio = NationalitesJs.find(item => item.id_pays === idpays) || null;

                                    setNationaliteValue(paysNatio);

                        }
                        setEtatCivil(etudiant.etat_civile_etudiant);
                        setCodeMassar(etudiant.code_massar)



                        var mydate = formatDate(etudiant.date_naissance_etud);
                        setDtn(mydate)
                        setNomPrenomFr(etudiant.nom_prenom_etud_fr)
                        setlieuNaisFR(etudiant.ville_naissance_etud)
                        setlieuNaisAR(etudiant.ville_naissance_etud_ar)
                        setnomFr(etudiant.nom_etudiant_fr)
                        setprenomFr(etudiant.prenom_etudiant_fr)
                        if (etudiant.nom_etudiant_ar && etudiant.prenom_etudiant_ar && etudiant.nom_prenom_etud_ar) setCheckedAr(true);
                        setnomAr(etudiant.nom_etudiant_ar)
                        setprenomAr(etudiant.prenom_etudiant_ar)
                        setNomPrenomAr(etudiant.nom_prenom_etud_ar)
                        setSexe(etudiant.sexe_etudiant)
                        setloadingContent(false)
                        },1000
                    )
                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: t('confirmer'),

                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                    }


                })
        }


        let isMounted = true;

        if (isMounted) {
            let datastep = new FormData();
            datastep.set('step', 'donnees_civiles_1')
            information_step_one(datastep);

        }

        return () => {
            isMounted = false;
        };

    }, []);
    const preinscription_etudiant_step_one = (params) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/create_account_step_1_donne_civile`, params)

            .then(async res => {
                if (res.data.status === 1) {
                    await Swal.fire({
                        title: t('step_2'),
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.EtapeSuivant();
                        }
                    })
                    setloading(false)
                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setloading(false)

                }else{
                    setloading(false)
                }


            })
    }
    const handlformsubmit = (event) => {
        event.preventDefault();
        setlieuNaisFRError(false)
        setlieuNaisARError(false)
        setnomFrError(false)
        setnomArError(false)
        setprenomFrError(false)
        setprenomArError(false)
        setNomPrenomArError(false)
        setCarteSejourError(false)
        setCniError(false)


        if (lieuNaisFR === '' || !is_french(lieuNaisFR)) {
            setlieuNaisFRError(true);
            return false;
        }
        if (lieuNaisAR === '' || !is_arabic(lieuNaisAR)) {
            setlieuNaisARError(true);
            return false;
        }
        if (prenomFr === '' || !is_french(prenomFr)) {
            setprenomFrError(true);
            return false;
        }
        if (nomFr === '' || !is_french(nomFr)) {
            setnomFrError(true);
            return false;
        }

        if (!isEtranger || checkedAr) {
            if (nomprenomAr === '' || !is_arabic(nomprenomAr)) {
                setNomPrenomArError(true);
                return false;
            }
            if (prenomAr === '' || !is_arabic(prenomAr)) {
                setprenomArError(true);
                return false;
            }
            if (nomAr === '' || !is_arabic(nomAr)) {
                setnomArError(true);
                return false;
            }



        } else {
            if (carteSejour === '') {
                setCarteSejourError(true);
                return false;
            }
        }


        const data = new FormData();
        if (etatCivil === '') {
            setEtatCivilError(true);
            return false;
        }
        if (!ipsValide){
            if (!isEtranger ) {
                if (cni === '' || !ValideCin(cni) ) {
                    setCniError(true);
                    return false;
                }
                data.set('cin', cni);
                data.set('nom_ar', nomAr)
                data.set('prenom_ar', prenomAr)
                data.set('nom_prenom_ar', nomprenomAr)

            } else {
                if (carteSejour === '' || !ValideCin(carteSejour) ){
                    setCarteSejourError(true);
                    return false;
                }
                data.set('carte_sejour', carteSejour);
                if (nomAr && prenomAr && nomprenomAr) {
                    data.set('nom_ar', nomAr)
                    data.set('prenom_ar', prenomAr)
                    data.set('nom_prenom_ar', nomprenomAr)
                }
            }
        }


        if (NationaliteValue.id_pays) {
            data.set('nationalite', NationaliteValue.id_pays);
        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ Nationalite",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }
        data.set('etat_civil', etatCivil);
        if (!ipsValide) {
            data.set('nom_fr', nomFr)
            data.set('prenom_fr', prenomFr)
            data.set('nom_prenom_fr', nomprenomfr)
        }

        data.set('sexe', sexe)
        data.set('lieu_naissance_fr', lieuNaisFR)
        data.set('lieu_naissance_ar', lieuNaisAR)
        data.set('code_massar', CodeMassar)

        data.set('date_naissance', dtn)


        setloading(true)
        preinscription_etudiant_step_one(data);

    }
    return (
        <React.Fragment>
            {
                !loadingContent ?
                    <Box component="form" container spacing={3} onSubmit={handlformsubmit}>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <TextField

                                    disabled
                                    id="code_massar"
                                    name="code_massar"
                                    label={t('code_massar_cne')}
                                    fullWidth
                                    value={CodeMassar || ''}
                                    autoFocus
                                    autoComplete="off"
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    disabled
                                    value={nomprenomfr || ''}
                                    id="nom_prenom_fr"
                                    name="nom_prenom_fr"
                                    label={t('nom_prenom_fr')}
                                    fullWidth
                                    autoComplete="off"
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    disabled
                                    value={dtn || ''}
                                    id="date_naissance"
                                    name="date_naissance"
                                    label={t('date_naissance')}
                                    fullWidth
                                    type={"date"}

                                    InputLabelProps={{shrink: true}}
                                    autoComplete="off"
                                    variant="filled"
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    onChange={(event) => {
                                        setlieuNaisFR(event.target.value)
                                    }}
                                    value={lieuNaisFR}
                                    id="lieu_naissance_fr"
                                    name="lieu_naissance_fr"
                                    label={t('lieu_naissance_fr')}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                    error={lieuNaisFRError}
                                    helperText={lieuNaisFRError ? t('champ_obligatoire_fr') : ' '}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextFeildAR
                                    onChangeAr={(event) => {
                                        setlieuNaisAR(event.target.value)
                                    }}
                                    value={lieuNaisAR || ""}
                                    error={lieuNaisARError}
                                    name="lieu_naissance_ar"
                                    label={t('lieu_naissance_ar')}
                                    id="lieu_naissance_ar"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    disabled={ipsValide}
                                    required
                                    onChange={(event) => {
                                        setnomFr(event.target.value)
                                    }}

                                    value={nomFr || ""}
                                    error={nomFrError}
                                    helperText={nomFrError ? t('champ_obligatoire_fr') : ' '}
                                    id="nom_fr"
                                    name="nom_fr"
                                    label={t('nom_fr')}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    disabled={ipsValide}
                                    required
                                    onChange={(event) => {
                                        setprenomFr(event.target.value)
                                    }}
                                    value={prenomFr || ""}
                                    error={prenomFrError}
                                    helperText={prenomFrError ? t('champ_obligatoire_fr') : ' '}
                                    id="prenom_fr"
                                    name="prenom_fr"
                                    label={t('prenom_fr')}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                />

                            </Grid>
                            {isEtranger && (
                                <>
                                    <Grid item xs={12} sm={12} dir={i18n.language === "ar" ? "rtl" : "ltr "}>

                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox
                                                checked={checkedAr}
                                                onChange={handleChange}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                                              label={t('possedez_nom_prenom_arabe')}/>
                                        </FormGroup>
                                    </Grid>


                                </>)}
                            {(!isEtranger || checkedAr) && (<><Grid item xs={12} sm={12}>

                                <TextFeildAR
                                    disableFiel={ipsValide}
                                    onChangeAr={(event) => {
                                    setNomPrenomAr(event.target.value)
                                }}
                                             value={nomprenomAr}
                                             name="nom_prenom_ar"
                                             label={t('nom_prenom_ar')}
                                             id={"nom_prenom_ar"}
                                             error={nomprenomArError}

                                />

                            </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextFeildAR
                                        disableFiel={ipsValide}
                                        onChangeAr={(event) => {
                                            setnomAr(event.target.value)
                                        }}
                                        value={nomAr} name="nom_ar"
                                        label={t('nom_ar')}
                                        id={"nom_ar"}
                                        error={nomArError}

                                    />

                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <TextFeildAR
                                        disableFiel={ipsValide}
                                        onChangeAr={(event) => {
                                            setprenomAr(event.target.value)
                                        }}
                                        value={prenomAr}
                                        error={prenomArError}
                                        name="prenom_ar" label={t('prenom_ar')} id={"prenom_ar"}/>

                                </Grid> </>)}
                            <Grid item xs={12} sm={4}>
                                {!isEtranger ? <TextField
                                    disabled={ipsValide}
                                    required
                                    id="cin	"
                                    name="cin"
                                    label={t("num_cnie")}
                                    value={cni || ""}
                                    onChange={(e) => {
                                        setCni(e.target.value)
                                    }}
                                    error={cniError}
                                    helperText={cniError ? t('champ_obligatoire') : ' '}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                /> : <TextField
                                    disabled={ipsValide}
                                    required
                                    id="carte_sejour"
                                    name="carte_sejour"
                                    label={t('carte_de_sejour')}
                                    value={carteSejour || ""}
                                    onChange={(e) => {
                                        setCarteSejour(e.target.value)
                                    }}
                                    error={carteSejourError}
                                    helperText={carteSejourError ? t('champ_obligatoire') : ' '}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                />}
                            </Grid>
                            <Grid item xs={12} sm={4}>

                                <Autocomplete
                                    id="country-select-demo"
                                    options={NationalitesJs}
                                    autoHighlight
                                    getOptionLabel={(option) => option.libelle_pays_fr || ""}
                                    onChange={(event, newValue) => {
                                        setNationaliteValue(newValue);
                                    }}
                                    value={NationaliteValue}
                                    isOptionEqualToValue={(option, value) => option.id_pays === value.id_pays}

                                    renderOption={(props, option) => (
                                        <Box component="li"  {...props} key={option.id_pays}>
                                            {option.libelle_pays_fr}
                                        </Box>

                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t('nationalite')}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />


                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t('etat_civil_e')}</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={etatCivil}
                                        defaultValue={"c"}
                                        error={etatCivilError}
                                        helpertext={etatCivil === "" ? t('champ_obligatoire') : ' '}
                                        label={t('etat_civil_e')}
                                        onChange={(event) => setEtatCivil(event.target.value)}

                                    >
                                        <MenuItem value={"c"}>{t('celebataire')}</MenuItem>
                                        <MenuItem value={"m"}>{t('marie')}</MenuItem>
                                        <MenuItem value={"d"}>{t('divorce')}</MenuItem>
                                        <MenuItem value={"v"}>{t('veuf')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} textAlign={"center"}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">{t('sexe')}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="sexe"
                                        onChange={(event) => {
                                            setSexe(event.target.value)
                                        }}
                                        value={sexe}

                                    >
                                        <FormControlLabel value="M" control={<Radio/>} label={t('masculin')}/>
                                        <FormControlLabel value="F" control={<Radio/>} label={t('feminin')}/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>


                        </Grid>

                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                                <InfoCndp/>
                            </Grid>
                            <Grid item xs={3}>
                                <Button

                                    variant="contained"
                                    type="submit"

                                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                                    endIcon={<SendIcon/>}
                                    disabled={loading}

                                >
                                    {t('enregistrer')}
                                </Button>

                            </Grid>

                        </Grid>

                    </Box>

                    : <Box sx={{display: 'flex'}}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                                <CircularProgress/>
                            </Grid>
                        </Grid>
                    </Box>}
        </React.Fragment>
    );
}
