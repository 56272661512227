import React, {useEffect} from "react";
import {HashRouter, Route, Switch, Redirect} from "react-router-dom";

// components
import Layout from "./Layout";
import StepsChoixSujetDoc from "../pages/condidature/choixSujetDoc/StepsChoixSujetDoc"
// pages
import Error from "../pages/error";
import Login from "../pages/login";
import Prienscription from "./PrienscriptionLogin/PrienscriptionLogin";
import PrienscriptionAccount from "./PrienscriptionAccount/PrienscriptionAccount";
import PersonnelLogin from "../pages/serviceEnLigne/EspacePersonnelLogin/PersonnelLogin"
import PrivicyPage from "../pages/login/Privicy"
import LayoutCondidature from "../components/Layout/LayoutCondidature"
// context
import { useUserState } from "../context/UserContext";
import LayoutServiceEnligne from "./Layout/LayoutServiceEnligne";
import ErrorPreinscription from "../pages/error/ErrorPreinscription";
import { useState} from "react";
import LoginCondidat from "../pages/condidature/loginCondidat/LoginCondidat";
import CreateAccountCondidat from "../pages/condidature/accountCondidat/CreateAccountCondidat";
import ErrorReinscription from "../pages/error/ErrorReinscription";
import ErrorCandidature from "../pages/error/ErrorCandidature";
import {BASE_URL, baseAxios, Version} from "../repository/baseAxios";
import axios from "axios";
export default function App() {
  // global
    const [ShowPreinscription, setShowPreinscription] = useState("0");
    const [showReinscription, setShowReinscription] = useState("0");
    const [showCandidaure, setShowCandidaure] = useState("0");
    var { isAuthenticated } = useUserState();
    useEffect(() => {
        const info = async () => {
            try {
                const response = await baseAxios.post(`${BASE_URL}/espace-etudiant/${Version}/mobile-app/get_parametres_mobile`);
                setShowPreinscription(response.data.params.show_myumi_preinscription)
                setShowReinscription(response.data.params.show_myumi_reinscription)
                setShowCandidaure(response.data.params.show_myumi_candidature);
                localStorage.setItem('entity_abrv_name',response.data.params.entity_abrv_name)
            } catch (error) {
                console.error(error);
            }
        };
        info();
    }, []);

  return (
      <>
    <HashRouter basename="/myapp">
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/compte" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/compte" />}
        />

          <PrivateRoute path="/app" component={Layout} />
          <PrivateRoute path="/service/reinscription" component={LayoutServiceEnligne} />
          <PrivateRoute path="/service/consultation-notes" component={LayoutServiceEnligne} />
          <PrivateRoute path="/service/mes-demandes" component={LayoutServiceEnligne} />
          <PrivateRoute path="/service/amo-etudiant" component={LayoutServiceEnligne} />
          <PrivateRoute path= "/service/tools" component={LayoutServiceEnligne} />
       {/*   { showCandidaure==="1" && <PrivateRoute path="/service/rapport-these-doctorat" component={LayoutServiceEnligne}/>}*/}

          {/*   <PrivateRoute path="/service/abscance" component={LayoutServiceEnligne} />*/}

{/*
          <PrivateRoute path="/service/change-password" component={LayoutServiceEnligne} />
*/}


           <PrivateRoute path="/candidature/steps" component={LayoutCondidature}/>
           <PrivateRoute path="/candidature/sujets" component={StepsChoixSujetDoc}/>



          <PublicRoute  path="/login" component={Login} />

          { showCandidaure==="1" ?  <PublicRoute  path="/candidature/login" component={LoginCondidat} /> : <PublicRoute path="/candidature/login" component={ErrorCandidature} />}

          { showCandidaure==="1" ? <PublicRoute path="/candidature/compte" component={CreateAccountCondidat} />  : <PublicRoute path="/candidature/compte" component={ErrorCandidature} />}

          { ShowPreinscription==="1" ? <PublicRoute path="/Prienscription" component={Prienscription}/> :  <PublicRoute path="/Prienscription" component={ErrorPreinscription} />}
          {ShowPreinscription==="1" ? <PublicRoute path="/Account" component={PrienscriptionAccount}/> : <PublicRoute path="/Account" component={ErrorPreinscription}  />}
          {showReinscription==="1" ? <PublicRoute path="/PersonnelLogin" component={PersonnelLogin}/> : <PublicRoute path="/PersonnelLogin" component={ErrorReinscription}  />}
          <PublicRoute  path="/Privacy" component={PrivicyPage} />


          <Route component={Error} />
      </Switch>
    </HashRouter>
      </>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route

        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
      return (
      <Route

        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
