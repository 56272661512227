import axios from "axios";
import Swal from 'sweetalert2'
const  BASE_URL="https://ent.enim.ac.ma";
//const  BASE_URL="http://or.umi.ac.ma";
const  Version="v1";
const  authorization_prefix="Bearer ";
var token =null ;
var se_token =null ;
var candidature_token =null ;
var version_app=1;
token = localStorage.getItem('token');
se_token = localStorage.getItem('se_token');
candidature_token =localStorage.getItem('candidature_token')

export const customHeaders = {
    Accept: 'application/json',
    Authorization: authorization_prefix + token || undefined
};

export const customHeadersCondidature = {
    Accept: 'application/json',
    Authorization: authorization_prefix + candidature_token || undefined
};
export const customHeadersServcie = {
    Accept: 'application/json',
    Authorization: authorization_prefix + se_token || undefined
};
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // for all requests


const  baseAxiosService= axios.create({baseURL : BASE_URL,
    headers:customHeadersServcie

});
const  baseAxios= axios.create({baseURL : BASE_URL,
    headers:customHeaders

});
const  baseAxiosCondidature= axios.create({baseURL : BASE_URL,

    headers:customHeadersCondidature
});


export function authHeader() {
    const token = localStorage.getItem('token');

    if (token) {

        return {
            Authorization: 'Bearer ' + token ,
            'Content-Type': 'application/json',
        };
    } else {
        return {};
    }

}
baseAxios.interceptors.request.use(
    (request)=>request,
    (error)=>{
        console.log(error)
        if(error){
            alert('erreur')

        }

    }
)
baseAxios.interceptors.response.use(
    (response)=> {
        /*
        if (response.data.version !== version_app) {
            Swal.fire({
                title: 'Nouvelle version disponible',
                text: 'Une nouvelle version de l\'application est disponible. Veuillez mettre à jour.',
                icon: 'info',
                confirmButtonText: 'Mettre à jour',
                cancelButtonText: 'Ignorer'
            }).then((result) => {
                if (result.isConfirmed) {
                    // Remplacez le lien ci-dessous par le véritable lien de mise à jour de votre application
                    const lienMiseAJour = "https://play.google.com/";
                    window.location.href = lienMiseAJour;
                }
            });
        }*/
        if (response.data.status===403 ){
            localStorage.clear();
            window.location.href="/login"
        }
        return response
    },
    (error)=>{
        //  console.log(error)
        if(error.response){
            Swal.fire({
                title: error.response.statusText,
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor:'red',
                color : 'red'
            } )

            if (error.response.status===403 ){
                localStorage.clear();
                window.location.href="/login"
            }
            if (error.response.status===404 ){

            }
            if (error.response.status===500 ){

            }
        }

    }
)

baseAxiosCondidature.interceptors.request.use(
    (request)=>request,
    (error)=>{
        console.log(error)
        if(error){
            alert('erreur')

        }

    }
)
baseAxiosCondidature.interceptors.response.use(
    (response) => {
            // Process successful responses here
            if (response.data.version !==version_app ) {
                Swal.fire({
                    title: 'Nouvelle version disponible',
                    text: 'Une nouvelle version de l\'application est disponible. Veuillez mettre à jour.',
                    icon: 'info',
                    confirmButtonText: 'Mettre à jour',

                    cancelButtonText: 'Ignorer'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Remplacez le lien ci-dessous par le véritable lien de mise à jour de votre application
                        const lienMiseAJour = "";
                        window.open(lienMiseAJour, "_blank");

                    }
                });
            }
        if (response.data.status===403 ){
            localStorage.clear();
            window.location.href="/login"
        }

            return response
        },
    (error)=>{
        //  console.log(error)
        if(error.response){
            Swal.fire({
                title: error.response.statusText,
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor:'red',
                color : 'red'
            } )

            if (error.response.status===403 ){
                localStorage.clear();
                window.location.href="/login"
            }
            if (error.response.status===404 ){

            }
            if (error.response.status===500 ){

            }
        }

    }
)

baseAxiosService.interceptors.response.use(
    (response) => {
        // Process successful responses here
        if (response.data.version !==version_app ) {
            Swal.fire({
                title: 'Nouvelle version disponible',
                text: 'Une nouvelle version de l\'application est disponible. Veuillez mettre à jour.',
                icon: 'info',
                confirmButtonText: 'Mettre à jour',
                cancelButtonText: 'Ignorer'
            }).then((result) => {
                if (result.isConfirmed) {
                    // Remplacez le lien ci-dessous par le véritable lien de mise à jour de votre application
                    const lienMiseAJour = "";
                    window.location.href = lienMiseAJour;
                }
            });
        }
        if (response.data.status===403 ){
            localStorage.clear();
            window.location.href="/login"
        }
       return response
    },
    (error)=>{
        //  console.log(error)
        if(error.response){
            Swal.fire({
                title: error.response.statusText,
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor:'red',
                color : 'red'
            } )

            if (error.response.status===403 ){
                localStorage.clear();
                window.location.href="/login"
            }
            if (error.response.status===404 ){

            }
            if (error.response.status===500 ){

            }

        }


    }
)
baseAxiosService.interceptors.request.use(
    (request)=>request,
    (error)=>{
        console.log(error)
        if(error){
            alert('erreur')

        }

    }
)
export {  baseAxios,BASE_URL,se_token,candidature_token,Version,token,baseAxiosService,baseAxiosCondidature};

