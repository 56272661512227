import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import  "./filiere.css";

const useStyles = makeStyles((theme) => ({

    tomrw: {
        margin: "5px",
        // backgroundColor: "pink",
        background: "lightblue",
        // "&:hover, &:focus": {
        //   background: "orange"
        // },
        textAlign: "left",
        "&:active, &:focus ,&selected": {
            background: "orange"
        },
        justifyContent: "flex-start"
    },
    labelInput : {
        display: "inline",
        position: "relative",
        paddingLeft: 20,
        marginBottom: 8,
        cursor: "pointer",
        fontSize: 15,

    }

}));

export default function Filiere(props) {
    const classes = useStyles();
    const [changeFiliere, setChangeFiliere] = useState(false);
    const handleBtnParent = (valuetoken, event) => {
        setChangeFiliere(true)
        let id_opi_bac =  props.SubmitForm.get('id_opi_bac');
        let source_opi =  props.SubmitForm.get('source_bac');
        if(id_opi_bac && source_opi){
            props.SubmitForm.delete('id_opi_bac')
            props.SubmitForm.delete('source_bac')
        }
        Object.values(props.etab).forEach((etab, index) => {
                Object.values(etab.filieres).forEach((fil, index) => {
                        if (fil.filiere_token) {
                            document.getElementById('id_' + fil.filiere_token).checked = false;
                            let t = document.querySelector('#fil_bac_' + fil.filiere_id + "+ #bac_" + fil.filiere_id);
                            if (t) {
                                t.style.display = "none"
                            }
                            let d = document.getElementsByName('bac_opi_' + fil.filiere_id)
                            if (d) {
                                for (let i = 0; i < d.length; i++) {
                                    d[i].checked = false;
                                }
                            }
                        }
                    }
                )
            }
        )
        document.getElementById('id_' + valuetoken).checked = true

        Object.values(props.ListFilieres).forEach((fil, index) => {
            if (fil.filiere_token === valuetoken) {

               let d =document.querySelector('#fil_bac_' + fil.filiere_id+"+ #bac_" + fil.filiere_id)
                if (d ){
                    d.style.display = "block";
                }
                let checkedinput = document.getElementsByName('bac_opi_' + fil.filiere_id)
                if(checkedinput) checkedinput.checked=true;

                {
                    fil.id_etudiant_opi_etab ? props.SubmitForm.set('id_etudiant_opi_opi_etab', fil.id_etudiant_opi_etab)
                        : props.SubmitForm.set('id_etudiant_opi_opi_etab', fil.id_etudiant_opi)
                }
                props.SubmitForm.set('token_filiere', fil.filiere_token)
                props.SubmitForm.set('source_opi', fil.opi)
            }

        })

    };
    const handleBtnActive = (valuetoken, event) => {
        setChangeFiliere(true)
        let id_opi_bac =  props.SubmitForm.get('id_opi_bac');
        let source_opi =  props.SubmitForm.get('source_bac');
        if(id_opi_bac && source_opi){
            props.SubmitForm.delete('id_opi_bac')
            props.SubmitForm.delete('source_bac')
        }
        document.getElementById('btn_' + valuetoken).focus()
        Object.values(props.etab).forEach((etab, index) => {
            Object.values(etab.filieres).forEach((fil, index) => {
                    if (fil.filiere_token) {
                        document.getElementById('id_' + fil.filiere_token).checked = false;

                        let t = document.querySelector('#fil_bac_' + fil.filiere_id + "+ #bac_" + fil.filiere_id);

                        if (t) {
                            t.style.display = "none"
                        }

                        let d = document.getElementsByName('bac_opi_' + fil.filiere_id)
                        if (d) {
                            for (let i = 0; i < d.length; i++) {
                                d[i].checked = false;
                            }
                        }
                    }
                }
            )
        }
        )

        Object.values(props.ListFilieres).forEach((fil, index) => {

            if (fil.filiere_token === valuetoken) {
                let d =document.querySelector('#fil_bac_' + fil.filiere_id+"+ #bac_" + fil.filiere_id)
                if (d ){
                    d.style.display = "block";
                }
                let checkedinput = document.getElementsByName('bac_opi_' + fil.filiere_id)
                if(checkedinput) checkedinput.checked=true;
                {
                    fil.id_etudiant_opi_etab ? props.SubmitForm.set('id_etudiant_opi_opi_etab', fil.id_etudiant_opi_etab)
                        : props.SubmitForm.set('id_etudiant_opi_opi_etab', fil.id_etudiant_opi)
                }

                props.SubmitForm.set('token_filiere', fil.filiere_token)
                props.SubmitForm.set('source_opi', fil.opi)
            }

        })
    }

    const handleBacOpi = (bac) => {

        let id_opi_bac =  props.SubmitForm.get('id_opi_bac');
        let source_opi =  props.SubmitForm.get('source_bac');
        if(id_opi_bac && source_opi){
            props.SubmitForm.delete('id_opi_bac')
            props.SubmitForm.delete('source_bac')
        }
            props.SubmitForm.set('id_opi_bac', bac.id_opi)
            props.SubmitForm.set('source_bac', bac.source)


    }

    return (
        <>
            {Object.values(props.ListFilieres).map((fil, index) => {
                    if (fil.filiere_token) {
                        return (<>
                                <Grid item xs={12} lg={12} md={12} sm={12} key={ fil.filiere_id} id={"fil_bac_"+fil.filiere_id}>

                                    <ButtonGroup

                                        className={classes.bigg}
                                        fullWidth
                                    >

                                        <Button
                                            fullWidth
                                            className={classes.tomrw}
                                            aria-label="justified"
                                            onClick={() => handleBtnParent(fil.filiere_token)}
                                            id={'btn_' + fil.filiere_token}
                                        >
                                            <p>
                                                <input  className={"test"} name={'filiere'} onClick={() => handleBtnActive(fil.filiere_token)}
                                                       type={'radio'} id={'id_' + fil.filiere_token}
                                                       value={fil.filiere_token}/>
                                                {fil.libelle_filiere}
                                            </p>
                                            {/*


                                                <Button style={{
                                                position: "absolute",
                                                right:0,
                                                margin:"0.8px",
                                                background:"#1976d2",
                                                color:"white"


                                            }}
                                                    variant="contained"

                                                  onClick={()=>{  window.open(fil.url,"_blank");
                                                  }}
                                            >
                                         <span className="fa fa-eye" style={{marginRight:"3px",color:"white",background:"#1976d2"}}>

                                              </span>
                                            </Button>

                                             */}


                                        </Button>



                                    </ButtonGroup>
                                </Grid>
                                {changeFiliere && (
                                    <Grid item style={{display: 'none', padding: 12}} id={'bac_' + fil.filiere_id}
                                          xs={12} lg={12} md={12} sm={12} key={index}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label" >Baccalaureat</FormLabel>
                                                {
                                                    Object.values(fil.bacs).map((bac, index) => {
                                                            return (
                                                                <label className={"container"}>
                                                                    <input  className={"test"}  name={'bac_opi_'+fil.filiere_id} onClick={() => handleBacOpi(bac)}
                                                                           type={'radio'} id={'id_bac' + fil.filiere_id}
                                                                           value={bac.id_opi}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                    <span className="textmark">{bac.annee_bac}</span>



                                                                </label>


                                                            )
                                                        }
                                                    )                                                }
                                        </FormControl>
                                    </Grid>
                                )
                                }
                            </>
                        )
                    }

                }
            )
            }
        </>)


}
