import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import SendIcon from '@mui/icons-material/Send';
import i18n from "../../../multilingue/i18n";
import {BASE_URL, baseAxiosService, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import {useEffect, useState} from "react";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";
import {Alert, CircularProgress} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
export default function ReinValidatePrienscription(props) {
    const {t} = useTranslation();
    const [loading, setloading] = useState(false);
    const [selectBacFile, setSelectBacFile] = useState(null);
    const [selectCinFile, setSelectCinFile] = useState(null);
    const [selectPhotoFile, setSelectPhotoFile] = useState(null);
    const [isImporteFichiers, setIsImporteFichiers] = useState(false);
    const [validation, setValidation] = useState(false);
    const [loadingContent, setloadingContent] = useState(true);
    const [selectMasterFile, setSelectMasterFile] = useState(null);
    const [selectLicenceFile, setSelectLicenceFile] = useState(null);
    const [doctorat, setDoctorat] = useState(false);

    const handlchangemasterfile = (event)=> {
        let file = event.target.files[0];
        let fileName = document.querySelector('#master').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setSelectMasterFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectMasterFile("")
        }
    }
    const handlchangelicencefile = (event)=> {
        let file = event.target.files[0];
        let fileName = document.querySelector('#licence').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setSelectLicenceFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectLicenceFile("")
        }
    }

    useEffect(()=>{
        const info_doc = (param) => {
            baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/info_doc`)
                .then(res => {
                    if (res.data.status === 200) {
                        if ( res.data.params.ip ) {
                            if(res.data.params.encadrant.nom && res.data.params.sujet_doc ){
                                setDoctorat(true)

                            } else {

                                setDoctorat(false)

                            }
                        }else{
                            setDoctorat(false)
                        }


                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: 'ERROR !',
                            text: 'Merci de réssayée au plus tard !',
                            icon: 'error',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    } else if (res.data.status === 403) {
                        localStorage.clear();
                        window.location.href = "/login";
                    }

                }).catch(function (error) {
                if (error.response) {
                    localStorage.clear();
                    window.location.href = "/login"
                }
            })
        }

        const information_step_one = (params) => {
            baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/information_by_step`, params)
                .then(async res => {
                    if (res.data.status === 200  && res.data.params.etudiant ) {
                        let etudiant =res.data.params.etudiant;
                        let inscription =res.data.params.inscription;
                        if ( etudiant.file_cin_id &&  etudiant.file_photo_id){
                            setIsImporteFichiers(true)
                        }
                        if (inscription){
                            setValidation(inscription.validation);
                        }

                        setloadingContent(false);
                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text :res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }


                })
        }

        let isMounted = true;

        if(isMounted ){
            let datastep =new FormData();
            datastep.set('step','bac')
            info_doc();
            information_step_one(datastep)
        }

        return () => {
            isMounted = false;
        };


    },[])

    const upload_files = (params) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/upload_files`, params)

            .then(async res => {

                if (res.data.status === 1) {
                    await Swal.fire({
                        title: t('step_6_rein'),
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.EtapeSuivant();
                        }
                    })
                    setloading(false)

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setloading(false)

                }else{
                    setloading(false)
                }
                setloading(false)



            })
    }
       const handlchangebacfile = (event)=>{
       let file = event.target.files[0];
   let fileName = document.querySelector('#baccalaureat').value;
  let  extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension==="pdf"){
            setSelectBacFile(file)
        }else{
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectBacFile("")

        }


}
    const handlchangecinfile = (event)=> {
        let file = event.target.files[0];
        let fileName = document.querySelector('#cnie').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setSelectCinFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectCinFile("")
        }
    }
    const handlchangephotofile = (event)=> {
        let file = event.target.files[0];
        let fileName = document.querySelector('#photo').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "jpg" || extension === "jpeg" || extension === "png"  ) {
            setSelectPhotoFile(file)
        } else {
            document.querySelector('#photo').value=null;
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier image ( JPGE,JPG,PNG ...)",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectPhotoFile("")

        }
    }
    const handlformsubmit = (event) => {
        event.preventDefault();
        setloading(true);
        const data = new FormData();
        if (   selectCinFile && selectPhotoFile ){
            data.set("cin_file",selectCinFile)



        }else{
            Swal.fire({
                title: "Erreur ! ",
                text: "merci d'importer La carte nationale et la Photo personnelle  !",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setloading(false);
            return false;
        }
        if (selectBacFile) {   data.set("bac_file",selectBacFile) }
       if(selectPhotoFile){data.set("photo_file",selectPhotoFile)}
        if (doctorat ){
            if ( selectLicenceFile) data.set("licence_file",selectLicenceFile)
            if ( selectMasterFile)  data.set("master_file",selectMasterFile)

        }


        upload_files(data);


    }
    return (
        <React.Fragment>
            { !loadingContent ?(
                !isImporteFichiers ?
                    <Box component="form" dir={i18n.language === "ar" ? "rtl" : "ltr "} container spacing={3} onSubmit={  handlformsubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                                <Alert severity="error" ><Typography fontWeight={"bold"}>{t('info_photo_etudiant')}</Typography></Alert>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t("baccalaureat_fichie") }  <span  color={"primary"}> PDF {t('optionnelle')}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <TextField
                                    onChange={handlchangebacfile}
                                    id="baccalaureat"
                                    name="baccalaureat"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="filled"
                                    type={"file"}
                                    inputProps={{accept:".pdf"}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('cnie')} <span  color={"primary"}> PDF {t('obligatoire')}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onChange={handlchangecinfile}
                                    id="cnie"
                                    name="cnie"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="filled"
                                    type={"file"}
                                    inputProps={{accept:".pdf"}}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('photo')}  <span  color={"primary"}>{t('obligatoire')}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    onChange={handlchangephotofile}
                                    id="photo"
                                    name="photo"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="filled"
                                    type={"file"}
                                    inputProps={{accept:"image/*"}}
                                />
                            </Grid>
                            {   doctorat && <>
                                <Grid item xs={12} sm={4}>
                                    <Typography>
                                        {t("licence") }  <span  color={"primary"}> PDF {t('optionnelle')}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        onChange={handlchangelicencefile}
                                        id="licence"
                                        name="licence"
                                        fullWidth
                                        autoComplete="given-name"
                                        variant="filled"
                                        type={"file"}
                                        inputProps={{accept: ".pdf"}}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography>
                                        {t("master") }  <span  color={"primary"}> PDF {t('optionnelle')}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        onChange={handlchangemasterfile}
                                        id="master"
                                        name="master"
                                        fullWidth
                                        autoComplete="given-name"
                                        variant="filled"
                                        type={"file"}
                                        inputProps={{accept: ".pdf"}}

                                    />
                                </Grid> </>
                            }
                        </Grid>

                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={12} sm={12} style={{marginTop:14,marginBottom:14}}>
                                <InfoCndp />
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{ mt: 3, ml: 1 , textAlign:"center"}}
                                    endIcon={<SendIcon />}
                                    dir={"ltr"}
                                    disabled={loading}
                                >
                                    {t('enregistrer')}
                                </Button>
                            </Grid>

                        </Grid>
                    </Box>

                    :
                    <>
                    <Grid container spacing={3} dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                        <Grid item xs={12} sm={4}>
                            <Typography>
                                {t("baccalaureat_fichie")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Alert variant="filled" severity="success">
                                 {t('importe_avec_succes')}
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography>
                                {t('cnie')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Alert variant="filled" severity="success">
                                {t('importe_avec_succes')}
                            </Alert>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography>
                                {t('photo')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Alert variant="filled" severity="success">
                                {t('importe_avec_succes')}
                            </Alert>
                        </Grid>
                    </Grid>
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                >

                    {!validation && (<Grid item xs={12}>
                        <Button
                            onClick={(e) => {
                                setIsImporteFichiers(false)
                            }
                            }
                            variant="contained"
                            type="submit"
                            sx={{mt: 3, ml: 1, textAlign: "center"}}
                            endIcon={<EditIcon/>}
                            color={"info"}
                            dir={"ltr"}
                        >
                            {t('remplacer_fichiers')}
                        </Button>
                    </Grid>)}
                    <Grid item xs={12} sm={12} style={{marginTop:14,marginBottom:14}}>
                        <InfoCndp />
                    </Grid>
                    <Grid item xs={12}>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{mt: 3, ml: 1, textAlign: "center"}}
                        endIcon={<SendIcon/>}
                        onClick={() => props.EtapeSuivant()}
                    >
                        {t('suivant')}
                    </Button>
                    </Grid>
                </Grid>
                    </>
           )  :    <Box sx={{display: 'flex'}}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Box>}

        </React.Fragment>
    );
}
