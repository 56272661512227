import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import appStore from "../../../images/icone-app-store.png";
import GooglePlay from "../../../images/icone-google-play.png";
import Box from "@mui/material/Box";
import React from "react";




export default function AppMobileStore(props) {


    return (<>
            <Box component={"div"} sx={{
                marginTop: 4,
                justifyContent: "center",
                display: 'none',
                alignItems: 'center',

            }}>
                <Stack spacing={2} direction="row">
                    <Button  >
                        <img alt={"AppStore"} width={100} height={50} src={appStore} />
                    </Button>
                    <Button  >
                        <img alt={"GooglePlay"} width={100} height={50} src={GooglePlay} />
                    </Button>

                </Stack>

            </Box>
        </>

    );
}


