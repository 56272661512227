import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import QuizIcon from "@mui/icons-material/Quiz";
import Box from "@mui/material/Box";
import {Button, Grid} from "@material-ui/core";
import {Dialog} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {isMobile} from "react-device-detect";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import {Close} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {BASE_URL, baseAxiosCondidature, Version} from "../../repository/baseAxios";
import Swal from "sweetalert2";
import useStyles from "../../pages/login/styles";


export default function HelpEtud(props) {
    const [openTo, setOpenTo] = useState(false);
    const [loadingReset, setLoadingReset] = useState(false);
    const [msgError, setMsgError] = useState(false);
    const [nomFr, setNomFr] = useState('');
    const [nomFrError, setNomFrError] = useState(false);

    const [formationDError, setformationDError] = useState(false);
    const [formationD, setFormationD] = useState("");
    const [msg, setMsg] = useState("");


    let {t} = useTranslation();
    const reclamation = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/commun/reclamation`, params)

            .then(async res => {
                if (res.data.status === 200) {
                    setNomFr("")
                    setFormationD("")
                    setMsg("")
                    setOpenTo(false)
                    await Swal.fire({
                        title: "Votre demande sera traitée dans les meilleurs délais",
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    })
                    setLoadingReset(false)
                } else if (res.data.status === -1) {
                    setLoadingReset(false)
                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })


                }


            })
    }
    var classes = useStyles();
    const help = () => {

        setNomFrError(false);
        setformationDError(false)

        setMsgError(false);

        if (nomFr === '') {
            setNomFrError(true);
            return false;
        }


        if (formationD === '') {
            setformationDError(true);
            return false;
        }
        if (msg === '') {
            setMsgError(true);
            return false;
        }
        setLoadingReset(true);
        var data = new FormData();
        data.set('nom_prenom_directeur', nomFr);
        data.set('formation_doctorale', setFormationD);
        data.set('intitule_sujet', msg);
        reclamation(data)

    }
    return (
        <>
            <Grid item xs={12} sm={6} style={{marginBottom: 20}}>
                <Fab variant="extended" color={props.color ? props.color : "primary"}
                     style={{textAlign: "center", width: "100%"}} onClick={() => {
                    setOpenTo(false)
                }}>
                    <QuizIcon sx={{mr: 1,}}/>
                    {t("besoin_daide_doctorat")}
                </Fab>
            </Grid>
            <Box component="form" spacing={3}>
                <Grid container spacing={3}>
                    <Dialog
                        style={{padding: 20}}
                        open={openTo}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent
                            style={{width: isMobile ? "" : "600px"}}
                        >
                            <Grid item xs={12} sm={12} textAlign={"center"}>

                                <TextField

                                    required
                                    onChange={(event) => {
                                        setNomFr(event.target.value)
                                    }}
                                    value={nomFr || ""}
                                    error={nomFrError}
                                    helperText={nomFrError ? t('champ_obligatoire_fr') : ' '}
                                    id="nom_fr"
                                    name="nom_fr"
                                    label={"nom et prenom de directeur de la thèse"}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} textAlign={"center"}>

                                <TextField
                                    required
                                    id="email"
                                    value={formationD || ""}
                                    onChange={(e) => {
                                        setFormationD(e.target.value)
                                    }}
                                    error={formationDError}
                                    helperText={formationDError ? t('champ_obligatoire') : ' '}
                                    name="email"
                                    label={"Votre formation doctorale"}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={"center"}>

                                <TextField
                                    required
                                    id="outlined-multiline-static"
                                    label={"intitulé du  sujet"}
                                    placeholder={"intitulé du  sujet"}
                                    fullWidth
                                    multiline
                                    rows="4"
                                    value={msg || " "}
                                    onChange={(e) => {
                                        setMsg(e.target.value)
                                    }}
                                    placeholder={"détails du problème"}
                                    error={msgError}
                                    helperText={msgError ? t('champ_obligatoire') : ' '}
                                    variant="outlined"
                                    name="msg"
                                />
                            </Grid>


                            <Grid item xs={6} sm={6} style={{display: "inline"}}>


                                <div className={classes.formButtons}>
                                    <Button
                                        onClick={help}
                                        variant="contained"
                                        color="primary"
                                        style={{background: "#007fa4"}}
                                        size="small"
                                        endIcon={<SendIcon/>}
                                        disabled={loadingReset}

                                    >
                                        <div className={classes.divWith}>

                                            {t('envoyer')}
                                        </div>
                                    </Button>
                                </div>
                                <div className={classes.formButtons}>
                                    <Button

                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        endIcon={<Close/>}
                                        onClick={() => {
                                            setOpenTo(false)
                                        }}

                                    >
                                        <div className={classes.divWith}>

                                            {t('fermer')}
                                        </div>
                                    </Button>
                                </div>
                            </Grid>

                        </DialogContent>
                    </Dialog>
                </Grid>
            </Box>
        </>
    )
}