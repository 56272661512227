import React, {useEffect, useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import {Alert, AlertTitle, CircularProgress, Divider, ListItemSecondaryAction} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from '@mui/icons-material/Person';
import {
    BASE_URL,
    baseAxios,
    baseAxiosCondidature,
    baseAxiosService,
    candidature_token,
    Version
} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import i18n from "../../../multilingue/i18n";
import {isMobile} from "react-device-detect";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import {Download} from "@mui/icons-material";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {object} from "prop-types";

export default function LaboSujets(props) {
    const [checked, setChecked] = React.useState([]);
    const [resume, setResume] = useState("");
    const [axeRecherche, setAxeRecherche] = useState("");
    const [intitule, setIntitule] = useState("");
    const [sujets, setSujets] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [specialite, setSpecialite] = useState("");
    const [nbPlaces, setNbPlaces] = useState("");
    const [loadingContent, setloadingContent] = useState(true);
    const [msgError, setMsgError] = useState("");
    const [OldSu, setOldSu] = useState([]);
    const [dataInfo, setDataInfo] = useState([]);
    const [loading, setloading] = useState(false);
    const [noteMotiv, setNoteMotiv] = useState(false);
    const [formationDoc, setFormationDoc] = useState([]);
    const [valFormationDoctoral, setValFormationDoctoral] = useState("");
    const [sujetesDisponibles, setSujetesDisponibles] = useState([]);
    const [sujetsOrder, setSujetsOrder] = useState([]);
    const [choixSujetValid, setChoixSujetValid] = useState(false);
    const [loadingOrder, setLoadingOrder] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const get_sujet_doc_by_candidatures_etudiant = () => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/get_sujet_doc_by_candidatures_etudiant`)

            .then(async res => {
                setDataInfo(res.data.params.data)

                if (res.data.status === 200) {
                    if (res.data.params.fds.length>0) {  setFormationDoc(res.data.params.fds) }

                    if (res.data.params.sujtes.length > 0
                        && res.data.params.chosenSubject.length === 0) {
                        setSujets(res.data.params.sujtes)
                    } else if (res.data.params.chosenSubject.length > 0) {
                        setOldSu(res.data.params.chosenSubject)
                        // Sort chosen subjects by order here (e.g., ascending order)
                        const chosenSubjectWithOrder = res.data.params.chosenSubject.map((subject, index) => ({
                            ...subject,
                            order: index + 1, // You can customize how you want to set the order here.
                        }));

                        setSujetsOrder(chosenSubjectWithOrder);
                        console.log("sujet order : ",chosenSubjectWithOrder)
                        if (!res.data.params.subjcted_sorted){
                            setNoteMotiv(true)

                        }else{
                            setChoixSujetValid(true)
                        }

                    }

                    setloadingContent(false)
                } else if (res.data.status === 201) {
                    setMsgError(res.data.status)
                    setloadingContent(false)
                } else if (res.data.status === 202) {
                    setMsgError(res.data.status)
                    setloadingContent(false)
                } else if (res.data.status === -1) {
                    setMsgError(res.data.status)
                    setloadingContent(false)
                } else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login"

                }


            })
    }
    useEffect(() => {



        let isMounted = true;

        if (isMounted) {
            setChecked([])
              get_sujet_doc_by_candidatures_etudiant();

        }

        return () => {
            isMounted = false;
        };

    }, []);
    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedTheses = Array.from(OldSu);
        const [reorderedItem] = reorderedTheses.splice(result.source.index, 1);
        reorderedTheses.splice(result.destination.index, 0, reorderedItem);

        // Mettez à jour les numéros d'ordre après la réorganisation
        const reorderedThesesWithOrder = reorderedTheses.map((thesis, index) => ({
            ...thesis,
            order: index + 1
        }));

        setOldSu(reorderedThesesWithOrder);
        handleChange(reorderedThesesWithOrder);
    };

    const moveItemUp = (index) => {
        if (index > 0) {
            const reorderedTheses = Array.from(OldSu);
            const itemToMove = reorderedTheses.splice(index, 1)[0];
            reorderedTheses.splice(index - 1, 0, itemToMove);

            // Mettez à jour les numéros d'ordre
            reorderedTheses.forEach((item, i) => {
                item.order = i + 1;
            });

            setOldSu(reorderedTheses);
            handleChange(reorderedTheses);
        }
    };

    const moveItemDown = (index) => {
        if (index < OldSu.length - 1) {
            const reorderedTheses = Array.from(OldSu);
            const itemToMove = reorderedTheses.splice(index, 1)[0];
            reorderedTheses.splice(index + 1, 0, itemToMove);

            // Mettez à jour les numéros d'ordre
            reorderedTheses.forEach((item, i) => {
                item.order = i + 1;
            });

            setOldSu(reorderedTheses);
            handleChange(reorderedTheses);
        }
    }
    const handleChange = (newTheses) => {
        setSujetsOrder(newTheses);
        console.log('Nouvelle liste de sujets de thèse :', newTheses);
    };

    const showDetail = (token) => {

        Object.values(sujets).map((su, indexx) => {
            if (su.token_sujet_doc === token) {

                setIntitule(su.intitule_sujet)
                setResume(su.resume)
                setAxeRecherche(su.axe_recherche)
                setSpecialite(su.specialites)
                setNbPlaces(su.nmbr_places)
                setOpen(true);

            }
        })

    }

    var {t} = useTranslation();

     const save_candidature_etudiant = (params) => {
         baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/save_choise_sujet_data`, params)

             .then(async res => {
                 if (res.data.status === 200 || res.data.status===1) {

                     get_sujet_doc_by_candidatures_etudiant();

                 } else if (res.data.status === -1) {
                     setLoadingOrder(false)
                     setloading(false)
                     Swal.fire({
                         title: "Vous avez déjà choisi des sujets",
                         text: res.data.message,
                         icon: 'error',

                         confirmButtonText: 'Confirmer',
                         confirmButtonColor: 'red',


                         color: 'red'
                     })

                 }else if (res.data.status === 403) {
                     localStorage.clear();
                     window.location.href = "/login"

                 }


             })
     }

    const saveCandidature = () => {
         let data = new FormData();
         if (checked.length > 0 && checked.length <= 3) {
             let file_lettre = "";
             let fileName = "";
             let error = false;
             let file_lettre_v = "";
             let file = [];
             if (noteMotiv){
                 Object.values(sujets).map((h, indexx) => {

                     Object.values(checked).map((token_sujet_doc, indexx) => {
                         if (token_sujet_doc === h.token_sujet_doc) {
                             file_lettre = document.querySelector("#t_" + token_sujet_doc) ? document.querySelector("#t_" + token_sujet_doc) : null;

                             if (file_lettre) {
                                 let filev = document.querySelector("#t_" + token_sujet_doc).value;
                                 fileName = document.querySelector("#t_" + token_sujet_doc).files[0];
                                 let extension = filev.substring(filev.lastIndexOf('.') + 1);
                                 if (extension !== "pdf" || !filev) {
                                     Swal.fire({
                                         text: "Merci d'importer le document PDF de la Note de motivation correspondant au sujet.\n",
                                         icon: 'error',
                                         confirmButtonText: 'Confirmer',
                                         confirmButtonColor: 'red',
                                         color: 'red'
                                     })

                                     error = true;
                                     return false;

                                 } else {
                                     if (fileName) {
                                         file_lettre_v = "file_" + h.sujet_doc_id;

                                         data.set(file_lettre_v, fileName);
                                     }


                                 }

                             } else {

                                 Swal.fire({
                                     title: "Erreur ! ",
                                     text: "File n'existe pas",
                                     icon: 'error',
                                     confirmButtonText: 'Confirmer',
                                     confirmButtonColor: 'red',
                                     color: 'red'
                                 })

                             }
                         }
                     })
                 })
             }

             if (!error) {
                 setloading(true)
                 Swal.fire({
                     title: t('confirmation_sujets'),
                     icon: 'success',
                     confirmButtonText: t('confirmer'),
                     confirmButtonColor: 'success',
                     showCancelButton: true,
                     color: 'success'
                 }).then((result) => {
                     if (result.isConfirmed) {

                         data.set('sujets', JSON.stringify(checked));

                         save_candidature_etudiant(data)

                     }else{
                         setloading(false)
                     }
                 })
             }


         } else {
             Swal.fire({
                 title: "Erreur !",
                 text: t('error_no_sujets'),
                 icon: 'error',
                 confirmButtonText: t('confirmer'),
                 confirmButtonColor: 'red',
                 color: 'red'
             })
             return false;

         }


     }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value.token_sujet_doc);
        const newChecked = [...checked];


        if (currentIndex === -1) {
            if (newChecked.length < 3) {
                newChecked.push(value.token_sujet_doc);
            } else {
                Swal.fire({
                    title: "Erreur !",
                    text: t('error_sujets'),
                    icon: 'error',
                    confirmButtonText: t('confirmer'),
                    confirmButtonColor: 'red',
                    color: 'red'
                })
                return false;
            }

        } else {

            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);

    };
    const TelechargerPdf = (token) => {
        if (candidature_token && token) {
            const linkSource = `${BASE_URL}/candidature/${Version}/candidature/get_recu_sujet_doc?access_token=${candidature_token}&token_candidat=${token}`;
            window.open(linkSource)
        }
    }
    const handleChangeFd = (event) => {
        const selectedValue = parseInt(event.target.value);

        if (selectedValue) {
            // Mettez à jour la liste des sujets disponibles en fonction de la formation doctorale sélectionnée
            setValFormationDoctoral(selectedValue);

            const sujetsPourFormation = sujets.filter(
                (sujet) => parseInt(sujet.filiere_id) === selectedValue
            );
            setSujetesDisponibles(sujetsPourFormation);

        }
    }
    const saveSujetsOrder = () => {
      setLoadingOrder(true);
        let data = new FormData();
        if (setOldSu.length > 0 && setOldSu.length <= 3) {
            let file_lettre = "";
            let fileName = "";
            let error = false;
            let file_lettre_v = "";
            let file = [];
            if (noteMotiv){

                    Object.values(sujetsOrder).map((h, indexx) => {
                            file_lettre = document.querySelector("#t_" + h.token_sujet_doc) ? document.querySelector("#t_" +  h.token_sujet_doc) : null;
                            if (file_lettre) {
                                let filev = document.querySelector("#t_" + h.token_sujet_doc).value;
                                fileName = document.querySelector("#t_" + h.token_sujet_doc).files[0];
                                let extension = filev.substring(filev.lastIndexOf('.') + 1);
                                if (extension !== "pdf" || !filev) {
                                    setLoadingOrder(false)
                                    Swal.fire({
                                        text: "Merci d'importer le document PDF de la Note de motivation correspondant au sujet.\n",
                                        icon: 'error',
                                        confirmButtonText: 'Confirmer',
                                        confirmButtonColor: 'red',
                                        color: 'red'
                                    })

                                    error = true;
                                    return false;

                                } else {
                                    if (fileName) {
                                        file_lettre_v = "file_" + h.sujet_doc_id;

                                        data.set(file_lettre_v, fileName);
                                    }


                                }

                            } else {

                                Swal.fire({
                                    title: "Erreur ! ",
                                    text: "File n'existe pas",
                                    icon: 'error',
                                    confirmButtonText: 'Confirmer',
                                    confirmButtonColor: 'red',
                                    color: 'red'
                                })

                            }

                    })
            }

            if (!error) {
                Swal.fire({
                    title: t('order_sujet_info'),
                    icon: 'success',
                    confirmButtonText: t('confirmer'),
                    confirmButtonColor: 'success',
                    showCancelButton: true,
                    color: 'success'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const subjectsAndOrder = {
                            sujets: sujetsOrder.map(subject => ({
                                token_sujet_doc: subject.token_sujet_doc,
                                order: subject.order
                            }))
                        };
                        data.set('sujets', JSON.stringify(subjectsAndOrder));
                        data.set('order_sujetes', true);

                        save_candidature_etudiant(data)

                    }else{
                        setLoadingOrder(false)
                    }
                })
            }


        } else {
            Swal.fire({
                title: "Erreur !",
                text: t('error_no_sujets'),
                icon: 'error',
                confirmButtonText: t('confirmer'),
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false;

        }
      console.log("save sujet ",sujetsOrder);
    }
    return (
        <React.Fragment>
            {!loadingContent ?

                OldSu.length===0 ?
                    <Grid container spacing={0}>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <Typography  variant="h6" component="h2" color={"#007fa4"}>
                                    Intitulé du sujet
                                </Typography>


                                <Typography my={2} >
                                    {intitule}
                                </Typography>
                                <Divider />
                                <Typography  variant="h6" component="h2" color={"#007fa4"}>
                                    Axes de recherche
                                </Typography>
                                <Typography my={2} >
                                    {axeRecherche}
                                </Typography>
                                <Divider />
                                <Typography  variant="h6" component="h2" color={"#007fa4"}>
                                    Résumer
                                </Typography>
                                <Typography my={2} >
                                    {resume}
                                </Typography>
                                <Divider />
                                <Typography  variant="h6" component="h2" color={"#007fa4"}>
                                    Spécialité
                                </Typography>
                                <Typography  my={2} >
                                    {specialite}
                                </Typography>
                                {nbPlaces > 0&& (<><Divider/>
                                    <Typography  variant="h6" component="h2" color={"#007fa4"}>
                                        Nombre de places disponible
                                    </Typography>
                                    <Typography  my={2} >
                                        {nbPlaces}
                                    </Typography></>)}

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>{t('fermer')}</Button>

                            </DialogActions>

                        </Dialog>
                                <Grid item xs={12} sm={12} marginBottom={1}
                                      dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                                    <Alert severity="error" style={{marginBottom:2}}><Typography
                                        ><b>{t('alert_choix_sujets')}</b></Typography></Alert>
                                </Grid>
                        <Grid item xs={12} sm={12} marginBottom={1}
                                      dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                                    <Alert severity="info" style={{marginBottom:2}}><Typography
                                        ><b>{t('error_sujets')}</b></Typography></Alert>
                                </Grid>
                                <Grid item xs={12} sm={12} >

                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={valFormationDoctoral}
                                            onChange={(event) => handleChangeFd(event)}
                                        >
                                            { formationDoc.length>0 && Object.values(formationDoc).map((fd, indexf) => {
                                                return (<FormControlLabel
                                                    key={indexf}
                                                    value={fd.filiere_id}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 30,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <span style={{
                                                            fontSize: '18px',
                                                            fontWeight: "bold"
                                                        }}> {/* Augmentez la taille de la police ici */}
                                                            {fd.libelle_filiere}
                                                        </span>
                                                    }
                                                />)
                                            })}

                                        </RadioGroup>

                                    </FormControl>
                                </Grid>
                                {
                                    valFormationDoctoral &&   Object.values(sujetesDisponibles).map((su, indexx) => {
                                            return (
                                                <>
                                                    <Grid item xs={12} sm={11} key={indexx+500} my={1}>
                                                        <ListItem
                                                            style={{background: "#007fa4"}}
                                                            disablePadding
                                                        >
                                                            <ListItemButton role={undefined}
                                                                            onClick={handleToggle(su)}
                                                                            dense>
                                                                <ListItemIcon>
                                                                    
                                                                    <Checkbox
                                                                        sx={{
                                                                            '& .MuiSvgIcon-root': {fontSize: 35},
                                                                            color: "orange",
                                                                            '&.Mui-checked': {
                                                                                color: "orange",
                                                                            }
                                                                        }}
                                                                        value={su.token_sujet_doc}
                                                                        edge="start"
                                                                        checked={checked.indexOf(su.token_sujet_doc) !== -1}
                                                                        tabIndex={-1}
                                                                        disableRipple

                                                                    />
                                                                </ListItemIcon>
                                                                <Alert variant="filled"
                                                                       sx={{
                                                                           fontSize: 14,
                                                                           bgcolor: "#007fa4",
                                                                           textTransform: "uppercase"
                                                                       }}

                                                                >
                                                                    <AlertTitle
                                                                        sx={{fontSize: 14}}><strong>{su.intitule_sujet}</strong></AlertTitle>
                                                                   Directeur de thèse: <strong> {su.encadrant.name} </strong><br/>
                                                                </Alert>
                                                            </ListItemButton>
                                                            <ListItemButton >
                                                                <Button variant={"contained"}
                                                                                             color={"warning"}
                                                                                             onClick={() => {
                                                                                                showDetail(su.token_sujet_doc)
                                                                                             }} size="small"
                                                                >
                                                                    plus details
                                                            </Button>
                                                            </ListItemButton>

                                                        </ListItem>
                                                    </Grid>



                                                </>
                                            )
                                        }
                                    )

                                }
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    alignContent="center"
                                    marginTop={5}
                                    marginBottom={2}
                                    style={{textAlign: "center"}}
                                >
                                    <Button variant="contained"
                                            type={"button"}
                                            endIcon={<SendIcon/>}
                                            onClick={()=>{saveCandidature()}}
                                            disabled={loading}

                                    >
                                        {t('enregistrer_sujet')}
                                    </Button>
                                </Grid>
                    </Grid> :
                    (
                        <>
                            <Grid item xs={12} sm={12} marginBottom={1}
                                  dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                                <Alert severity="info" style={{marginBottom:2}}><Typography
                                    variant={"h6"}>{t('order_sujet')}</Typography></Alert>

                            </Grid>
                            <Grid item xs={12} sm={12} marginBottom={1}
                                  dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                                <Alert severity="error" style={{marginBottom:2}}><Typography
                                    variant={"h6"}><b>{t('order_sujet_info')}</b></Typography></Alert>

                            </Grid>

                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="thesis-list">
                                                {(provided) => (
                                                    <List
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}

                                                    >

                                                        {OldSu.map((thesis, index) => (
                                                            <>
                                                                <Divider textAlign="left" style={{fontWeight:"bold"}} key={index + 100}>votre choix {index + 1} </Divider>
                                                                <Draggable key={thesis.sujet_doc_id} draggableId={thesis.token_sujet_doc} index={index} isDragDisabled={choixSujetValid}>
                                                                    {(provided) => (
                                                                   <>     <ListItem
                                                                            sx={{marginBottom: 3, background: "#4286bd", color: "white"}}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}

                                                                        >
                                                                            <ListItemIcon>
                                                                                <CheckCircleOutlineIcon style={{color:"white"}} />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={thesis.intitule_sujet} secondary={"Directeur de thèse: "+thesis.name}/>

                                                                            { !choixSujetValid && <> <IconButton
                                                                                onClick={() => moveItemUp(index)}
                                                                                disabled={index === 0} edge="end"
                                                                                size={"large"}>
                                                                                <ArrowUpwardIcon style={{
                                                                                    color: "white",
                                                                                    fontSize: 28,
                                                                                    fontWeight: "bold"
                                                                                }}/>
                                                                            </IconButton>
                                                                                <IconButton onClick={() => moveItemDown(index)}
                                                                                disabled={index === OldSu.length - 1} edge="end" size={"large"}>
                                                                                <ArrowDownwardIcon style={{color:"white",fontSize:28,fontWeight:"bold"}} />
                                                                                </IconButton> </> }

                                                                        </ListItem>
                                                                    { (noteMotiv && !choixSujetValid)  && ( <><span style={{marginTop: 20}}
                                                                        dir={i18n.language === "ar" ? "rtl" : "ltr "}> {t('lettre_motivation')} (PDF)</span>
                                                                        <TextField
                                                                        id={"t_" + thesis.token_sujet_doc}
                                                                        dir={i18n.language === "ar" ? "rtl" : "ltr "}
                                                                        autoComplete="given-name"
                                                                        variant="filled"
                                                                        type={"file"}
                                                                        inputProps={{accept: ".pdf"}}
                                                                        style={{marginBottom: 30, marginLeft: 20, textAlign: "center"}}
                                                                        />
                                                                        </>)

                                                                    }
                                                                    </>

                                                                    )}

                                                                </Draggable>
                                                            </>))}
                                                        {provided.placeholder}
                                                    </List>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >

                                {
                                    !choixSujetValid ?       <Grid item xs={3}>  <Button variant="contained"
                                         type={"button"}
                                         endIcon={<SendIcon/>}
                                         onClick={() => {
                                             saveSujetsOrder()
                                         }}
                                         disabled={loadingOrder}

                                >
                                    {t('enregistrer_sujet_order')}
                                        </Button> </Grid>:
                                    <Grid item xs={3}>
                                    <Button variant="contained" color={"success"}
                                    sx={{textAlign: "center"}}
                                    onClick={() => TelechargerPdf(dataInfo.token_candidat)}
                                    size={"small"} startIcon={<Download/>}>
                                    Télécharger votre recu
                                    </Button>
                                    </Grid>
                                }

                            </Grid>

                        </>
                    )


                :
                <Box sx={{display: 'flex'}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Box>
            }


        </React.Fragment>)
}
