import React, {useEffect, useState} from "react";
import {
    Route,
    Switch,
    Redirect,
    withRouter, useHistory,
} from "react-router-dom";
import classnames from "classnames";

import  RieServiceEnLigne from "../../pages/dashboard/RieServiceEnLigne"

// styles
import useStyles from "./styles";

// components
import HeaderServiceEnligne from "../Header/HeaderServiceEnligne";

// pages

import  SidebarServiceEnLigne from "../../components/Sidebar/SidebarServiceEnLigne"
import  DashboardServiceEnLigne from "../../pages/dashboard/DashboardServiceEnLigne"
import  DemandesEtud from "../../pages/serviceEnLigne/serviceDemandes/demandesEtud"


// context
import { useLayoutState } from "../../context/LayoutContext";
import {Copyright} from "../../context/UserContext";
import AmoEtudiant from "../../pages/serviceEnLigne/serviceAmoeEtud/amoEtudiant";
import ServiceTools from "../../pages/serviceEnLigne/serviceTools/ServiceTools";


function LayoutServiceEnLigne(props) {
    var classes = useStyles();


    var layoutState = useLayoutState();

    return (
        <div className={classes.root}  >
            <>
                <HeaderServiceEnligne history={props.history} />
                <SidebarServiceEnLigne   />
                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >

                    <div className={classes.fakeToolbar} />
                    <Switch>
                        <Route path="/service/reinscription" component={RieServiceEnLigne} />
                        <Route path="/service/consultation-notes" component={DashboardServiceEnLigne} />
=                      {/*  <Route path="/service/abscance" component={Abscance} />
                        <Route path="/service/change-password" component={ChangePassword} />*/}
                        <Route path="/service/mes-demandes" component={DemandesEtud} />
                        <Route path="/service/amo-etudiant" component={AmoEtudiant} />
                        <Route path="/service/tools" component={ServiceTools}/>
                   {/*     <Route path="/service/rapport-these-doctorat" component={ServiceTheseDoc}/>*/}

                    </Switch>

                    <Copyright />
                </div>

            </>
        </div>
    );
}

export default withRouter(LayoutServiceEnLigne);
