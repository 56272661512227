
import {Alert, Autocomplete, CircularProgress, Select} from "@mui/material";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import i18n from "../../../multilingue/i18n";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";

import SendIcon from "@mui/icons-material/Send";
import React, {useEffect, useState} from "react";
import TextFeildAR from "../../../components/Widget/TextFeildAR/TextFeildAR";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {useTranslation} from "react-i18next";
import {BASE_URL, baseAxiosCondidature, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import {isMobile} from "react-device-detect";
import {trim} from "stylis";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";
var NationalitesJs=[];

export default function DonneeCivilCondidat(props) {

    const [CodeMassar, setCodeMassar] = useState('');
    const [CodeMassarError, setCodeMassarError] = useState(false);

    const [dtn, setDtn] = useState('');
    const [dtnError, setDtnError] = useState(false);
    const [lieuNaisFR, setLieuNaisFR] = useState("");
    const [lieuNaisFRError, setLieuNaisFRError] = useState(false);
    const [lieuNaisAR, setLieuNaisAR] = useState('');
    const [lieuNaisARError, setLieuNaisARError] = useState(false);
    const [nomFr, setNomFr] = useState('');
    const [nomFrError, setNomFrError] = useState(false);
    const [prenomFr, setPrenomFr] = useState('');
    const [prenomFrError, setprenomFrError] = useState(false);
    const [checkedAr, setCheckedAr] = React.useState(true);
    const [nomprenomAr, setNomPrenomAr] = useState('');
    const [nomprenomArError, setNomPrenomArError] = useState(false);
    const [nomAr, setNomAr] = useState("");
    const [nomArError, setNomArError] = useState(false);
    const [prenomAr, setPrenomAr] = useState("");
    const [prenomArError, setPrenomArError] = useState(false);
    const [cni, setCni] = useState("");
    const [cniError, setCniError] = useState(false);

    const [etatCivil, setEtatCivil] = useState("");
    const [etatCivilError, setEtatCivilError] = useState(false);
    const [sexe, setSexe] = useState("M");
    const [loadingContent, setloadingContent] = useState(true);
    const [loading, setLoading] = useState(false);
    const [ipsValide, setIpsValide] = useState(false);
    const [NationaliteValue, setNationaliteValue] = useState(146);
    const [open, setOpen] = useState(false);
    const [isDoctorat, setIsDoctorat] = useState(false);

    var {t} = useTranslation();
    const is_arabic = (value) => {
        var arabic = /[\u0600-\u06FF\u0750-\u077F]/;
        var fr = /[a-zA-Z]/;

        var string = value;
        if (arabic.test(string) && !fr.test(string)) {
            return true
        } else {
            return false
        }
    }
    const ValideCin = (value) => {
        var letters = /^[A-Z]{1,2}?[0-9]{1}[0-9]{1,7}[A-Z]{0,1}$/;


       let str = value.replace(/\s/g, '');

        if (letters.test(str)) {
            return true;
        } else {
            return false;
        }
    }
    const is_french = (value) => {
        var letters =  /^([a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+\s)*[a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/;

        if (letters.test(value)) {
            return true;
        } else {
            return false;
        }

    }
    const formatDate = (date) => {
        const [day, month, year] = date.split('/');

        const result = [year, month, day].join('-');
        return result;
    }

    useEffect(() => {
        const list_nationalite_l = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/nationalities`)
                .then(async res => {
                    if (res.data.status === 200) {
                        setTimeout(
                            () => {
                                const allNationalites = res.data.params;
                                NationalitesJs = allNationalites.map(function (item) {
                                    return {
                                        id_pays: item.id_pays,
                                        libelle_pays_fr: item.libelle_pays_fr,
                                    }
                                });
                                console.log('natio :', NationalitesJs)

                            },1000)
                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: t('confirmer'),
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }
                    else {
                        localStorage.clear();
                        window.location.href="/login";
                    }


                }).catch(function (error) {
                    if (error.response) {
                        localStorage.clear();
                        window.location.href = "/login"
                    }
                }
            )
        }

        const candidature_information_by_step = (params) => {
            baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/candidature_information_by_step`, params)

                .then(async res => {
                    if (res.data.status === 200 && res.data.params.etudiant) {

                        setTimeout(
                            () => {
                                let etudiant = res.data.params.etudiant;
                                let ip = res.data.params.ip;
                                if(ip){
                                    if (ip.validation===true){
                                        setIpsValide(true)
                                    }
                                }
                                let is_phd_candidat=res.data.params.is_phd_candidat
                                if (is_phd_candidat===true){
                                    setOpen(true)
                                    setIsDoctorat(true)
                                }



                                    setCni(etudiant.cni_etudiant);

                                if (etudiant.id_pays) {
                                    let idpays = parseInt(etudiant.id_pays);



                                    setNationaliteValue(idpays);

                                }
                                setEtatCivil(etudiant.etat_civile_etudiant);
                                setCodeMassar(etudiant.code_massar)
                                if(etudiant.date_naissance_etud) {
                                    var mydate = formatDate(etudiant.date_naissance_etud);
                                    setDtn(mydate)
                                }

                                setLieuNaisFR(etudiant.ville_naissance_etud)
                                setLieuNaisAR(etudiant.ville_naissance_etud_ar)
                                setNomFr(etudiant.nom_etudiant_fr)
                                setPrenomFr(etudiant.prenom_etudiant_fr)
                                if (etudiant.nom_etudiant_ar && etudiant.prenom_etudiant_ar && etudiant.nom_prenom_etud_ar) setCheckedAr(true);
                                setNomAr(etudiant.nom_etudiant_ar)
                                setPrenomAr(etudiant.prenom_etudiant_ar)
                                setNomPrenomAr(etudiant.nom_prenom_etud_ar)
                                setSexe(etudiant.sexe_etudiant)
                                setloadingContent(false)
                            },1000
                        )

                    } else if (res.data.status === -1) {
                        setloadingContent(false)
                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: t('confirmer'),
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                    }else if (res.data.status === 403) {
                        localStorage.clear();
                        window.location.href="/login";
                    }
                    else{
                        setloadingContent(false)
                    }


                })
        }



            let datastep = new FormData();
            datastep.set('step', 'donnees_civiles_1')
            list_nationalite_l();
            candidature_information_by_step(datastep);



    }, []);

    const candidature_create_account_step_1_donne_civile = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/candidature_create_account_step_1_donne_civile`, params)

            .then(async res => {
                if (res.data.status === 1) {
                    await Swal.fire({
                        title: t('step_2'),
                        icon: 'success',
                        confirmButtonText: t('suivant'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.EtapeSuivant();
                        }
                    })
                    setLoading(false)
                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setLoading(false)

                }else{
                    setLoading(false)
                }


            })
    }

    const handleChange = (event) => {

        setCheckedAr(event.target.checked);

    };
    let history=useHistory();
    const redirectChoixSujets = () => {
        history.push("/candidature/sujets")
    };
    const dateValid = (value) => {
        var date_regex = /^(19|20)\d{2}\-(0[1-9]|1[0-2])\-(0[1-9]|1\d|2\d|3[01])$/;

        if (!(date_regex.test(value))) {
            return false
        }else{
            return true
        }
    }
    const handlformsubmit = (event) => {
        event.preventDefault();



        setCodeMassarError(false)
        setLieuNaisFRError(false)
        setLieuNaisARError(false)
        setNomFrError(false)
        setNomArError(false)
        setprenomFrError(false)
        setPrenomArError(false)
        setNomPrenomArError(false)
        setCniError(false)
        const data = new FormData();
        if (CodeMassar === '' || CodeMassar.length<10) {
            setCodeMassarError(true);
            return false;
        }

        if (lieuNaisFR === '' || !is_french(lieuNaisFR)) {
            setLieuNaisFRError(true);
            return false;
        }
        if (dtn === '' || !dateValid(dtn)) {
            setDtnError(true);
            return false;
        }
        if (lieuNaisFR === '' || !is_french(lieuNaisFR)) {
            setLieuNaisFRError(true);
            return false;
        }
        if (lieuNaisAR === '' || !is_arabic(lieuNaisAR)) {
            setLieuNaisARError(true);
            return false;
        }
        if (prenomFr === '' || !is_french(prenomFr)) {
            setprenomFrError(true);
            return false;
        }
        if (nomFr === '' || !is_french(nomFr)) {
            setNomFrError(true);
            return false;
        }

        if ( checkedAr) {
            if (nomprenomAr === '' || !is_arabic(nomprenomAr)) {
                setNomPrenomArError(true);
                return false;
            }
            if (prenomAr === '' || !is_arabic(prenomAr)) {
                setPrenomArError(true);
                return false;
            }
            if (nomAr === '' || !is_arabic(nomAr)) {
                setNomArError(true);
                return false;
            }



        }
        if (NationaliteValue) {
            data.set('nationalite', NationaliteValue);
        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ Nationalite",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }


        if (etatCivil === '') {
            setEtatCivilError(true);
            return false;
        }
        if (cni === '' || !ValideCin(cni) ) {
            setCniError(true);
            return false;
        }
        if (!ipsValide){
            if (checkedAr ) {

                data.set('nom_ar', nomAr)
                data.set('prenom_ar', prenomAr)
                data.set('nom_prenom_ar', nomprenomAr)

            }

        }


        data.set('cin', cni);
        data.set('etat_civil', etatCivil);
        if (!ipsValide) {
            data.set('nom_fr', nomFr)
            data.set('prenom_fr', prenomFr)

        }

        data.set('sexe', sexe)
        data.set('lieu_naissance_fr', lieuNaisFR)
        data.set('lieu_naissance_ar', lieuNaisAR)
        data.set('code_massar', CodeMassar)
        data.set('date_naissance', dtn)


        setLoading(true)
        candidature_create_account_step_1_donne_civile(data)

    }
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <React.Fragment>
        {
    !loadingContent ?
    <Box component="form" container spacing={3} onSubmit={handlformsubmit}>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogContent>
                <Grid item xs={12} my={4}>
                    <Alert severity="info" ><Typography fontSize={18}
                    ><b>Nous vous informons que la sélection des sujets est désormais ouverte. Vous avez la possibilité de choisir trois sujets au total et  de les classer par ordre de préférence</b></Typography></Alert>
                </Grid>
                <hr />
                <Grid item xs={12}>
                    <Button

                        variant="contained"
                        type="submit"

                        sx={{my: 5, ml: 1, textAlign: "center",height:70,fontSize:15,fontWeight:"bold"}}
                        endIcon={<SendIcon/>}
                        onClick={redirectChoixSujets}
                        fullWidth

                    >
                        {t('aller_choix_sujets')}
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button

                        variant="contained"
                        type="button"

                        sx={{mt: 3, ml: 1, textAlign: "center"}}
                        onClick={handleClose}
                        color={"warning"}
                    >
                        {t('fermer')}
                    </Button>
                </Grid>

            </DialogContent>
        </Dialog>
        <Grid container spacing={3}>
            {isDoctorat && <Grid item xs={12}>
                <Button

                    variant="contained"
                    type="submit"

                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                    endIcon={<SendIcon/>}

                    onClick={redirectChoixSujets}

                >
                    {t('aller_choix_sujets')}
                </Button>
            </Grid>}
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="code_massar"
                    name="code_massar"
                    label={t('code_massar_cne') + " ( 10 caractères ) "}
                    fullWidth
                    value={CodeMassar || ''}
                    onChange={(event) => {
                        setCodeMassar(event.target.value)
                    }}
                    error={CodeMassarError}
                    helperText={CodeMassarError ? t('champ_obligatoire') : ' '}
                    autoFocus
                    autoComplete="off"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    value={dtn || ''}
                    onChange={(event) => {
                        setDtn(event.target.value)
                    }}
                    id="date_naissance"
                    name="date_naissance"
                    label={t('date_naissance')}
                    fullWidth
                    type={"date"}
                    error={dtnError}
                    helperText={dtnError ? t('champ_obligatoire') : ' '}

                    InputLabelProps={{shrink: true}}
                    autoComplete="off"
                    variant="outlined"
                />

            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    onChange={(event) => {
                        setLieuNaisFR(event.target.value)
                    }}
                    value={lieuNaisFR}
                    id="lieu_naissance_fr"
                    name="lieu_naissance_fr"
                    label={t('lieu_naissance_fr')}
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    error={lieuNaisFRError}
                    helperText={lieuNaisFRError ? t('champ_obligatoire_fr') : ' '}
                />
            </Grid>
            <Grid item xs={12} sm={6}>

                <TextFeildAR
                    onChangeAr={(event) => {
                        setLieuNaisAR(event.target.value)
                    }}
                    value={lieuNaisAR || ""}
                    error={lieuNaisARError}
                    name="lieu_naissance_ar"
                    label={t('lieu_naissance_ar')}
                    id="lieu_naissance_ar"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    onChange={(event) => {
                        setNomFr(event.target.value)
                    }}

                    value={nomFr || ""}
                    error={nomFrError}
                    helperText={nomFrError ? t('champ_obligatoire_fr') : ' '}
                    id="nom_fr"
                    name="nom_fr"
                    label={t('nom_fr')}
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    onChange={(event) => {
                        setPrenomFr(event.target.value)
                    }}
                    value={prenomFr || ""}
                    error={prenomFrError}
                    helperText={prenomFrError ? t('champ_obligatoire_fr') : ' '}
                    id="prenom_fr"
                    name="prenom_fr"
                    label={t('prenom_fr')}
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                />
            </Grid>

                    <Grid item xs={12} sm={12} dir={i18n.language === "ar" ? "rtl" : "ltr "}>

                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                checked={checkedAr}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                            />}
                                              label={t('possedez_nom_prenom_arabe')}/>
                        </FormGroup>
                    </Grid>



            {( checkedAr) && (<><Grid item xs={12} sm={12}>

                <TextFeildAR onChangeAr={(event) => {
                    setNomPrenomAr(event.target.value)
                }}
                             value={nomprenomAr}
                             name="nom_prenom_ar"
                             label={t('nom_prenom_ar')}
                             id={"nom_prenom_ar"}
                             error={nomprenomArError}

                />

            </Grid>
                <Grid item xs={12} sm={6}>
                    <TextFeildAR
                        onChangeAr={(event) => {
                            setNomAr(event.target.value)
                        }}
                        value={nomAr} name="nom_ar"
                        label={t('nom_ar')}
                        id={"nom_ar"}
                        error={nomArError}

                    />

                </Grid>
                <Grid item xs={12} sm={6}>

                    <TextFeildAR
                        onChangeAr={(event) => {
                            setPrenomAr(event.target.value)
                        }}
                        value={prenomAr}
                        error={prenomArError}
                        name="prenom_ar" label={t('prenom_ar')} id={"prenom_ar"}/>

                </Grid> </>)}
            <Grid item xs={12} sm={4}>
               <TextField
                    required
                    id="cin	"
                    name="cin"
                    label={t("num_cnie_candidat")}
                    value={cni || ""}
                    onChange={(e) => {
                        setCni(e.target.value)
                    }}
                    error={cniError}
                    helperText={cniError ? t('champ_obligatoire') : ' '}
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                />

            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">{t('nationalite')}</InputLabel>
                <Select
                    required
                    fullWidth
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={NationaliteValue}
                    onChange={(event)=>{ setNationaliteValue(event.target.value)}}
                    label={t('nationalite')}
                >
                    {NationalitesJs &&
                        (
                            Object.values(NationalitesJs).map((et, index) => {
                                    return (
                                <MenuItem key={et.id_pays} value={et.id_pays}>    {et.libelle_pays_fr}</MenuItem>

                                    )
                                }
                            )
                        )
                    }
                </Select>

                </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t('etat_civil_e')}</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={etatCivil}
                        defaultValue={"c"}
                        error={etatCivilError}
                        helpertext={etatCivil === "" ? t('champ_obligatoire') : ' '}
                        label={t('etat_civil_e')}
                        onChange={(event) => setEtatCivil(event.target.value)}

                    >
                        <MenuItem value={"c"}>{t('celebataire')}</MenuItem>
                        <MenuItem value={"m"}>{t('marie')}</MenuItem>
                        <MenuItem value={"d"}>{t('divorce')}</MenuItem>
                        <MenuItem value={"v"}>{t('veuf')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} textAlign={"center"}>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">{t('sexe')}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="sexe"
                        onChange={(event) => {
                            setSexe(event.target.value)
                        }}
                        value={sexe}

                    >
                        <FormControlLabel value="M" control={<Radio/>} label={t('masculin')}/>
                        <FormControlLabel value="F" control={<Radio/>} label={t('feminin')}/>
                    </RadioGroup>
                </FormControl>
            </Grid>

        </Grid>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                <InfoCndp/>
            </Grid>
            <Grid item xs={3}>
                <Button

                    variant="contained"
                    type="submit"

                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                    endIcon={<SendIcon/>}
                    disabled={loading}

                >
                    {t('enregistrer')}
                </Button>
            </Grid>

        </Grid>

    </Box> :
        <Box sx={{display: 'flex'}}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                    <CircularProgress/>
                </Grid>
            </Grid>
        </Box>
}
        </React.Fragment>
    );

}