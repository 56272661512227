import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Link
} from "@material-ui/core";
import {
    Menu as MenuIcon,
    NotificationsNone as NotificationsIcon,
    Person as AccountIcon,

    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import LogoutIcon from '@mui/icons-material/Logout';
// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";

import { Stack} from "@mui/material";
import i18n from "../../multilingue/i18n";
import Flag from 'react-world-flags'
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import LockResetIcon from '@mui/icons-material/LockReset';


/*const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];*/

export default function HeaderServiceEnligne(props) {
    var classes = useStyles();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }
    // global
    var layoutState = useLayoutState();
    var layoutDispatch = useLayoutDispatch();
    var userDispatch = useUserDispatch();
    const {t}=useTranslation();
    var history=useHistory();

    // local
    var [notificationsMenu, setNotificationsMenu] = useState(null);
    var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
    var [profileMenu, setProfileMenu] = useState(null);

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                {   <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(layoutDispatch)}
                    className={classNames(
                        classes.headerMenuButtonSandwich,
                        classes.headerMenuButtonCollapse,
                    )}
                >
                    {layoutState.isSidebarOpened ? (
                        <ArrowBackIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse,
                                ),
                            }}
                        />
                    ) : (
                        <MenuIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse,
                                ),
                            }}
                        />
                    )}
                </IconButton>}
                <Typography variant="h6" weight="medium" className={classes.logotype}>
                    {t('espace_numerique')}
                </Typography>

                <div className={classes.grow} />
                <Stack  direction="row" >
                    <Button  color="lightblue"  onClick={()=>changeLanguage('en')}><Flag code="GB_NIR" height="20" /></Button>
                    <Button   onClick={()=>changeLanguage('fr')}><Flag code="GF" height="20" /> </Button>
                    <Button   onClick={()=>changeLanguage('ar')}><Flag code="MA" height="20" /> </Button>
                </Stack>
                <div className={classes.grow} />


                {/*  <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >

          <Badge
            badgeContent={isNotificationsUnread ? notifications.length : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>*/}


                <IconButton
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.headerMenuButton}
                    aria-controls="profile-menu"
                    onClick={e => setProfileMenu(e.currentTarget)}
                >
                    <AccountIcon classes={{ root: classes.headerIcon }} />
                </IconButton>

                {/*
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>*/}

                <Menu
                    id="profile-menu"
                    open={Boolean(profileMenu)}
                    anchorEl={profileMenu}
                    onClose={() => setProfileMenu(null)}
                    className={classes.headerMenu}
                    classes={{ paper: classes.profileMenu }}
                    disableAutoFocusItem
                >



                    {/*  <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Messages
          </MenuItem>*/}
                    <div className={classes.profileMenuUser}>
                        <Typography
                            className={classes.profileMenuLink}
                            color="primary"
                            onClick={() => signOut(userDispatch, props.history)}

                        >
                            <span className={"fa fa-sign-out fa-1x"}></span>    {t('deconnecte')}
                        </Typography>


                    </div>
                {/*    <div className={classes.profileMenuUser}>
                        <Typography
                            className={classes.profileMenuLink}
                            color="primary"
                            onClick={() =>  history.push('/service/change-password')}


                        >
                          <LockResetIcon   />   {t('changement_de_mot_de_passe')}
                        </Typography>


                    </div>*/}

                </Menu>
            </Toolbar>
        </AppBar>
    );
}
