import React from "react";
import {
    Route,
    Switch,
    Redirect,
    withRouter,
} from "react-router-dom";
import classnames from "classnames";


// styles
import useStyles from "./styles";

// components
import HeaderCondidature from "../Header/HeaderCondidature";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import StepsCondidature from "../../pages/dashboard/StepsCondidature"
// context
import { useLayoutState } from "../../context/LayoutContext";
import {Copyright} from "../../context/UserContext";

function LayoutCondidature(props) {
    var classes = useStyles();

    // global
    var layoutState = useLayoutState();

    return (
        <div className={classes.root}  >
            <>
                <HeaderCondidature history={props.history} />

                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >
                    <div className={classes.fakeToolbar} />
                    <Switch>
                        <Route path="/candidature/steps" component={StepsCondidature} />

                    </Switch>

                    <footer style={{ textAlign : "center"}} >

                        <Copyright />

                    </footer>
                </div>
            </>
        </div>
    );
}

export default withRouter(LayoutCondidature);
