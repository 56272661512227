
import {Alert, Autocomplete, CircularProgress} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import i18n from "../../../multilingue/i18n";
import Link from "@mui/material/Link";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";

import SendIcon from "@mui/icons-material/Send";
import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";

import {BASE_URL, baseAxios, baseAxiosCondidature, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
var seriesBac = [];
var academie_s = [];
var provinces = [];
var Villes = [];
var MentionsB=[];

export default function BacCondidat(props) {
    const [loading, setloading] = useState(false);
    const [mentionsBacValue,setMentionsBacValue]=useState(null);
    const [lycee,setLycee]=useState(null);
    const [moyenBac,setMoyenBac]=useState(null);
    const [moyenBacError,setMoyenBacError]=useState(false);

    const [lyceeError,setLyceeError]=useState(false);
    const [anneeBac,setAnneBac]=useState(null);
    const [anneeBacError,setAnneBacError]=useState(false);
    const [loadingContent, setloadingContent] = useState(true);
    const [provinceValue, setProvinceValue] = useState({});
    const [academieValue, setAcademieBacValue] = useState({});
    const [serieBacValue, setSerieBacValue] = useState({});
    const [villeValue, setVilleValue] = useState({});

    var {t} = useTranslation();
    const information_step_one = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/candidature_information_by_step`, params)
            .then(async res => {
                if (res.data.status === 200  && res.data.params.inscription) {
                    setTimeout(
                        () => {
                                let inscription=   res.data.params.inscription;
                                let ville_id_bac=parseInt( inscription.ville_bac)
                                let mention_baccalaureat=parseInt(inscription.mention_bac)
                            let moyen_bac=parseFloat(inscription.moyen_bac)

                            if(moyen_bac){
                                setMoyenBac(moyen_bac);
                            }
                            if(ville_id_bac){
                                let ville = Villes.find(item => item.id ===ville_id_bac) || null;
                                setVilleValue(ville);
                            }

                            if(mention_baccalaureat){
                                let mention = MentionsB.find(item => item.mention_id ===mention_baccalaureat) || null;
                                setMentionsBacValue(mention);
                            }

                            if(inscription.province_id ){
                                let bac_province_id=inscription.province_id;
                                let province = provinces.find(item => item.province_id ===bac_province_id) || null;
                                setProvinceValue(province)
                            }
                            if(inscription.academie_id){
                                let bac_academie_id=inscription.academie_id;
                                let academie = academie_s.find(item => item.academie_id ===bac_academie_id) || null;
                                setAcademieBacValue(academie)
                            }
                            if(inscription.serie_bac_id){
                                let serie_bac_id=inscription.serie_bac_id;
                                let serie_bac = seriesBac.find(item => item.serie_bac_id ===serie_bac_id) || null;
                                setSerieBacValue(serie_bac)
                            }
                            if(inscription.annee_bac){
                                setAnneBac(inscription.annee_bac)
                            }
                            if(inscription.lycee_bac){
                                setLycee(inscription.lycee_bac)
                            }

                            setloadingContent(false)
                        },1000
                    )
                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merce de ressayer au plus tard",
                        text :res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }else{
                    setloadingContent(false)
                }


            })
    }

    useEffect(() => {

        const list_seriesBac = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/series_bac`)

                .then(async res => {
                    if (res.data.status === 200) {
                        seriesBac = res.data.params;

                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text :res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        };
        const list_provinces = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/list_provinces`)

                .then(async res => {
                    if (res.data.status === 200) {
                        provinces = res.data.params;


                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text :res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        };
        const list_academies = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/list_academies`)

                .then(async res => {
                    if (res.data.status === 200) {
                        academie_s = res.data.params;

                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text :res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        };
        const list_villes = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/list_villes`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const allvilles = res.data.params;
                        Villes = allvilles;


                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        } ;
        const list_mentions = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/mentions`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const mentions = res.data.params;
                        MentionsB=mentions;

                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text :res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        };
        list_mentions()
        list_villes()
        list_academies()
        list_provinces()
        list_seriesBac()
        let isMounted = true;

        if(isMounted ){

            let datastep =new FormData();
            datastep.set('step','bac')
            information_step_one(datastep)


        }

        return () => {
            isMounted = false;
        };

        setloadingContent(false)
    }, []);
    const validanneeBac = (value) => {
        var date_regex = /^(19|20)\d{2}$/;

        if (!(date_regex.test(value))) {
            setAnneBacError(true)
            return false
        }else{
            return true
        }
    }

    const candidature_create_account_step_4_bac = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/candidature_create_account_step_4_bac`, params)

            .then(async res => {

                if (res.data.status === 1) {
                    await Swal.fire({
                        title: t('step_5'),
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.EtapeSuivant();
                        }
                    })
                    setloading(false)
                } else if (res.data.status === -1) {
                    setloading(false)
                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setloading(false)

                }else{
                    setloading(false)
                }
                setloading(false)


            })
    }

    const is_french = (value) => {
        var letters =  /^([a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+\s)*[a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/;

        if (letters.test(value)) {
            return true;
        } else {
            return false;
        }

    }
    const handlformsubmit = (event) => {
        event.preventDefault();

        setLyceeError(false)
        if (anneeBac ==="") {
            setAnneBacError(true)
            return false;
        }
        if (moyenBac ===""  || moyenBac>20) {
            setMoyenBacError(true)
            return false;
        }
        if (lycee ==="" || !is_french(lycee)) {
            setLyceeError(true)
            return false;
        }
        const data = new FormData();
        if (serieBacValue.serie_bac_id) {
            data.set('serie_bac_id',serieBacValue.serie_bac_id)
        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ Série du baccalauréat",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }
        if (academieValue.academie_id) {
            data.set('academie_id',academieValue.academie_id)
        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ Academie du baccalauréat",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }
        if (provinceValue.province_id) {
            data.set('province_id',provinceValue.province_id)
        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ province du baccalauréat",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }
        if (villeValue.id) {
            data.set('ville_bac',villeValue.id)
        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ Ville baccalauréat",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }
        if (mentionsBacValue.mention_id) {
            data.set('mention_bac',mentionsBacValue.mention_id)
        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ mention baccalauréat",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }
        data.set('lycee_bac',lycee)
        data.set('annee_bac',anneeBac)
        data.set('moyen_bac',moyenBac)

        setloading(true);
        candidature_create_account_step_4_bac(data);

    }
    return (
        <React.Fragment>
            {
                !loadingContent ?
                    <Box component="form" container spacing={3} dir={i18n.language === "ar" ? "rtl" : "ltr "}
                         onSubmit={handlformsubmit}>

                        <Grid item xs={12} sm={12} style={{marginTop: 12, marginBottom: 22}}>
                            <Autocomplete
                                id="country-select-demo"
                                dir={"ltr"}
                                options={seriesBac}
                                autoHighlight
                                getOptionLabel={(option) => option.libelle_baccalaureat_fr || ""}
                                onChange={(event, newValue) => {
                                    setSerieBacValue(newValue)
                                }}
                                value={serieBacValue}
                                renderOption={(props, option) => (
                                    <Box component="li"  {...props} key={option.serie_bac_id}>
                                        {option.libelle_baccalaureat_fr}
                                    </Box>

                                )}
                                isOptionEqualToValue={(option, value) => option.serie_bac_id === value.serie_bac_id}

                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t('seriebac')}
                                        inputProps={{
                                            ...params.inputProps,

                                        }}
                                        autoComplete={"off"}

                                    />

                                )}
                            />
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    id="country-select-demo"
                                    dir={"ltr"}
                                    options={academie_s}
                                    autoHighlight
                                    getOptionLabel={(option) => option.libelle_academie_fr || ""}
                                    onChange={(event, newValue) => {
                                        setAcademieBacValue(newValue)
                                    }}
                                    value={academieValue}
                                    renderOption={(props, option) => (
                                        <Box component="li"  {...props} key={option.academie_id}>
                                            {option.libelle_academie_fr}
                                        </Box>

                                    )}
                                    isOptionEqualToValue={(option, value) => option.academie_id === value.academie_id}

                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t('academie')}
                                            inputProps={{
                                                ...params.inputProps,

                                            }}
                                            autoComplete={"off"}

                                        />

                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    id="country-select-demo"
                                    dir={"ltr"}
                                    options={provinces}
                                    autoHighlight
                                    getOptionLabel={(option) => option.libelle_province_fr || "" }
                                    onChange={(event, newValue) => {
                                        setProvinceValue(newValue)
                                    }}
                                    value={provinceValue}
                                    renderOption={(props, option) => (
                                        <Box component="li"  {...props} key={option.province_id}>
                                            {option.libelle_province_fr}
                                        </Box>

                                    )}
                                    isOptionEqualToValue={(option, value) => option.province_id === value.province_id}

                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t('province')}
                                            inputProps={{
                                                ...params.inputProps,

                                            }}
                                            autoComplete={"off"}

                                        />

                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    dir={'ltr'}
                                    id="anneeBac"
                                    name="anneeBac"
                                    label={t('annee_bac')}
                                    value={anneeBac || ""}
                                    onChange={(e) => {
                                        setAnneBac(e.target.value)
                                    }}
                                    error={anneeBacError}
                                    helperText={anneeBacError ? t('champ_obligatoire_fr') : ' '}

                                    fullWidth
                                    autoComplete={"off"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('ville_obtention_du_baccalaureat')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Autocomplete
                                    id="country-select-demo"
                                    options={Villes}
                                    autoHighlight
                                    getOptionLabel={(option) => option.ville_fr || ""}
                                    onChange={(event, newValue) => {
                                        setVilleValue(newValue)
                                    }}
                                    value={villeValue}

                                    renderOption={(props, option) => (
                                        <Box component="li"  {...props} key={option.id}>
                                            {option.ville_fr}
                                        </Box>

                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t(props.libelle)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill

                                            }}
                                        />

                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('moyen_du_baccalaureat')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    required
                                    dir={'ltr'}
                                    id="moyen_bac"
                                    name="moyen_bac"
                                    type={"number"}
                                    value={moyenBac || ""}
                                    onChange={(e) => {
                                        setMoyenBac(e.target.value)
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 20,
                                            step: "0.01"
                                        }
                                    }}
                                    error={moyenBacError}
                                    helperText={moyenBacError ? t('champ_obligatoire_fr') : ' '}
                                    fullWidth
                                    autoComplete={"off"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('mention_du_baccalaureat')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Autocomplete
                                    id="country-select-demo"
                                    dir={"ltr"}
                                    options={MentionsB}
                                    autoHighlight
                                    getOptionLabel={(option) => option.mention_name || ""}
                                    onChange={(event, newValue) => {
                                        setMentionsBacValue(newValue)
                                    }}
                                    value={mentionsBacValue}
                                    renderOption={(props, option) => (
                                        <Box component="li"  {...props} key={option.mention_id}>
                                            {option.mention_name}
                                        </Box>

                                    )}
                                    isOptionEqualToValue={(option, value) => option.mention_id === value.mention_id}

                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}

                                            inputProps={{
                                                ...params.inputProps,

                                            }}
                                            autoComplete={"off"}

                                        />

                                    )}
                                />

                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('lycee_obtention_du_baccalaureat')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    required
                                    dir={'ltr'}
                                    id="lycee_bac"
                                    name="lycee_bac"
                                    value={lycee || ""}
                                    onChange={(e) => {
                                        setLycee(e.target.value)
                                    }}
                                    error={lyceeError}
                                    helperText={lyceeError ? t('champ_obligatoire_fr') : ' '}
                                    fullWidth
                                    autoComplete={"off"}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <InfoCndp/>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >

                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{mt: 3, ml: 1, textAlign: "center"}}
                                        endIcon={<SendIcon/>}
                                        dir={"ltr"}
                                        disabled={loading}
                                    >
                                        {t('enregistrer')}
                                    </Button>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Box>
            :
                <Box sx={{display: 'flex'}}>
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                >
                <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                <CircularProgress/>
                </Grid>
                </Grid>
                </Box>}
        </React.Fragment>
    );

}