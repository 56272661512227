import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {BASE_URL, baseAxiosCondidature, Version} from "../../../repository/baseAxios";

import Swal from "sweetalert2";
import {Autocomplete, CircularProgress, Divider} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useTranslation} from "react-i18next";

import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";

var VillesJs = [];
var JobsParents = [];
var TypesHandicapes = [];
var codespostale = [];

export default function AdressCondidat(props) {
    const [loadingContent, setloadingContent] = useState(true);
    const [telephone, seTtelephone] = useState("");
    const [adresse, setAdresse] = useState("");
    const [email, setEmail] = useState(false);
    const [telephoneError, seTtelephoneError] = useState(false);
    const [adresseError, setAdresseError] = useState(false);

    const [emailError, setEmailError] = useState(false);
    const [codePostaleValue, setCodePostaleValue] = useState({});
    const [fonctionnaire, setFonctionnaire] = useState("no");
    const [handicape, setHandicape] = useState("no");
    const [handicapeTypeValue, sethandicapeTypeValue] = useState("");
    const [VilleValue, setVilleValue] = useState("");

    const [profMere, setProfMere] = useState("");
    const [profPere, setProfPere] = useState("");
    const [profEtud, setProfEtud] = useState("");
    const [loading, setLoading] = useState(false);


    const {t} = useTranslation();
    const create_account_step_2 = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/candidature_create_account_step_2`, params)
            .then(async res => {
                if (res.data.status === 1) {
                    await Swal.fire({
                        title: t('step_3'),
                        icon: 'success',
                        confirmButtonText: t('suivant'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {

                            props.EtapeSuivant();

                        }
                    })
                    setLoading(false)


                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                    setLoading(false)

                }else{
                    setLoading(false)
                }

                setLoading(false)
            })
    }

    const is_french = (value) => {
        var letters =  /^([a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+\s)*[a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/;

        if (letters.test(value)) {
            return true;
        } else {
            return false;
        }

    }
    const is_arabic = (value) => {
        var arabic = /[\u0600-\u06FF\u0750-\u077F]/;
        var string = value;
        if (arabic.test(string)) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        const list_villes = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/list_villes`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const allvilles = res.data.params;
                        VillesJs = allvilles;


                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }   else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        }
        const parents_jobs = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/parents_jobs`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const parentsJobs = res.data.params;
                        JobsParents = parentsJobs;


                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }   else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        }
        const code_postales = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/code_postales`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const code_postales = res.data.params;

                        codespostale = code_postales.map(function (item) {
                            return {
                                id_code_postal : item.id_code_postal,
                                libelle_secteur : item.libelle_secteur,
                            }
                        });

                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }   else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        }
        const type_handicaps = () => {
            baseAxiosCondidature.get(`${BASE_URL}/inscription/${Version}/inscription/type_handicaps`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const type_handicaps = res.data.params;

                        TypesHandicapes = type_handicaps.map(function (item) {
                            return {
                                id_handicap_stat : item.id_handicap_stat,
                                libelle_handicap : item.libelle_handicap,
                            }
                        });
                        console.log('TypesHandicapes :',TypesHandicapes)

                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }   else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        }

        const candidature_information_by_step = (params) => {
            baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/candidature_information_by_step`, params)

                .then(async res => {
                    if (res.data.status === 200 && res.data.params.etudiant && res.data.params.amo) {
                        setTimeout(
                            () => {
                                let etudiant = res.data.params.etudiant;


                                let amo = res.data.params.amo;

                                let idcodepostal = parseInt(amo.id_code_postal)
                                let idVille = parseInt(etudiant.ville_id)
                                let ville = VillesJs.find(item => item.id === idVille) || null;
                                setVilleValue(ville);
                                let codeSocioMere = etudiant.code_categorie_sociopro_mere;
                                let codeSocioPere = etudiant.code_categorie_sociopro_pere;
                                if (idcodepostal) {
                                    let codepostaletud = codespostale.find(item => item.id_code_postal === idcodepostal) || null;

                                    setCodePostaleValue(codepostaletud)

                                }
                                if (codeSocioPere) {
                                    let parentsJobpere = JobsParents.find(item => item.code_categorie_sociopro_stat === codeSocioPere) || null;

                                    setProfPere(parentsJobpere);

                                }
                                if (codeSocioMere) {
                                    let parentsJobmere = JobsParents.find(item => item.code_categorie_sociopro_stat === codeSocioMere) || null;
                                    setProfMere(parentsJobmere);

                                }

                                setEmail(etudiant.email_etudiant);
                                setAdresse(etudiant.adresse_personnelle_etud);

                                seTtelephone(etudiant.tel_mobile_etudiant);
                                if (etudiant.handicap_statut === true) {
                                    setHandicape("yes");
                                    let id_handicap_stat = parseInt(etudiant.id_handicap_stat);
                                    let thandicape = TypesHandicapes.find(item => item.id_handicap_stat === id_handicap_stat) || null;

                                    sethandicapeTypeValue(thandicape);


                                }
                                if (etudiant.profession_statut === true) {
                                    setFonctionnaire("yes");
                                    let codeSocioEtud = etudiant.code_categorie_sociopro_etud;
                                    let jobetud = JobsParents.find(item => item.code_categorie_sociopro_stat === codeSocioEtud) || null;
                                    setProfEtud(jobetud);

                                }
                                setloadingContent(false)
                            },1000)
                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }   else {
                        setloadingContent(false)
                        localStorage.clear();
                        window.location.href="/login";
                    }


                })
        }
        let isMounted = true;
        if (isMounted) {

            let datastep = new FormData();
            datastep.set('step', 'donnees_civiles_2');

            list_villes();
            parents_jobs();
            code_postales();
            type_handicaps();
            candidature_information_by_step(datastep);

        }

        return () => {
            isMounted = false;
        };

    }, [])
    const handlformsubmit = (event) => {
        event.preventDefault();

        seTtelephoneError(false);
        setEmailError(false);
        setAdresseError(false);
        if (adresse === '' || is_arabic(adresse)) {
            setAdresseError(true);
            return false;
        }
        if (telephone === '' ) {
            seTtelephoneError(true);
            return false;
        }


        if (email === '' ||  is_arabic(email)) {
            setEmailError(true);
            return false;
        }


        const data = new FormData();

        data.set('ville', VilleValue.id);
        data.set('profession_mere', profMere.code_categorie_sociopro_stat);
        data.set('profession_pere', profPere.code_categorie_sociopro_stat);
        if (codePostaleValue.id_code_postal) {
            data.set('code_postale', codePostaleValue.id_code_postal);

        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ code postale",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }

        data.set('handicape', handicape);
        data.set('fonctionnaire', fonctionnaire);

        data.set('adresse', adresse);
        data.set('telephone', telephone);
        data.set('email', email);

        if (handicape === "yes") {
            if (handicapeTypeValue.id_handicap_stat) {
                data.set('type_handicape', handicapeTypeValue.id_handicap_stat);

            } else {
                Swal.fire({
                    title: "Erreur !",
                    text: "Erreur dans le champ TYPE D'HANDICAPE",
                    icon: 'error',
                    confirmButtonText: 'Confirmer',
                    confirmButtonColor: 'red',
                    color: 'red'
                })
                return false
            }
        }
        if (fonctionnaire === "yes") {
            if (profEtud.code_categorie_sociopro_stat) {
                data.set('profession_etudiant', profEtud.code_categorie_sociopro_stat);

            } else {
                Swal.fire({
                    title: "Erreur !",
                    text: "Erreur dans le champ profession etudiant",
                    icon: 'error',
                    confirmButtonText: 'Confirmer',
                    confirmButtonColor: 'red',
                    color: 'red'
                })
                return false
            }
        }
        setLoading(true)
        create_account_step_2(data);


    }
    return (
        <React.Fragment>
            {!loadingContent ?
                <Box component="form" container spacing={3} onSubmit={handlformsubmit}>
                    <Grid container spacing={3}>


                        <Grid item xs={12} sm={6} textAlign={"center"}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">{t('fonctionnaire')} </FormLabel>
                                <RadioGroup

                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name={"fonctionnaire"}
                                    value={fonctionnaire}
                                    onChange={(event) => setFonctionnaire(event.target.value)}
                                >
                                    <FormControlLabel value="no" control={<Radio/>} label={t('non')}/>
                                    <FormControlLabel value="yes" control={<Radio/>} label={t('oui')}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign={"center"}>
                            <FormControl>
                                <FormLabel
                                    id="demo-row-radio-buttons-group-label">{t('situation_handicape')}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="handicape"
                                    value={handicape}
                                    onChange={(event) => setHandicape(event.target.value)}
                                >
                                    <FormControlLabel value="no" control={<Radio/>} label={t('non')}/>
                                    <FormControlLabel value="yes" control={<Radio/>} label={t('oui')}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {fonctionnaire === "yes" ? (
                            <Grid item xs={12} sm={6}>
                                <Autocomplete

                                    id="country-select-demo"
                                    options={JobsParents}
                                    autoHighlight
                                    getOptionLabel={(option) => option.libelle_categorie_sosciopro || ""}
                                    onChange={(event, newValue) => {
                                        setProfEtud(newValue)

                                    }}
                                    value={profEtud}
                                    isOptionEqualToValue={(option, value) => option === value}

                                    renderOption={(props, option) => (
                                        <Box component="li"  {...props} key={option.code_categorie_sociopro_stat}>
                                            {option.libelle_categorie_sosciopro}
                                        </Box>

                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t('profession')}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill

                                            }}

                                        />

                                    )}
                                />

                            </Grid>
                        ) : <Grid item xs={12} sm={6}>

                        </Grid>}
                        {handicape === "yes" ? (
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="country-select-demo"
                                    options={TypesHandicapes}
                                    autoHighlight
                                    getOptionLabel={(option) => option.libelle_handicap || ""}
                                    onChange={(event, newValue) => {
                                        sethandicapeTypeValue(newValue)
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id_handicap_stat === value.id_handicap_stat}

                                    value={handicapeTypeValue}
                                    renderOption={(props, option) => (
                                        <Box component="li"  {...props} key={option.id_handicap_stat}>
                                            {option.libelle_handicap}
                                        </Box>

                                    )}

                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t('type_handicape')}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill

                                            }}
                                        />

                                    )}
                                />
                            </Grid>
                        ) : <Grid item xs={12} sm={6}>

                        </Grid>}
                        <Grid item xs={12} sm={3}>
                            <Divider sx={{mt: 2}} variant="middle" color={"orange"}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="outlined-multiline-static"
                                label={t('champ_adresse')}
                                fullWidth
                                multiline
                                rows="3"
                                value={adresse || " "}
                                onChange={(e) => {
                                    setAdresse(e.target.value)
                                }}
                                error={adresseError}
                                helperText={adresseError ? t('invalid_adresse') : ' '}
                                variant="outlined"
                                name="adresse"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Divider sx={{mt: 2}} variant="middle" color={"orange"}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id="country-select-demo"
                                options={VillesJs}
                                autoHighlight
                                getOptionLabel={(option) => option.ville_fr || ""}


                                onChange={(event, newValue) => {
                                    setVilleValue(newValue)
                                }}
                                value={VilleValue}

                                renderOption={(props, option) => (
                                    <Box component="li"  {...props} key={option.id}>
                                        {option.ville_fr}
                                    </Box>

                                )}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t('ville')}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill

                                        }}
                                    />

                                )}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id="country-select-demo"
                                options={codespostale}
                                autoHighlight
                                getOptionLabel={(option) => `${option.id_code_postal}: ${option.libelle_secteur}` || null}
                                onChange={(event, newValue) => {
                                    setCodePostaleValue(newValue)
                                }}
                                value={codePostaleValue}
                                renderOption={(props, option) => (
                                    <Box component="li"  {...props} key={option.id_code_postal}>
                                        {option.id_code_postal} : {option.libelle_secteur}
                                    </Box>

                                )}
                                isOptionEqualToValue={(option, obj) => option.id_code_postal === obj.id_code_postal}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t('code_postal')}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill

                                        }}
                                    />

                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id="country-select-demo"
                                options={JobsParents}
                                autoHighlight
                                getOptionLabel={(option) => option.libelle_categorie_sosciopro || ""}
                                onChange={(event, newValue) => {
                                    setProfPere(newValue)
                                }}
                                value={profPere}

                                renderOption={(props, option) => (
                                    <Box component="li"  {...props} key={option.code_categorie_sociopro_stat}>
                                        {option.libelle_categorie_sosciopro}
                                    </Box>

                                )}
                                isOptionEqualToValue={(option, value) => option === value}

                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t('profession_pere')}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill

                                        }}

                                    />

                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                id="country-select-demo"
                                options={JobsParents}
                                autoHighlight
                                getOptionLabel={(option) => option.libelle_categorie_sosciopro || ""}
                                onChange={(event, newValue) => {
                                    setProfMere(newValue)
                                }}
                                value={profMere}
                                renderOption={(props, option) => (
                                    <Box component="li"  {...props} key={option.code_categorie_sociopro_stat}>
                                        {option.libelle_categorie_sosciopro}
                                    </Box>

                                )}


                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t('profession_mere')}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill

                                        }}

                                    />

                                )}
                            /> </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="telephone"
                                name="telephone"
                                label={t('telephone')}
                                fullWidth
                                value={telephone || ""}
                                onChange={(e) => {
                                    seTtelephone(e.target.value)
                                }}
                                error={telephoneError}
                                helperText={telephoneError ? t('invalid_telephone') : ' '}
                                autoComplete="off"
                                variant="outlined"

                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="email"
                                value={email || ""}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                error={emailError}
                                helperText={emailError ? t('invalid_email') : ' '}
                                name="email"
                                label={t('email')}
                                fullWidth
                                autoComplete="off"
                                variant="outlined"
                            />

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                            <InfoCndp/>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{mt: 3, ml: 1, textAlign: "center"}}
                                endIcon={<SendIcon/>}
                                disabled={loading}
                            >
                                {t('enregistrer')}
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
                :
                <Box sx={{display: 'flex'}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Box>
            }
        </React.Fragment>
    );
}

