import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import SendIcon from '@mui/icons-material/Send';
import i18n from "../../../multilingue/i18n";
import {BASE_URL, baseAxiosCondidature, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import {useEffect, useState} from "react";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";
import {Alert} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import Avatar from "@mui/material/Avatar";
import InfoPhoto from "../../../images/info_photo.png"

export default function ImportBac(props) {
    const {t} = useTranslation();

    const [loading, setloading] = useState(false);
    const [selectBacFile, setSelectBacFile] = useState(null);
    const [selectCinFile, setSelectCinFile] = useState(null);
    const [selectPhotoFile, setSelectPhotoFile] = useState(null);
    const [isImporteFichiers, setIsImporteFichiers] = useState(false);
    const [etudiant, setEtudiant] = useState(null);
    const [selectEngagementFile, setSelectEngagementFile] = useState(null);
    const [selectRibFile, setSelectRibFile] = useState(null);
    const [selectAssuranceFile, setSelectAssuranceFile] = useState(null);
    const [selectSanitaireFile, setSelectSanitaireFile] = useState(null);


    useEffect(() => {

        const information_step_one = (params) => {
            baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/candidature_information_by_step`, params)
                .then(async res => {
                    if (res.data.status === 200 && res.data.params.etudiant) {
                        let etudiant = res.data.params.etudiant;
                        if (etudiant.file_bac_id && etudiant.file_cin_id && etudiant.file_photo_id) {
                            setIsImporteFichiers(true)
                        }
                        setEtudiant(etudiant);

                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }


                })
        }

        let isMounted = true;

        if (isMounted) {
            let datastep = new FormData();
            datastep.set('step', 'bac')
            information_step_one(datastep)
        }

        return () => {
            isMounted = false;
        };


    }, [])

    const upload_files = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/candidature_upload_files`, params)
            .then(async res => {
                if (res.data.status === 1) {
                    await Swal.fire({
                        title: t('step_6_candidat'),
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.EtapeSuivant();
                        }
                    })
                    setloading(false)

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setloading(false)

                } else {
                    setloading(false)
                }
                setloading(false)


            })
    }
    const handlchangebacfile = (event) => {
        let file = event.target.files[0];


        let fileName = document.querySelector('#baccalaureat').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setSelectBacFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectBacFile("")

        }


    }
    const handlchangefileEngagement = (event) => {
        let file = event.target.files[0];
        let fileName = document.querySelector('#file_engagement_id').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setSelectEngagementFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectEngagementFile("")
        }
    }
    const handlchangefileAttestation = (event) => {
        let file = event.target.files[0];
        let fileName = document.querySelector('#file_attestation_rib_id').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setSelectRibFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectRibFile("")
        }
    }
    const handlchangefileAssurance = (event) => {
        let file = event.target.files[0];
        let fileName = document.querySelector('#file_assurance_id').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setSelectAssuranceFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectAssuranceFile("")
        }
    }
    const handlchangefileFiche_sanitaire = (event) => {
        let file = event.target.files[0];
        let fileName = document.querySelector('#file_fiche_sanitaire_id').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setSelectSanitaireFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectSanitaireFile("")
        }
    }


    const handlchangecinfile = (event) => {
        let file = event.target.files[0];
        let fileName = document.querySelector('#cnie').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setSelectCinFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectCinFile("")
        }
    }
    const handlchangephotofile = (event) => {
        let file = event.target.files[0];
        let fileName = document.querySelector('#photo').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "jpg" || extension === "jpeg" || extension === "png") {
            setSelectPhotoFile(file)
        } else {
            document.querySelector('#photo').value = null;
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier image ( JPGE,JPG,PNG ...)",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectPhotoFile("")

        }
    }
    const handlformsubmit = (event) => {
        event.preventDefault();

        setloading(true);
        if (selectBacFile && selectCinFile && selectPhotoFile) {
            const data = new FormData();
            data.set("bac_file", selectBacFile)
            data.set("cin_file", selectCinFile)
            data.set("photo_file", selectPhotoFile)
            upload_files(data);
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "merci d'importer les fichiers correctement !",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setloading(false);
        }

    }
    return (
        <React.Fragment>
            <Box component="form" dir={i18n.language === "ar" ? "rtl" : "ltr "} container spacing={3}
                 onSubmit={handlformsubmit}>
                <Grid container spacing={3}>

                    {(!isImporteFichiers ||etudiant && !etudiant.file_bac_id )  && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t("baccalaureat_fichie")} (PDF)
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    onChange={handlchangebacfile}
                                    id="baccalaureat"
                                    name="baccalaureat"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="filled"
                                    type={"file"}
                                    inputProps={{accept: ".pdf"}}

                                />
                            </Grid>
                        </>
                    )}

                    {etudiant && etudiant.file_bac_id && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t("baccalaureat_fichie")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <Alert variant="filled" severity="success">
                                    {t('importe_avec_succes')}
                                </Alert>
                            </Grid>

                        </>
                    )}

                    {(!isImporteFichiers ||etudiant && !etudiant.file_cin_id) && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('cnie')} (PDF)
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    onChange={handlchangecinfile}
                                    id="cnie"
                                    name="cnie"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="filled"
                                    type={"file"}
                                    inputProps={{accept: ".pdf"}}
                                />
                            </Grid>
                        </>
                    )}
                    {etudiant && etudiant.file_cin_id && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('cnie')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <Alert variant="filled" severity="success">
                                    {t('importe_avec_succes')}
                                </Alert>
                            </Grid>
                        </>
                    )}
                    {(!isImporteFichiers ||etudiant && !etudiant.file_photo_id )&& (
                        <>
                            <Grid item xs={12} sm={12} dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                                <Alert severity="error"><Typography
                                    fontWeight={"bold"}>{t('info_photo_etudiant')}</Typography></Alert>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('photo')} (image)
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onChange={handlchangephotofile}
                                    id="photo"
                                    name="photo"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="filled"
                                    type={"file"}
                                    inputProps={{accept: "image/*"}}

                                />
                            </Grid>
                        </>
                    )}
                    {etudiant && etudiant.file_photo_id && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <Typography>
                                    {t('photo')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <Alert variant="filled" severity="success">
                                    {t('importe_avec_succes')}
                                </Alert>
                            </Grid>
                        </>
                    )}


                </Grid>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                        <InfoCndp/>
                    </Grid>
                    <Grid item xs={3}>
                        <Button

                            variant="contained"
                            type="submit"
                            sx={{mt: 3, ml: 1, textAlign: "center"}}
                            endIcon={<SendIcon/>}
                            dir={"ltr"}
                            disabled={loading}
                        >
                            {t('enregistrer')}
                        </Button>
                    </Grid>

                </Grid>
            </Box>

            <>


                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >

                    <Grid item xs={12}>
                        <Button
                            onClick={(e) => {
                                setIsImporteFichiers(false)
                            }
                            }
                            variant="contained"
                            type="submit"
                            sx={{mt: 3, ml: 1, textAlign: "center"}}
                            endIcon={<EditIcon/>}
                            color={"info"}
                            dir={"ltr"}
                        >
                            {t('remplacer_fichiers')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                        <InfoCndp/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{mt: 3, ml: 1, textAlign: "center"}}
                            endIcon={<SendIcon/>}
                            onClick={() => props.EtapeSuivant()}
                        >
                            {t('suivant')}
                        </Button>
                    </Grid>
                </Grid>
            </>


        </React.Fragment>
    );
}
