import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { BASE_URL, baseAxios, Version} from "../../../repository/baseAxios";
import Filiere from "../filiere";
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Widget from "../../../components/Widget";
import useStyles from "../../dashboard/styles";
import CardHeader from '@mui/material/CardHeader';
import {isMobile} from 'react-device-detect';
import CardContent from '@mui/material/CardContent';
import Swal from "sweetalert2";
import SendIcon from '@mui/icons-material/Send';
import {useTranslation} from "react-i18next";
import {Alert, AlertTitle, CircularProgress} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ModalPolicy from "../../../components/Widget/Cndp/ModalPolicy";
import {Close} from "@material-ui/icons";
import {useHistory} from "react-router-dom/cjs/react-router-dom";

export default function ChoixFilieres(props) {
    const [loading, setloading] = useState(false);
    const [loadingContent, setloadingContent] = useState(true);
    const [DataErreurCndp, setDataErreurCndp] = useState(false)
    const [MsgErreurCndp, setMsgErreurCndp] = useState('Erreur !');

    const {t} = useTranslation();
    var classes = useStyles();
    // local
    const [checked, setChecked] = React.useState(false);

    const [filiereIp, setFiliereIp] = useState([]);
    const [Etablissements, setEtablissements] = React.useState([]);
    const [ignorerIp, setIgnorerIp] = React.useState();
    const [ignorerIpVisible, setIgnorerIpVisible] = React.useState(true);
    const [openCndp, setOpenCndp] = useState(false);

    const [aucunInscription, setAucunInscription] = React.useState(false);
    const [Tokeninscription, setTokeninscription] = React.useState(false);
    const [validation, setValidation] = useState(false);
         var history = useHistory();
    const changeIgnored = () => {
        setIgnorerIp('no')
        setIgnorerIpVisible(true);

        let datauthorized = new FormData();
        datauthorized.set('ignorer_ip', ignorerIp);
        authorized_inscription(datauthorized);
    }

    let data = new FormData();
    const add_registration = (params) => {
        baseAxios.post(`${BASE_URL}/inscription/${Version}/etudiant/add_registration`, params)

            .then(res => {

                if (res.data.status === 200) {

                    Swal.fire({
                        title: t('step_1'),
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {

                        if (result.isConfirmed) {
                            props.EtapeSuivant();
                        }
                    })
                    get_registration()
                    setloading(false)
                } else if (res.data.status === -1) {

                    Swal.fire({
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setloading(false)
                }else{
                    setloading(false)
                }
                setloading(false)
            })
    }
    const remove_registration = (id_inscription) => {
        let removedata = new FormData();
        removedata.set('id_inscription', id_inscription)
        baseAxios.post(`${BASE_URL}/inscription/${Version}/etudiant/remove_registration`, removedata)

            .then(res => {

                if (res.data.status === 200) {


                    Swal.fire(t('inscription_supprimer'), '', 'success')
                    setTokeninscription("");
                    setIgnorerIp('yes')
                    setIgnorerIpVisible(true);

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merci de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }


            })
    }
    const authorized_inscription = (param) => {
        baseAxios.post(`${BASE_URL}/inscription/${Version}/etudiant/authorized_inscription`, param)

            .then(res => {
                if (res.data.status === 200 && res.data.params.length > 0) {
                    const allfilieres = res.data.params;
                    setEtablissements(allfilieres)
                    setloadingContent(false)
                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: 'ERROR !',
                        text: 'Merci de réssayée au plus tard !',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                }
                else {
                    setloadingContent(false)
                    setAucunInscription(true)
                }


            })
    }
    const get_registration = () => {
        baseAxios.post(`${BASE_URL}/inscription/${Version}/etudiant/get_registration`)

            .then(res => {

                if (res.data.status === 200) {
                    if (res.data.params.ips && res.data.params.ips.length > 0) {
                        const filiere = res.data.params.ips;
                        setIgnorerIp('no')
                        setIgnorerIpVisible(false);
                        setFiliereIp(filiere)
                        setloadingContent(false)
                    } else {
                        setIgnorerIp('yes')
                        setIgnorerIpVisible(true);

                    }

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merci de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                }else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                }
            })
    }
   const remove_registration_f = (id_ip) => {
       Swal.fire({
           title: "Etes-vous sûr que vous voulez supprimer ?",
           showCancelButton: true,
           confirmButtonText: 'Supprimer',
           denyButtonText: `Don't save`,
       }).then((result) => {
           /* Read more about isConfirmed, isDenied below */
           if (result.isConfirmed) {
               remove_registration(id_ip)
           }
       })

   }
    const acceptCndpBeforeLogin = ()=>{
setDataErreurCndp(false)
        setMsgErreurCndp("")
        if(checked){
            let cndpData= new FormData();
            cndpData.set('accept_cndp',"yes");
            accept_cndp(cndpData);
        }else{
            setDataErreurCndp(true)
            setMsgErreurCndp("Errur ! Accepter   les conditions générales d'utilisation")
        }
    }
    const accept_cndp = (params) => {
        baseAxios.post(`${BASE_URL}/inscription/${Version}/etudiant/accept_cndp`, params)

            .then(res => {

                if (res.data.status === 1 ) {

                 localStorage.setItem('cndp','yes')
                        setOpenCndp(false)


                } else if (res.data.status === -1) {
                    setDataErreurCndp(true)
                    setMsgErreurCndp(res.data.message)

                    return false;
                }
                else{
                    setOpenCndp(false)
                    Swal.fire({
                        title: "Erreur !",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor:'red',
                        color : 'red'
                    } )
                    setloading(false);

                }


            })
    }
    useEffect(() => {

        let accept_cndp = localStorage.getItem("cndp");
        let redirectService = localStorage.getItem("redirectService");
        let redirectCondidat = localStorage.getItem("redirectCondidat");


        if (accept_cndp !== "yes") {
            setOpenCndp(true);
        }


        const information_step_one = (params) => {
            baseAxios.post(`${BASE_URL}/inscription/${Version}/etudiant/information_by_step`, params
            )

                .then(async res => {
                    if (res.data.status === 200 && res.data.params.etudiant && res.data.params.compte) {

                        let etudiant = res.data.params.etudiant;
                        if (etudiant) {
                            setTokeninscription(etudiant.ip_token)
                            setValidation(etudiant.validation)
                        }

                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merci de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    } else if (res.data.status === 403) {
                        localStorage.clear();
                        window.location.href = "/login"

                    }


                })
        }

        let isMounted = true;
         let candidat=false;
        if (redirectService === "yes") {
            candidat=false;
            isMounted = false;

        }else if(redirectCondidat==="yes"){
            isMounted = false;
            candidat=true;

        }
        if (isMounted) {


            let datastep = new FormData();
            datastep.set('step', 'choix_filiere');
            information_step_one(datastep);
            get_registration();

        } else if (!isMounted && !candidat){
          //  history.push('/service/reinscription')
          //  window.location.reload();
            window.location.href="#/service/reinscription"
        }else {
            history.push('/candidature/steps')
        }


    }, []);
    useEffect(() => {
        if (ignorerIp === "yes") {
            let datauthorized = new FormData();
            datauthorized.set('ignorer_ip', ignorerIp);
            authorized_inscription(datauthorized);
        }

    }, [ignorerIp]);

    const handlformsubmit = (event) => {
        event.preventDefault();

        data.set('code_massar', localStorage.getItem('code_massar'))

        if (Tokeninscription) {
            data.set('ip_token', Tokeninscription)
        }
        let source = data.get('source');
        let id_opi_bac = data.get('id_opi_bac');
        let source_opi = data.get('source_opi');
        let token_filiere_v = data.get('token_filiere');
        if (!source_opi && !token_filiere_v) {
            Swal.fire({
                title: "Erreur ! ",
                text: t('erreur_filiere'),
                icon: 'error',
                confirmButtonText: t('confirmer'),
                confirmButtonColor: 'red',
                color: 'red'
            })
        } else if (!source && !id_opi_bac) {
            Swal.fire({
                title: "Erreur ! ",
                text: t('erreur_bac_filiere'),
                icon: 'error',
                confirmButtonText: t('confirmer'),
                confirmButtonColor: 'red',
                color: 'red'
            })
        } else {

            setloading(true)
            add_registration(data);

            setloading(false)
        }
    }

    return (
        <React.Fragment>
            {!loadingContent ?

                <Box component="form" container spacing={3} onSubmit={handlformsubmit}>
                    <Grid container spacing={3}>
                        {!ignorerIpVisible && (filiereIp.map((fil, index) => {
                                    if (fil.filiere_id) {
                                        return (
                                            <>
                                                <Grid item xs={12} sm={12} key={index}>
                                                    <Alert variant="filled" sx={{fontSize: 18, bgcolor: "#007fa4",mb:4}}
                                                           action={(!isMobile ) &&( !validation  &&
                                                               (<ButtonGroup variant="contained"
                                                                             aria-label="outlined  button group">

                                                                   <Button variant="contained" color={"error"}
                                                                           onClick={() => remove_registration_f(fil.id_inscription)}
                                                                           size={"small"} startIcon={<DeleteIcon/>}>
                                                                   </Button>
                                                               </ButtonGroup>))
                                                           }
                                                    >
                                                        <AlertTitle
                                                            sx={{fontSize: 18}}><strong>{fil.filiere.libelle_filiere}</strong></AlertTitle>
                                                        <strong> {fil.etablissement.abreviation_etablissement} </strong>— {fil.diplome.libelle_diplome_fr}
                                                        <strong> : {fil.annee_inscription}</strong>
                                                        {(isMobile ) &&
                                                           ( !validation && (<ButtonGroup style={{marginTop: 6}} variant="contained"
                                                                          aria-label="outlined  button group">

                                                                    <Button variant="contained" color={"error"}
                                                                            onClick={() => remove_registration(fil.id_inscription)}
                                                                            size={"small"} startIcon={<DeleteIcon/>}>
                                                                    </Button>
                                                                </ButtonGroup>
                                                            ))}
                                                    </Alert>

                                                </Grid>




                                            </>
                                        )
                                    }

                                }
                            )
                        )}
                        {
                            !aucunInscription ? (ignorerIpVisible && (Etablissements.map((e, index) => {
                                    return (

                                        <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                            <Widget disableWidgetMenu
                                                    upperTitle
                                            >
                                                <div className={classes.visitsNumberContainer}>
                                                    <Grid container item>
                                                        <Grid item xs={12}>
                                                            {
                                                                !isMobile ? (<CardHeader
                                                                    style={{textTransform: 'uppercase'}}
                                                                    avatar={
                                                                        <Avatar
                                                                            style={{float: "right"}}
                                                                            sx={{
                                                                                height: 56,

                                                                                "& .css-1pqm26d-MuiAvatar-img": {
                                                                                    objectFit: "contain"
                                                                                }

                                                                            }}

                                                                            alt="Etablissement logo"
                                                                            src={e.etablissement.logo}
                                                                            variant="square"
                                                                        />
                                                                    }
                                                                    title={e.etablissement.slug}
                                                                    subheader={e.etablissement.name}

                                                                />) : (<CardContent sx={{flex: '1 0 auto'}}>
                                                                    <Typography component="div" variant="h5">
                                                                        <Avatar
                                                                            sx={{
                                                                                width: 200, height: 56,
                                                                                "& .css-1pqm26d-MuiAvatar-img": {
                                                                                    objectFit: "contain"
                                                                                }

                                                                            }}

                                                                            alt="Remy Sharp"
                                                                            src={e.etablissement.logo}
                                                                            variant="square"
                                                                        />
                                                                    </Typography>
                                                                    <Typography variant="h6" component="div">
                                                                        {e.etablissement.name}
                                                                    </Typography>
                                                                </CardContent>)
                                                            }


                                                        </Grid>
                                                    </Grid>
                                                </div>


                                                <Filiere sx={{bgcolor: 'primary.main'}} SubmitForm={data}  ListFilieres={e.filieres} etab={Etablissements}
                                                />

                                            </Widget>
                                        </Grid>


                                    )


                                })))
                                : (
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12} sm={12} style={{
                                            marginTop: 14,
                                            background: "#FDEDED",
                                            textAlign: "center",
                                            padding: 13,
                                            marginBottom: 14
                                        }}>
                                            <ErrorOutlineIcon sx={{
                                                color: 'red', fontSize: 50
                                            }}/>
                                            <Typography variant={"h5"}> Aucune inscription n'est
                                                <strong> disponible !</strong>
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                )
                        }

                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        {/*  <Grid item xs={12} sm={6} >
                            <Button

                                variant="contained"
                                sx={{mt: 3, mb: 3, textAlign: "center"}}
                                color={"warning"}
                                onClick={()=>{window.open('https://carte.enim.ac.ma')}}
                            >
                                carte universitaire
                            </Button>
                        </Grid>*/}
                        {(ignorerIp === "yes" && !aucunInscription) && (
                            <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                                <InfoCndp/>
                            </Grid>
                        )
                        }

                        <Grid item xs={3}>
                            {ignorerIpVisible ? (
                                !aucunInscription && (<Button
                                    variant="contained"
                                    type="submit"
                                    sx={{mt: 3, mb: 3, ml: 1, textAlign: "center"}}
                                    endIcon={<SendIcon/>}
                                    disabled={loading}
                                >
                                    {t('enregistrer')}
                                </Button>)

                            ) : (<Button
                                variant="contained"
                                type="submit"
                                sx={{mt: 3, mb: 3, ml: 1, textAlign: "center"}}
                                endIcon={<SendIcon/>}
                                onClick={() => props.EtapeSuivant()}
                            >
                                {t('suivant')}
                            </Button>)}

                        </Grid>

                    </Grid>

                </Box>
                :
                <Box sx={{display: 'flex'}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Box>
            }
            <Box component="form" spacing={3}>
                <Grid container spacing={3}>

                    <Dialog
                        style={{padding: 10}}
                        open={openCndp}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >

                        <DialogTitle id="alert-dialog-title">
                            les conditions générales d'utilisation
                        </DialogTitle>
                        <DialogContent>
                            {DataErreurCndp ? <Alert variant="filled" severity="error">
                                {MsgErreurCndp}
                            </Alert> : ("")

                            }
                            <Grid item xs={12} sm={12} textAlign={"center"}>
                                <FormControlLabel
                                    value="top"

                                    style={{marginTop: 7, marginBottom: 5, float: "left"}}
                                    control={<Checkbox value="remember" color="primary"/>}
                                    checked={checked} id={"accept"} onChange={(event) => {
                                    setChecked(event.target.checked)
                                }}
                                />
                                <ModalPolicy/>
                            </Grid>
                            <Grid item xs={6} sm={6} style={{display: "inline"}}>


                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                                    endIcon={<SendIcon/>}
                                    onClick={acceptCndpBeforeLogin}
                                >
                                    {t('accepter')}
                                </Button>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>

            </Box>

        </React.Fragment>
    );
}
