import React, {useState} from "react";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Flag from 'react-world-flags'
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Copyright} from "../../../context/UserContext";
import Grid from "@mui/material/Grid";
import {BASE_URL, baseAxiosCondidature, Version} from "../../../repository/baseAxios";
import {Alert, IconButton, OutlinedInput, Stack} from "@mui/material";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {useTranslation} from "react-i18next";
import i18n from "../../../multilingue/i18n";
import Swal from "sweetalert2";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ModalPolicy from "../../../components/Widget/Cndp/ModalPolicy";
import HomeIcon from "@mui/icons-material/Home";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";


const theme = createTheme();

function PrienscriptionAccount() {

    const { t } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }
    const [loading, setloading] = useState(false);

    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirme, setPasswordConfirme] = useState('')
    const [emailNum, setEmailNum] = useState('')

    const [nomError, setNomError] = useState(false)
    const [emailNumError, setEmailNumError] = useState(false)
    const [ddnp, setDdnp] = useState("")
    const [ddnErrorp, setDdnErrorp] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [passwordConfirmeError, setPasswordConfirmeError] = useState(false)

    const [prenomError, setPrenomError] = useState(false)
    const [DataErreur, setDataErreur] = useState(false)
    const [MsgErreur, setMsgErreur] = useState('Erreur !')
    const [checked, setChecked] = React.useState(false);
    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const history=useHistory();
    const CreateAccountCondidat = (params) => {
         baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/create_account_candidat`, params)

            .then(res => {

                if (res.data.status === 200 && res.data.params.access_token ){

                    localStorage.setItem('candidature_token', res.data.params.access_token);
                    localStorage.setItem('redirectCondidat',"yes")




                    window.location.reload();
                 //   window.location.href="/candidature/steps"



                } else if (res.data.status === -1) {
                    setDataErreur(true)
                    setMsgErreur(res.data.message)
                    setloading(false);
                    return false;
                }else{
                    Swal.fire({
                        title: "Erreur !",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor:'red',
                        color : 'red'
                    } )
                    setloading(false);
                }


            })

    }
    const handlformsubmit = (event) => {
        event.preventDefault();
        setDataErreur(false)
        setNomError(false)
        setPrenomError(false)
        setEmailNumError(false)
        setPasswordError(false)
        setDdnErrorp(false);
        setPasswordConfirmeError(false)
        if (nom === '' || nom.indexOf(" ") !== -1) {
            setNomError(true);
            return false;
        }
        if (prenom === '' || prenom.indexOf(" ") !==-1) {
            setPrenomError(true);
            return false;
        }
        if (ddnp === '' || !dateValid(ddnp)) {
            setDdnErrorp(true);
            return false;
        }

        if (emailNum === '' || emailNum.indexOf(" ") !==-1) {
            setEmailNumError(true);
            return false;
        }
        if (password === '' ) {
            setPasswordError(true);
            return false;
        }
        if (password.length<6) {
            setPasswordError(true);
            setDataErreur(true);
            setMsgErreur('Le mot de passe doit contenir au moins 6 caractères.')
            return false;
        }
        if (password.indexOf(" ")!==-1) {
            setPasswordError(true);
            setDataErreur(true);
            setMsgErreur('Erreur : Le champ de passe ne peut pas inclure d\'espaces.\n')
            return false;
        }
        if (passwordConfirme === '' || passwordConfirme.indexOf(" ")!==-1) {
            setPasswordConfirmeError(true);
            setDataErreur(true);
            setMsgErreur('Erreur : Le champ de confirmation de mot de passe ne peut pas inclure d\'espaces.\n')
            return false;
        }
        if ( !(password===passwordConfirme)){
            setDataErreur(true)
            setPasswordConfirmeError(true);
            setMsgErreur('confirmation mot de passe est incorrect !')
            return  false;
        }


        const data = new FormData();
        if ( !checked){
            setDataErreur(true)
            setMsgErreur("Erreur ! Merci de d'accepte les Conditions générales d'utilisation")
            document.getElementById("accept").style.border="1px red solid"
            return  false;

        }else {
            setDataErreur(false)
            document.getElementById("accept").style.border="none"
            data.set("accept_cndp","yes");
        }

        setloading(true)
        data.set("nom",nom)
        data.set("prenom",prenom)
        data.set("date_naissance",ddnp)
        data.set("email",emailNum)
        data.set("password",password)
        data.set("password_confirmation",passwordConfirme)


        CreateAccountCondidat(data)

    }
    const dateValid = (value) => {
        var date_regex = /^(19|20)\d{2}\-(0[1-9]|1[0-2])\-(0[1-9]|1\d|2\d|3[01])$/;

        if (!(date_regex.test(value))) {
            return false
        }else{
            return true
        }
    }



    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Stack spacing={2} direction="row">
                    <Button variant={"contained"} color={"warning"} onClick={()=>{history.push('/')}} sx={{ textAlign: "left",mt:5}} size="medium" >
                        <HomeIcon />
                    </Button>
                </Stack>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack spacing={2} direction="row" sx={{ marginBottom: 8 }} >
                        <Button variant="contained"  onClick={()=>changeLanguage('en')}><Flag code="GB_NIR" height="16" /></Button>
                        <Button variant="contained"  onClick={()=>changeLanguage('fr')}><Flag code="GF" height="16" /> </Button>
                        <Button variant="contained"  onClick={()=>changeLanguage('ar')}><Flag code="MA" height="16" /> </Button>
                    </Stack>
                    {DataErreur ? <Alert variant="filled" severity="error">
                        {MsgErreur}
                    </Alert> : ("")

                    }
                    <Avatar sx={{m: 1, bgcolor: 'success.main', fontSize: 'large'}}>
                        <PersonIcon/>

                    </Avatar>

                    <Typography component="h1" fontWeight={"bold"}  variant="h5" color="primary" >
                        {t("cree_un_compte") }
                    </Typography>
                    <Box component="form" onSubmit={  handlformsubmit} noValidate sx={{mt: 1}}>

                        <TextField
                            required
                            style={{marginTop:10}}
                            onChange={(e) => setNom(e.target.value.toLowerCase())}
                            margin="normal"
                            fullWidth
                            id="nom_fr"
                            error={nomError}
                            helperText={!nomError ? t('champ_obligatoire') : 'Erreur : Le Nom ne peut pas contenir un espace.'}
                            label={t('nom_fr')}
                            value={nom}
                            autoComplete="off"
                            autoFocus
                            

                        />

                        <TextField
                            required
                            style={{marginTop:10}}
                            onChange={(e) => setPrenom(e.target.value.toLowerCase())}
                            margin="normal"
                            fullWidth
                            id="prenom_fr"
                            error={prenomError}
                            helperText={!prenomError  ? t('champ_obligatoire') : 'Erreur : Le prénom ne peut pas contenir un espace.'}
                            label={t('prenom_fr')}
                            value={prenom}
                            autoComplete="off"
                            autoFocus
                        />
                        <TextField
                            style={{marginTop:10}}
                            required
                            id="date_naissance"
                            value={ddnp}
                            onChange={(e) => {
                                setDdnp(e.target.value)
                            }}
                            label={t('date_naissance')}
                            fullWidth
                            type={"date"}

                            InputLabelProps={{ shrink: true }}
                            autoComplete="off"
                            variant="outlined"
                            helperText={ddnp === "" ? t('champ_obligatoire') : ' '}
                            error={ddnErrorp}
                        />

                            <Grid>
                                <TextField
                                required
                                onChange={(e) => setEmailNum(e.target.value.toLowerCase())}
                                margin="normal"
                                fullWidth
                                id="email"
                                helperText={!emailNumError ? t('champ_obligatoire') : "Erreur : L'entrée ne peut pas contenir un espace."}
                                label={t('email_num')}
                                name="email_num"
                                value={emailNum}
                                autoComplete="off"
                                autoFocus
                                error={emailNumError}

                            />
                            </Grid>
                        <Grid>

                            <FormControl variant="outlined" fullWidth sx={{mt: 2}}>
                                <InputLabel htmlFor="outlined-adornment-password">{t('mot_de_passe')}</InputLabel>
                                <OutlinedInput
                                    required
                                    id="password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}
                                    autoComplete={"current-password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}

                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={t('mot_de_passe')}
                                    error={passwordError}



                                />
                            </FormControl>
                        </Grid>
                        <Grid>
                                <TextField
                                    required
                                    onChange={(e) => setPasswordConfirme(e.target.value)}
                                    margin="normal"
                                    fullWidth
                                    id="password_confirmation"
                                    helperText={passwordConfirme === "" ? t('champ_obligatoire') : ' '}
                                    label={t('mot_de_passe_confirmation')}
                                    name="password_confirmation"
                                    autoComplete="off"
                                    value={passwordConfirme}
                                    autoFocus
                                    type="password"
                                    error={passwordConfirmeError}

                                />

                            </Grid>


                        <FormControlLabel
                            value="top"

                            style={ { marginTop:7,marginBottom:5,float:"left" }}
                            control={<Checkbox value="remember" color="primary" />}
                            checked={checked}  id={"accept"} onChange={(event)=>{setChecked(event.target.checked)}}
                        />
                        <ModalPolicy />

                        <Grid container>
                            <Button
                                type="submit"
                                color="success"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                disabled={loading}
                            >
                                {t('connexion')}
                            </Button>


                            <Grid item  xs={12} sm={12} dir={i18n.language==="ar"   ?"rtl"  : "ltr " }>
                                <Button

                                    onClick={() =>
                                        history.push("/candidature/login")
                                    }
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                >
                                    { t("vous_avez_deja_un_compte")  }
                                </Button>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

            </Container>

            <Copyright sx={{mt: 4, mb: 4}}/>


        </ThemeProvider>

    );

}

export default PrienscriptionAccount;