import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import SchoolIcon from '@mui/icons-material/School';
import "./styleSteps.css"

import ListItemIcon from '@mui/material/ListItemIcon';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Alert, AlertTitle, CircularProgress, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import SendIcon from "@mui/icons-material/Send";
import {BASE_URL, baseAxios, baseAxiosCondidature, candidature_token, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {isMobile} from "react-device-detect";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {Download} from "@mui/icons-material";
import i18n from "../../../multilingue/i18n";
import TextField from "@mui/material/TextField";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {useHistory} from "react-router-dom";

export default function ChoixCondidature(props) {
    var {t} = useTranslation();
    const [etablissements, setEtablissements] = useState();
    const [selectCv, setSelectCv] = useState();
    const [candidatures, setCandidatures] = useState(null);
    const [hasCandidatures, setHasCandidatures] = useState(false);

    const [etablissementVal, setEtablissementVal] = useState("");
    const [showCondidature, setShowCondidature] = useState(false);
    const [etablissementError, setEtablissementError] = useState(false);
    const [addCondidature, setAddCondidature] = useState(false);
    const [addIps, setAddIps] = useState(false);
    const [etudiantCondidatures, setEtudiantCondidatures] = useState();
    const [loadingContent, setloadingContent] = useState(true);
    const [typeCondidatureId, setTypeCondidatureId] = useState();
    const [typeCondidatures, setTypeCondidatures] = useState();
    const [hasCondidat, setHasCondidat] = useState(false);
    const [doctorat, setDoctorat] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [showLaboSujets, setShowLaboSujets] = useState(false);
    const [laboSujets, setLaboSujets] = React.useState(null);
    const [aucunCandidature, setAucunCandidature] = useState(false);
    const get_candidatures_etudiant = () => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/get_candidatures_etudiant`)
            .then(async res => {
                if (res.data.status === 200 && res.data.params) {
                    if (res.data.params.length>0) {
                        setloadingContent(false)
                        setEtudiantCondidatures(res.data.params)
                        setHasCondidat(true)

                    } else {
                        setloadingContent(false)
                        setHasCondidat(false)

                    }

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merci de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }


            })
    }
    useEffect(() => {
        let isMounted = true;
        if(isMounted ){
            get_candidatures_etudiant()


        }

        return () => {
            isMounted = false;
        };


        setloadingContent(false)
    }, []);
    const get_etablissement_by_type_candidature = () => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/get_etablissement_by_type_candidature`)

            .then(async res => {
                if (res.data.status === 200 && res.data.params) {
                    if (res.data.params) {
                        setTimeout(
                            () => {
                                setAucunCandidature(false)
                                setEtablissements(res.data.params)
                                setloadingContent(false)
                            }, 200)
                    } else {

                        Swal.fire({
                            title: "Erreur Merci de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                    }

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merci de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }


            })
    }
    const save_candidature_etudiant = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/save_candidature_etudiant`, params)

            .then(async res => {
                if (res.data.status === 200) {

                    setAddCondidature(false)
                    setAddIps(false)
                    setSelectCv("");
                    get_candidatures_etudiant()
                } else if (res.data.status === -1) {
                    setLoadingSave(false)
                    Swal.fire({
                        title: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }


            })
    }
    const get_candidature_by_type_candidature_and_etablissements = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/get_candidature_by_type_candidature_and_etablissements`, params)

            .then(async res => {
                if (res.data.status === 200 && res.data.params) {
                    if (res.data.params.length>0) {
                        setAucunCandidature(false)
                        setTimeout(
                            () => {
                                setCandidatures(res.data.params)
                                setHasCandidatures(true)
                            }, 200)
                    } else {
                        setAucunCandidature(true)
                        setHasCandidatures(false)
                    }

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merci de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }


            })
    }
    const handlchangeEtablissement = (event) => {
        setLoadingSave(false)
        setAucunCandidature(false);
        let t= event.target.value
        setShowCondidature(true)
        let data = new FormData();
        if (t && typeCondidatureId) {
            data.set('etablissement_id', t)
            data.set('type_candidature_id', typeCondidatureId)
            get_candidature_by_type_candidature_and_etablissements(data)
        } else {
            setEtablissementError(true)
            Swal.fire({
                title: "Erreur Merci de ressayer au plus tard",

                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
        }


    }
    const getCondidatureValue = (token_candidature) => {

        let data = new FormData();
        setLoadingSave(true)
        if (token_candidature) {
            Swal.fire({
                title: "Merci de confirmer votre inscription  ",
                icon: 'success',
                showCancelButton: true,
                cancelButtonText: t('retour'),
                confirmButtonText: t('confirmer'),
                confirmButtonColor: 'green',
                color: 'success'
            }).then((result) => {
                if (result.isConfirmed) {
                    data.set('token_candidature', token_candidature)
                    save_candidature_etudiant(data)

                } else {
                    setLoadingSave(false)
                }
            })

        } else {
            setLoadingSave(false)
            Swal.fire({
                title: "Erreur Merci de ressayer au plus tard",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
        }


    }
    let history=useHistory();
    const redirectChoixSujets = () => {
        history.push("/candidature/sujets")
    };
    const getCondidatureValueDoctorat = (token_candidature) => {

        let data = new FormData();
        setLoadingSave(true)
        if (selectCv){
            if (token_candidature )  {
                Swal.fire({
                    title: "Merci de confirmer votre inscription  ",
                    icon: 'success',
                    showCancelButton: true,
                    cancelButtonText: t('retour'),
                    confirmButtonText: t('confirmer'),
                    confirmButtonColor: 'green',
                    color: 'success'
                }).then((result) => {
                    if (result.isConfirmed) {
                        data.set('token_candidature', token_candidature)
                        data.set('file_cv', selectCv)

                        save_candidature_etudiant(data)

                    } else {
                        setLoadingSave(false)
                    }
                })

            } else {
                setLoadingSave(false)
                Swal.fire({
                    title: "Erreur Merci de ressayer au plus tard",
                    icon: 'error',
                    confirmButtonText: 'Confirmer',
                    confirmButtonColor: 'red',
                    color: 'red'
                })
            }
        }else{
            setLoadingSave(false)
            Swal.fire({
                title: t('error_importe_cv'),
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
        }



    }

    const remove_registration = (token) => {

        let removedata = new FormData();
        removedata.set('token_candidat', token)
        Swal.fire({
            title: "Etes-vous sûr de vouloir supprimer cette candidature ?",
            showCancelButton: true,
            confirmButtonText: 'Supprimer',

        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/remove_candidat`, removedata)

                    .then(res => {

                        if (res.data.status === 200) {


                            Swal.fire(t('inscription_supprimer'), '', 'success')
                            get_candidatures_etudiant();

                        } else if (res.data.status === -1) {

                            Swal.fire({

                                text: res.data.message,
                                icon: 'error',
                                confirmButtonText: t('fermer'),
                                confirmButtonColor: 'red',
                                color: 'red'
                            })

                        }


                    })
            }
        })


    }
    const get_types_candidatures = () => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/get_types_candidatures`)

            .then(async res => {
                if (res.data.status === 200 && res.data.params) {
                    if (res.data.params) {
                        setTimeout(
                            () => {
                                setTypeCondidatures(res.data.params)
                                setloadingContent(false)

                            }, 200)
                    } else {

                        Swal.fire({
                            title: "Erreur Merci de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                    }

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merci de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }


            })
    }
    const getCondidature = (id_cond, annee_apres_bac_auth) => {
        setCandidatures(null)
        setLaboSujets(null)
        setTypeCondidatureId(id_cond)
        if (annee_apres_bac_auth >= 5) {
            setDoctorat(true)
        } else {
            setDoctorat(false)
        }
        setloadingContent(true)
        setAddIps(true)
        get_etablissement_by_type_candidature();

    }
    const handlchangebacfile = (event)=>{
        let file = event.target.files[0];
        let fileName = document.querySelector('#baccalaureat').value;
        let  extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension==="pdf"){
            setSelectCv(file)
        }else{
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setSelectCv("")

        }


    }

    const hanldChange = (event) => {
        setCandidatures(null)
        setLaboSujets(null)
        setloadingContent(true)
        setAddIps(false)
        setAddCondidature(true)
        get_types_candidatures();

    }
    const TelechargerPdf = (token) => {
        if (candidature_token && token) {
            const linkSource = `${BASE_URL}/candidature/${Version}/candidature/get_recu_preinscription?access_token=${candidature_token}&token_candidad=${token}`;
            window.open(linkSource)
        }
    }
    const retourAjouterCondidature = (event) => {

        setloadingContent(true)
        setAddIps(false)
        setAddCondidature(true)
        get_types_candidatures();

    }
    return (
        !loadingContent ?
            <>
                {(!addCondidature && !addIps) && (
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12}>
                            <Alert severity="warning" ><Typography variant={"h5"}>{t('info_finaliser_cand')}</Typography></Alert>
                        </Grid>

                        {hasCondidat &&
                             Object.values(etudiantCondidatures).map((etu, i) => {
                                    return (
                                        <Grid item xs={12} sm={12} key={i}>
                                            <Alert variant="filled" sx={{fontSize: 18, bgcolor: "#007fa4",textTransform:"uppercase"}}
                                                   action={(!isMobile) &&
                                                       (
                                                           <ButtonGroup variant="contained"
                                                                        aria-label="outlined  button group">
                                                               <Button variant="contained" color={"error"}
                                                                       onClick={() => remove_registration(etu.token_candidat)}
                                                                       size={"small"} startIcon={<DeleteIcon/>}>
                                                               </Button>
                                                           </ButtonGroup>
                                                       )
                                                   }
                                            >
                                                <AlertTitle
                                                    sx={{fontSize: 18}}><strong>{etu.type}</strong></AlertTitle>
                                                <strong> {etu.slug_ldap} </strong>—

                                                <strong> {etu.libelle_filiere } <br/></strong>

                                                {(isMobile) &&
                                                    (<ButtonGroup style={{marginTop: 6}} variant="contained"
                                                                  aria-label="outlined  button group">
                                                            <Button variant="contained" color={"error"}
                                                                    onClick={() => remove_registration(etu.token_candidat)}
                                                                    size={"small"} startIcon={<DeleteIcon/>}>
                                                            </Button>
                                                        </ButtonGroup>
                                                    )}
                                            </Alert>

                                        </Grid>
                                    )
                                }

                            )

                        }
                        {!hasCondidat && ( <Grid item xs={12} sm={12} textAlign={"center"}>
                            <Button

                                variant="contained"
                                type="submit"
                                color={"success"}
                                sx={{mt: 5, mb: 2, textAlign: "center"}}
                                startIcon={<AddIcon/>}
                                size={"large"}
                                onClick={retourAjouterCondidature}
                            >
                                {t('ajouter_candidature')}
                            </Button>
                        </Grid>)
                        }
                        {hasCondidat && (
                            <Grid item xs={12}>
                            <Button

                                variant="contained"
                                type="submit"

                                sx={{mt: 3, ml: 1, textAlign: "center"}}
                                endIcon={<SendIcon/>}

                                onClick={redirectChoixSujets}

                            >
                                {t('aller_choix_sujets')}
                            </Button>
                        </Grid>
                        )}


                        <Grid item xs={12} sm={12} textAlign={"center"}>
                            <Button variant={"contained"} color={"warning"} onClick={props.EtapePrec} sx={{mt: 3}}
                                    size="medium" startIcon={<ArrowBackIcon/>}>
                                {t('retour_etape')}
                            </Button>
                        </Grid>
                    </Grid>
                )}

                {(addCondidature && !addIps) && (
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"

                    >
                        <nav aria-label="secondary mailbox folders" >
                            <List sx={{mt: 6}}>
                                {typeCondidatures && (Object.values(typeCondidatures).map((cond, index) => {
                                        return (
                                            <ListItem disablePadding className={"item-condidature"} key={index}>
                                                <ListItemButton onClick={() => {
                                                    getCondidature(cond.type_candidatue_id, cond.annee_apres_bac_auth)
                                                }}>
                                                    <ListItemIcon>
                                                        <SchoolIcon style={{color: "white"}} fontSize="large"/>
                                                    </ListItemIcon>
                                                    <ListItemText style={{fontSize: 20}} primary={cond.type}/>
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    }
                                ))

                                }
                            </List>
                        </nav>
                        <Grid item xs={12} sm={12} textAlign={"center"}>
                            <Button variant={"contained"} color={"warning"} onClick={() => {
                                setAddCondidature(false)
                            }} sx={{mt: 3}}
                                    size="medium" startIcon={<ArrowBackIcon/>}>
                                {t('retour')}
                            </Button>
                        </Grid>

                    </Grid>
                )
                }
                {addIps && (
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        marginTop={3}
                        dir={i18n.language === "ar" ? "rtl" : "ltr "}
                    >
                        <Grid item xs={12} sm={12} marginBottom={3} textAlign={"center"} dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                            <Button
                                variant="contained"
                                type="submit"
                                color={"warning"}
                                sx={{mb: 1, textAlign: "center"}}
                                startIcon={<ArrowBackIcon/>}
                                size={"large"}
                                onClick={hanldChange}
                            >
                                {t('retour_etape')}
                            </Button>
                        </Grid>
                        { doctorat &&(
                            <>   <Grid item xs={12} sm={3}>
                                <Typography fontSize={20}>
                                    {t("cv_fichie")}
                                </Typography>
                            </Grid>
                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        onChange={handlchangebacfile}
                                        id="baccalaureat"
                                        name="baccalaureat"
                                        fullWidth
                                        autoComplete="given-name"
                                        variant="filled"
                                        type={"file"}
                                        inputProps={{accept:".pdf"}}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>

                                </Grid>
                            </>  )}
                        <Grid item xs={12} sm={4} style={{fontSize:20,marginTop:5}}>Merci de sélectionner l'établissement.</Grid>
                        <Grid item xs={12} sm={6} >
                            <FormControl fullWidth>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id={"etablissement"}
                                    dir={"ltr"}

                                    onChange={handlchangeEtablissement}

                                >
                                    <option></option>
                                    {etablissements &&
                                        (

                                            Object.values(etablissements).map((e, index) => {
                                                    return (

                                                        <MenuItem key={index}
                                                                  value={e.etablissement_id}>{e.libelle_etablissement_fr}</MenuItem>
                                                    )
                                                }
                                            )
                                        )
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} ></Grid>


                    </Grid>

                )

                }
                {
                    (showCondidature && addIps && !doctorat) && (
                        <Grid
                            container
                            spacing={2}
                            item
                            marginTop={1}
                        >

                            {(candidatures && hasCandidatures)&&
                                candidatures.length>0 ? (Object.values(candidatures).map((cond, index) => {

                                    return (
                                        <Grid item xs={12} sm={6} key={index}>
                                            <ListItem disablePadding className={"item-condidature-choix"}>
                                                <ListItemButton onClick={() => {
                                                    getCondidatureValue(cond.token_candidature)
                                                }} disabled={loadingSave}>
                                                    <ListItemIcon>
                                                        <SchoolIcon style={{color: "white"}} fontSize="large"/>
                                                    </ListItemIcon>
                                                    <ListItemText style={{fontSize: 20}} primary={cond.candidature_name }/>
                                                    { cond.temps_amenage===true && < ListItemText style={{fontSize: 20,color: "#f7941f"}}
                                                                   primary={" ( temps aménagé ) "}/> }
                                                </ListItemButton>
                                            </ListItem>
                                        </Grid>
                                    )
                                })
                            ):(
                           aucunCandidature &&      <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12} sm={12} style={{
                                        marginTop: 14,
                                        background: "#FDEDED",
                                        textAlign: "center",
                                        padding: 13,
                                        marginBottom: 14
                                    }}>
                                        <ErrorOutlineIcon sx={{
                                            color: 'red', fontSize: 50
                                        }}/>
                                        <Typography variant={"h5"}> aucune candidature
                                            <strong> n'est disponible actuellement!</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )

                            }


                        </Grid>

                    )
                }
                {
                    (showCondidature && addIps && doctorat) && (
                        <Grid
                            container
                            spacing={2}
                            item
                            marginTop={1}
                        >

                            {(candidatures && hasCandidatures) ? (
                                (Object.values(candidatures).map((cond, index) => {

                                    return (
                                        <Grid item xs={12} sm={6} key={index}>
                                            <ListItem disablePadding className={"item-condidature-choix"}>
                                                <ListItemButton onClick={() => {
                                                    getCondidatureValueDoctorat(cond.token_candidature)
                                                }} disabled={loadingSave}>
                                                    <ListItemIcon>
                                                        <SchoolIcon style={{color: "white"}} fontSize="large"/>
                                                    </ListItemIcon>
                                                    <ListItemText style={{fontSize: 20}} primary={cond.candidature_name}/>
                                                    { cond.temps_amenage===true && < ListItemText style={{fontSize: 20,color: "#f7941f"}}
                                                                                            primary={" ( temps aménagé ) "}/> }
                                                </ListItemButton>
                                            </ListItem>
                                        </Grid>
                                    )
                                })
                            ) ): (
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12} sm={12} style={{
                                        marginTop: 14,
                                        background: "#FDEDED",
                                        textAlign: "center",
                                        padding: 13,
                                        marginBottom: 14
                                    }}>
                                        <ErrorOutlineIcon sx={{
                                            color: 'red', fontSize: 50
                                        }}/>
                                        <Typography variant={"h5"}> aucune candidature
                                            <strong> n'est disponible !</strong>
                                        </Typography>

                                    </Grid>
                                </Grid>
                            )

                            }





                        </Grid>

                    )
                }


            </>
            :
            <Box sx={{display: 'flex'}}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"

                >
                    <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Box>

    );
}
