import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Flag from 'react-world-flags'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {BASE_URL, baseAxios, Version} from "../../../repository/baseAxios"
import React, {useState} from "react";
import {Copyright, useUserDispatch} from "../../../context/UserContext";
import {useTranslation} from 'react-i18next';
import i18n from "../../../multilingue/i18n";
import Swal from "sweetalert2";
import {Alert, IconButton, OutlinedInput, Stack} from "@mui/material";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import AppMobileStore from "../../../components/Widget/AppMobileStore/AppMobileStore";
import {isMobile} from "react-device-detect";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import SendIcon from "@mui/icons-material/Send";
import {Close} from "@material-ui/icons";
import HomeIcon from "@mui/icons-material/Home";
const theme = createTheme();

export default function PrienscriptionLogin() {
    var userDispatch = useUserDispatch();
    const [emailAcademique, setEmailAcademique] = useState("");
    const [emailAcademiqueError, setEmailAcademiqueError] = useState(false);
    const [carteSejourCin, setCarteSejourCin] = useState(false);
    const [carteSejourCinError, setCarteSejourCinError] = useState(false);
    const [passwordVal, setPasswordVal] = useState("")
    const [codeMassarp, setCodeMassarp] = useState("")
    const [ddnp, setDdnp] = useState("")
    const [ddnErrorp, setDdnErrorp] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [codeMassarErrorp, setCodeMassarErrorp] = useState(false)

    const [DataErreur, setDataErreur] = useState(false)
    const [MsgErreur, setMsgErreur] = useState('Erreur !')
    const [DataErreurp, setDataErreurp] = useState(false)
    const [MsgErreurp, setMsgErreurp] = useState('Erreur !')
    const [loadingForgetPassord, setLoadingForgetPassord] = useState(false);
    const [remember, setRemember] = useState(false);
    const [loading, setloading] = useState(false);
    const [anneeBac,setAnneBac]=useState("");
    const [anneeBacError,setAnneBacError]=useState(false);
var history =useHistory();
    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });


    const {t} = useTranslation();
    const prielogin = (params) => {
        baseAxios.post(`${BASE_URL}/espace-etudiant/${Version}/login`, params)

            .then(res => {

                if (res.data.status === 200 && res.data.params.access_token && res.data.params.compte.massar) {


                    localStorage.setItem('se_token', res.data.params.access_token);
                    localStorage.setItem('code_massar', res.data.params.compte.massar);



                    localStorage.setItem('date_naissance',res.data.params.compte.date_naissance)
                    localStorage.setItem('redirectService',"yes")
                    localStorage.setItem('preinscriptionService',"non")

                    userDispatch({type: "LOGIN_SUCCESS"});

                   // window.location.href="/service/reinscription"
                    window.location.reload();

                    setloading(false)
                } else if (res.data.status === -1) {
                    setDataErreur(true)
                    setMsgErreur(t('error_email_academique'))
                    setloading(false);

                    return false;
                }else if (res.data.status === -2) {

                    setOpen(false)

                    return false;
                }
                else{
                    Swal.fire({
                        title: "Erreur !",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor:'red',
                        color : 'red'
                    } )
                    setloading(false);

                }




            })
    }
    const reset_password_and_get_email_academic = (params) => {
        baseAxios.post(`${BASE_URL}/espace-etudiant/${Version}/reset_password_and_get_email_academic`, params)
            .then(res => {
                if (res.data.status === 200 && res.data.params.email && res.data.params.password ) {
        setOpen(false)
                    Swal.fire({
                        icon: 'success',
                        title: ' ',
                        html : `<h2>Email académique:<span style ="color:red;">${res.data.params.email}</span> </h2><h3>Mot de passe:<span style ="color:red;"> ${res.data.params.password} </span></h3>`,
                        showConfirmButton: true,
                    })

                } else if (res.data.status === -1) {
                    setDataErreurp(true)
                    setMsgErreurp(res.data.message)
                    setloading(false)
                    setLoadingForgetPassord(false)
                    return false;
                } else {

                    setloading(false)

                }
                setloading(false)

            })
    }


    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handlformsubmit = () => {
        if (codeMassarp === '') {
            setCodeMassarErrorp(true)
            return false

        }
        if (carteSejourCin === '') {
            setCarteSejourCinError(true);
            return false;
        }
        if (ddnp === '') {
            setDdnErrorp(true)
            return false
        }
        if (anneeBac === '') {
            setAnneBacError(true)
            return false
        }
        setLoadingForgetPassord(true)
        let  checkData=new FormData();
        checkData.set('code_massar',codeMassarp)
        checkData.set('carte_national',carteSejourCin)
        checkData.set('date_naissance',ddnp)
        checkData.set('annee_bac',anneeBac)

        reset_password_and_get_email_academic(checkData);
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const handleForm = () => {
        setDataErreur(false)
        const data = new FormData();
        if (remember) {
            data.set('remember_me', 'yes')

        } else {
            data.set('remember_me', 'no')

        }
        if (emailAcademique===""){
            setEmailAcademiqueError(true);
            return false;
        }



        data.set('email', emailAcademique)
        data.set('password', passwordVal)
        setloading(true)

        prielogin(data);


    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Stack spacing={2} direction="row">
                    <Button variant={"contained"} color={"warning"} onClick={()=>{history.push('/')}} sx={{ textAlign: "left",mt:5}} size="medium" >
                        <HomeIcon />
                    </Button>
                </Stack>
                <Box component={"div"} sx={{
                    marginTop: 4,
                    justifyContent: "center",
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Stack spacing={2} direction="row">
                        <Button variant="contained" onClick={() => changeLanguage('en')}><Flag code="GB_NIR"
                                                                                               height="16"/></Button>
                        <Button variant="contained" onClick={() => changeLanguage('fr')}><Flag code="GF" height="16"/>
                        </Button>
                        <Button variant="contained" onClick={() => changeLanguage('ar')}><Flag code="MA" height="16"/>
                        </Button>
                    </Stack>

                </Box>

                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {DataErreur ? <Alert variant="filled" severity="error">
                        {MsgErreur}
                    </Alert> : ("")

                    }
                    <Avatar sx={{m: 1, bgcolor: 'success.main', fontSize: 'large'}}>
                        <PersonIcon/>

                    </Avatar>

                    <Typography component="h1" fontWeight={"bold"} variant="h5" color="primary">
                        {t('connexion')}
                    </Typography>
                    <Box component="div" noValidate sx={{mt: 1}}>
                        <TextField
                            required
                            style={{marginTop:10}}

                            value={emailAcademique}
                            onChange={(e) => {
                                setEmailAcademique(e.target.value)
                            }}
                            error={emailAcademiqueError}
                            helperText={emailAcademiqueError ? t('champ_obligatoire') : ' '}

                            fullWidth
                            id="email_academique"
                            label={t('email_academique')}
                            autoComplete="off"
                            autoFocus

                        />
                        <FormControl variant="outlined" fullWidth sx={{mt: 2 ,mb:3}}>
                            <InputLabel htmlFor="outlined-adornment-password">{t('mot_de_passe')}</InputLabel>
                            <OutlinedInput
                                required
                                id="password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={passwordVal}
                                onChange={(e) => {
                                    setPasswordVal(e.target.value)
                                }}
                                autoComplete={"current-password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}

                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t('mot_de_passe')}


                            />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox color="primary"
                                               checked={remember}
                                               onChange={(e) => {
                                                   setRemember(e.target.checked)
                                               }}/>}
                            label={t('remember_me')}
                        />
                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={12}  sx={{mt: 2}}>
                                <Button
                                    type="submit"
                                    color="success"
                                    fullWidth
                                    variant="contained"
                                    disabled={loading}
                                    onClick={() => handleForm()}
                                >
                                    {t('connexion')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} dir={i18n.language === "ar" ? "rtl" : "ltr "}>

                                <Button
                                    fullWidth
                                    onClick={() => {
                                        setLoadingForgetPassord(false)
                                        setOpen(true)
                                    }
                                    }
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                >
                                    {t("forget_password_personnel")}


                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Copyright sx={{mt: 4, mb: 4}}/>
                </Box>
                { !isMobile && (<AppMobileStore/> )}
            </Container>
            <Box component="form"  spacing={3} >
                <Grid container spacing={3}>

                    <Dialog
                        style={{padding:10}}
                        open={open}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >


                        <DialogContent style={{width : isMobile ? "" : "600px" }}>
                            {DataErreurp ? <Alert variant="filled" severity="error">
                                {MsgErreurp}
                            </Alert> : ("")

                            }
                            <Grid item xs={12} sm={12} textAlign={"center"}>

                                <TextField
                                    required
                                    style={{marginTop:10}}

                                    value={codeMassarp}
                                    onChange={(e) => {
                                        setCodeMassarp(e.target.value)
                                    }}
                                    error={codeMassarErrorp}
                                    helperText={codeMassarErrorp ? t('champ_obligatoire') : ' '}

                                    fullWidth
                                    id="code_massar"
                                    label={t('code_massar_cne')}
                                    name="code_massar"
                                    autoComplete="off"
                                    autoFocus

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={"center"}>
                            <TextField
                                required
                                id="carte_sejour"
                                name="carte_sejour"
                                label={t('carte_de_sejour_cin')}
                                value={carteSejourCin || ""}
                                onChange={(e) => {
                                    setCarteSejourCin(e.target.value)
                                }}
                                error={carteSejourCinError}
                                helperText={carteSejourCinError ? t('champ_obligatoire') : ' '}
                                fullWidth
                                autoComplete="off"
                                variant="outlined"
                            />
                </Grid>
                            <Grid item xs={12} sm={12} textAlign={"center"}>

                                <TextField
                                    required
                                    style={{marginTop:10}}
                                    id="date_naissance"
                                    value={ddnp}
                                    onChange={(e) => {
                                        setDdnp(e.target.value)
                                    }}
                                    label={t('date_naissance')}
                                    fullWidth
                                    type={"date"}

                                    InputLabelProps={{ shrink: true }}
                                    autoComplete="off"
                                    variant="outlined"
                                    helperText={ddnErrorp ? t('champ_obligatoire') : ' '}
                                    error={ddnErrorp}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    dir={'ltr'}
                                    id="anneeBac"
                                    label={t('annee_bac')}
                                    value={anneeBac || ""}
                                    onChange={(e) => {
                                        setAnneBac(e.target.value)
                                    }}
                                    helperText={anneeBacError ? t('champ_obligatoire') : ' '}
                                    error={anneeBacError}
                                    fullWidth
                                    autoComplete={"off"}
                                    variant="outlined"

                                />
                            </Grid>
                            <Grid item xs={6} sm={6} style={{display:"inline"}}>

                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                                    endIcon={<Close />}
                                    onClick={()=>{ setOpen(false)}}
                                    color={"warning"}

                                >
                                    {t('fermer')}
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                                    endIcon={<SendIcon/>}
                                    onClick={handlformsubmit}
                                    disabled={loadingForgetPassord}
                                >
                                    {t('envoyer')}
                                </Button>
                            </Grid>

                        </DialogContent>
                    </Dialog>
                </Grid>

            </Box>




        </ThemeProvider>
    );
}