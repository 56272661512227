import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import { isMobile} from 'react-device-detect';
import {Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import i18n from "../../multilingue/i18n";
import Avatar from "@mui/material/Avatar";
import LogoTitle from "../../images/logo_title.png";
import {useHistory} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import  ReiTelechargerRecu from "../../pages/serviceEnLigne/ReiTelechargerRecu"
import ReinChoixFilieres from "../serviceEnLigne/stepsReinscription/ReinChoixFilieres";
import ReinDonneeCivilOne from "../serviceEnLigne/stepsReinscription/ReinDonneeCivilOne";
import ReinDonneeCivilTwo from "../serviceEnLigne/stepsReinscription/ReinDonneeCivilTwo";
import ReinCouvMedicale from "../serviceEnLigne/stepsReinscription/ReinCouvMedicale";
import ReinValidatePrienscription from "../serviceEnLigne/stepsReinscription/ReinValidatePrienscription";
import ReinBaccalaureat from "../serviceEnLigne/stepsReinscription/ReinBaccalaureat";
const theme = createTheme();
export default function Prienscription() {
    const [activeStep, setActiveStep] = React.useState(0);
    const  history=useHistory();
    const {t} = useTranslation();
    const steps = [
        t("choix_filieres"),

        t("donnees_civile_1"),
        t("adresse"),

        t("couverture_medicale"),

        t("information_du_baccalaureat"),
        t("validation")
    ];
    const handleNext = () => {
        setActiveStep(activeStep + 1);

    };
    const handleBack = () => {

        setActiveStep(activeStep - 1);

    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <ReinChoixFilieres EtapeSuivant={handleNext} />;

            case 1:
                return <ReinDonneeCivilOne EtapeSuivant={handleNext} />;

            case 2:
                return <ReinDonneeCivilTwo EtapeSuivant={handleNext} />;

            case 3:
                return <ReinCouvMedicale EtapeSuivant={handleNext} />;
            case 4:
                return <ReinBaccalaureat EtapeSuivant={handleNext} />
            case 5:
                return <ReinValidatePrienscription EtapeSuivant={handleNext} />
            default:
                throw new Error("Unknown step");
        }

    }

    return (

        <ThemeProvider theme={theme}>
            <CssBaseline/>

            {isMobile && (  <Typography dir={i18n.language === "ar" ? "rtl" : "ltr "} variant={"h6"}  fontFamily={"Open Sans"} fontWeight={"bold"} color={"primary"} >
                <i className=" fa fa-bookmark"></i>   {t("centre_de_preinscription_et_de_reinscription")}
            </Typography>)}
            <Container  style={{maxWidth:"1000px"}} sx={{mb: 4,p:0}}>

                <Paper
                    variant="outlined"
                    sx={{my: {xs: 1, md: 1}, p: { md: 3}}}
                >
                    <Grid container spacing={3} mt={1} dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                        {!isMobile ?  <>  <Grid item align={i18n.language === "ar" ? "right" : "left"} xs={8} sm={8}>
                                <Typography variant={"h5"}  fontFamily={"Open Sans"} fontWeight={"bold"} color={"primary"} >
                                    <i className=" fa fa-bookmark"></i>   {t("centre_de_preinscription_et_de_reinscription")}
                                </Typography>

                            </Grid>
                                <Grid item align={i18n.language === "ar" ? "left" : "right"} xs={4} sm={4}>
                                    <img
                                        style={{width: 90, height: 60}}

                                        alt="Etablissement logo"
                                        src={LogoTitle}

                                    />
                                </Grid> </> :
                            <Grid container spacing={0} direction="column" alignItems="center" justify="center" >
                                <Grid item align={"centre"} alignContent={"center"} xs={12} sm={12}>
                                    <Avatar
                                        sx={{width: 200, height: 60}}
                                        alt="Etablissement logo"
                                        src={LogoTitle}
                                        variant="square"

                                    />
                                </Grid>
                            </Grid>}
                    </Grid>
                    <Divider sx={{mt: 2}} variant="middle" color={"orange"} />
                    {isMobile ? (  <Stepper activeStep={activeStep} orientation="vertical" >
                        {steps.map((step, index) => (
                            <Step key={step}>
                                <StepLabel

                                >
                                    {step}
                                </StepLabel>
                                <StepContent>
                                    {activeStep === steps.length ? (
                                        <React.Fragment>


                                            <Button onClick={()=>history.push("/app")} sx={{mt: 3, ml: 1}}>
                                                Acceuil
                                            </Button>

                                            <Button variant={"contained"} color={"warning"} onClick={handleBack} sx={{mt: 3, ml: 1}} size="medium" startIcon={<ArrowBackIcon />}>
                                                {t('retour')}
                                            </Button>

                                        </React.Fragment>
                                    ) : (

                                        <React.Fragment>

                                            {getStepContent(activeStep)}
                                            <Box sx={{display: "flex", justifyContent: "center"}} >
                                                {activeStep !== 0 && (
                                                    <Button variant={"contained"} color={"warning"} onClick={handleBack} sx={{mt: 3, ml: 1}} size="medium" startIcon={<ArrowBackIcon />}>
                                                        {t('retour')}
                                                    </Button>
                                                )}

                                            </Box>
                                        </React.Fragment>  )}

                                </StepContent>
                            </Step>

                        ))}
                    </Stepper>) : (<Stepper activeStep={activeStep} sx={{pt: 3, pb: 3}}
                                            dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    )
                    }
                    {!isMobile && (<Divider  variant="middle" sx={{mb: 3 }} color={"orange"} />)}
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <ReiTelechargerRecu handlback={handleBack} />
                        ) : (
                            <React.Fragment>

                                {!isMobile ?   getStepContent(activeStep) :  ("")}
                                {!isMobile ?    <Box sx={{display: "flex", justifyContent: "center"}}>
                                    {activeStep !== 0 && (
                                        <Button variant={"contained"} color={"warning"} onClick={handleBack} sx={{mt: 3, ml: 1}} size="medium" startIcon={<ArrowBackIcon />}>
                                            {t('retour')}
                                        </Button>
                                    )}


                                </Box> :  ("")}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}
