import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SendIcon from '@mui/icons-material/Send';
import Box from "@mui/material/Box";

import {useTranslation} from "react-i18next";
import i18n from "../../../multilingue/i18n";
import {useEffect, useState} from "react";
import {BASE_URL, baseAxiosService, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import {Autocomplete, CircularProgress} from "@mui/material";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";
var VillesJs = [];
var MentionsB = [];
export default function ReinBaccalaureat(props) {
    const [loading, setloading] = useState(false);

    const [VilleValue,setVilleValue]=useState("");
    const [mentionsBacValue,setMentionsBacValue]=useState("");
    const [lycee,setLycee]=useState("");
    const [lyceeError,setLyceeError]=useState(false);
    const [academie,setAcademie]=useState("");
    const [province,setProvince]=useState("");
    const [anneeBac,setAnneBac]=useState("");
    const [serieBac,setSerieBac]=useState("");

    const [ipToken, setipToken] = React.useState("");
    const [loadingContent, setloadingContent] = useState(true);
    const is_french = (value) => {
        var letters =  /^([a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+\s)*[a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/;

        if (letters.test(value)) {
            return true;
        } else {
            return false;
        }

    }


    const {t} = useTranslation();

    const create_account_step_4_bac = (params) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/create_account_step_4_bac`, params)

            .then(async res => {

                if (res.data.status === 1) {
                    await Swal.fire({
                        title: t('step_5'),
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.EtapeSuivant();
                        }
                    })


                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setloading(false)

                }else{
                    setloading(false)
                }
                setloading(false)


            })
    }

    const handlformsubmit = (event) => {
        event.preventDefault();
        setLyceeError(false)
        if (lycee ==="" || !is_french(lycee)) {
            setLyceeError(true)
            return false;
        };
        const data = new FormData();
        if (VilleValue.id) {
            data.set('ville_bac',VilleValue.id)
        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ Ville baccalauréat",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }
        if (mentionsBacValue.mention_id) {
            data.set('mention_bac',mentionsBacValue.mention_id)
        } else {
            Swal.fire({
                title: "Erreur !",
                text: "Erreur dans le champ mention baccalauréat",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false
        }
        data.set('lycee_bac',lycee)
        data.set('ip_token',ipToken)

        setloading(true);
        create_account_step_4_bac(data);

    }
    useEffect(()=>{
        const list_villes = () => {
            baseAxiosService.get(`${BASE_URL}/inscription/${Version}/inscription/list_villes`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const allvilles = res.data.params;
                        VillesJs=allvilles;
                        VillesJs = allvilles.map(function (item) {
                            return {
                                id : item.id,
                                ville_fr : item.ville_fr,
                            }
                        });




                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merci de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }



                })
        }
        const mentions = () => {
            baseAxiosService.get(`${BASE_URL}/inscription/${Version}/inscription/mentions`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const mentions = res.data.params;
                        MentionsB=mentions;

                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text :res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }


                })
        }
        const information_step_one = (params) => {
            baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/information_by_step`, params)
                .then(async res => {
                    if (res.data.status === 200 && res.data.params.inscription  ) {
                        setTimeout(
                            () => {
                     let inscription=   res.data.params.inscription;


                        let ville_id_bac=parseInt( inscription.ville_obtention_baccalaureat)

                         if(ville_id_bac){
                             let ville = VillesJs.find(item => item.id ===ville_id_bac) || null;


                                     setVilleValue(ville);

                         }
                         let mention_baccalaureat=parseInt(inscription.mention_baccalaureat)

                         if(mention_baccalaureat){
                             let mention = MentionsB.find(item => item.mention_id ===mention_baccalaureat) || null;

                                     setMentionsBacValue(mention);


                         }
                         if(res.data.params.bac_province ){
                             let bac_province=res.data.params.bac_province;
                             setProvince(bac_province.libelle_province_fr)

                         }
                         if(res.data.params.bac_academie){
                             let bac_academie=res.data.params.bac_academie;
                                if(bac_academie.libelle_academie_fr){
                                    setAcademie(bac_academie.libelle_academie_fr)
                                }
                         }
                         if(res.data.params.bac_series_bac){
                             let bac_series_bac=res.data.params.bac_series_bac;

                             setSerieBac(bac_series_bac.libelle_baccalaureat_fr)
                         }
                        setAnneBac(inscription.annee_baccalaureat)
                         setLycee(inscription.lycee_obtention_baccalaureat)
                        setipToken(inscription.ip_token)
                        setloadingContent(false)
                            },1000
                        )
                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text :res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }


                })
        }

        let isMounted = true;

        if(isMounted ){
            list_villes();
            mentions();
                let datastep =new FormData();
                datastep.set('step','bac')
                information_step_one(datastep)


        }

        return () => {
            isMounted = false;
        };


    },[])
    return (
        <React.Fragment>
            {!loadingContent ?
            <Box component="form" container spacing={3} dir={i18n.language === "ar" ? "rtl" : "ltr "} onSubmit={  handlformsubmit}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >

                    <Grid item xs={12} sm={12} style={{marginTop: 12, marginBottom: 22}}>
                        <TextField
                            disabled
                            dir={'ltr'}
                            id="academie"
                            name="academie"
                            value={serieBac || ""}
                            fullWidth
                            label={t('seriebac')}
                            autoComplete={"off"}
                            variant="filled"

                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            disabled
                            dir={'ltr'}
                            id="academie"
                            name="academie"
                            value={academie || ""}
                            fullWidth
                            label={t('academie')}
                            autoComplete={"off"}
                            variant="filled"

                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            disabled
                            dir={'ltr'}
                            id="province"
                            name="province"
                            label={t('province')}

                            value={province || ""}
                            fullWidth
                            autoComplete={"off"}
                            variant="filled"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            disabled
                            dir={'ltr'}
                            id="anneeBac"
                            name="anneeBac"
                            label={t('annee_bac')}
                            value={anneeBac || ""}
                            fullWidth
                            autoComplete={"off"}
                            variant="filled"

                        />
                    </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography>
                                        {t('ville_obtention_du_baccalaureat')}
                                    </Typography>
                                </Grid>
                    <Grid item xs={12} sm={5}>
                        <Autocomplete
                            id="country-select-demo"
                            options={VillesJs}
                            autoHighlight
                            getOptionLabel={(option) => option.ville_fr || ""}


                            onChange={(event, newValue) => {
                                setVilleValue(newValue)
                            }}
                            value={VilleValue}

                            renderOption={(props, option) => (
                                <Box component="li"  {...props} key={option.id}>
                                    {option.ville_fr}
                                </Box>

                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}

                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}

                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill

                                    }}
                                />

                            )}
                        />
                    </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Typography>
                                        {t('mention_du_baccalaureat')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Autocomplete
                                        id="country-select-demo"
                                        dir={"ltr"}
                                        options={MentionsB}
                                        autoHighlight
                                        getOptionLabel={(option) => option.mention_name || ""}
                                        onChange={(event, newValue) => {
                                            setMentionsBacValue(newValue)
                                        }}
                                        value={mentionsBacValue}
                                        renderOption={(props, option) => (
                                            <Box component="li"  {...props} key={option.mention_id}>
                                                {option.mention_name}
                                            </Box>

                                        )}
                                        isOptionEqualToValue={(option, value) => option.mention_id === value.mention_id}

                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,

                                                }}
                                                autoComplete={"off"}

                                            />

                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Typography>
                                        {t('lycee_obtention_du_baccalaureat')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        required
                                        dir={'ltr'}
                                        id="lycee_bac"
                                        name="lycee_bac"
                                        value={lycee || ""}
                                        onChange={(e)=>{setLycee(e.target.value)}}
                                        error={lyceeError}
                                        helperText={lyceeError ?  t('champ_obligatoire_fr') : ' '}
                                        fullWidth
                                        autoComplete={"off"}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} >
                                    <InfoCndp/>
                                </Grid>
                        <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        >

                        <Grid item xs={3} >
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ mt: 3, ml: 1 , textAlign:"center"}}
                                endIcon={<SendIcon />}
                                dir={"ltr"}
                                disabled={loading}
                            >
                                {t('enregistrer')}
                            </Button>

                        </Grid>

                        </Grid>

                </Grid>
            </Box>
                :
                <Box sx={{display: 'flex'}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Box>
            }
        </React.Fragment>
    );
}
