import React from "react";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import i18n from "../../../multilingue/i18n";


export default function InfoCndp(props) {


   const {t}=useTranslation();
    return (<>
            <Typography  color={props.color}  style={{fontSize:10 , fontWeight:"bold",textAlign:"justify" }} dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                {t('information_cndp')}
               </Typography>
        </>

    );
}
