import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import {useTranslation} from "react-i18next";

const theme = createTheme({
    direction: "rtl" // Both here and <body dir="rtl">
});
// Create rtl cache
const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin]
});

export default function TextFeildAR(props) {
    const {t} =useTranslation();
    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <TextField required dir="rtl" onChange={props.onChangeAr} value={props.value || ''} label={props.label} name={props.name} id={props.id}
                           helperText={props.error  ? t('champ_obligatoire_ar') : ' '} error={props.error}  fullWidth
                           autoComplete="off"
                           variant="outlined"
                           disabled={props.disableFiel}
                />
            </ThemeProvider>
        </CacheProvider>
    );
}
