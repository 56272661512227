

import React from "react";
import {useEffect} from "react";
import {BASE_URL, baseAxios, Version,token} from "../../../repository/baseAxios";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import i18n from "../../../multilingue/i18n";
import {Divider} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';


export default function TelechargerRecu(props) {
    const [idInscription, setIdInscription] = React.useState("");
const  history =useHistory();
const {t}=useTranslation();


    const information_step_one = (params) => {
        baseAxios.post(`${BASE_URL}/inscription/${Version}/etudiant/information_by_step`, params)

            .then(async res => {
                if (res.data.status === 200) {
                    setIdInscription(res.data.params.etudiant.id_inscription)

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merce de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }


            })
    }

    const TelechargerPdf = () => {
            if (token && idInscription) {
                const linkSource = `${BASE_URL}/inscription/${Version}/preinscription/etudiant/get_recu?access_token=${token}&id_inscription=${idInscription}`;
               window.open(linkSource)

            }
    }
    useEffect(() => {

        let datastep =new FormData();
        datastep.set('step', 'choix_filiere')
        information_step_one(datastep);

    }, [])
    return (
        <React.Fragment>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >

                <Grid item xs={12} sm={12}>

                   <Typography dir={i18n.language === "ar" ? "rtl" : "ltr "} variant={"h5"}  fontFamily={"Open Sans"} fontWeight={"bold"} color={"primary"} >
                        <i className=" fa fa-check"></i>   {t("prienscription_bien_enregistrer")}
                    </Typography>
                    <Divider />
                    <Typography variant="subtitle1" style={{ textAlign : "center"}}>
                        <Button startIcon={<DownloadIcon />}    variant="contained" color={"success"} onClick={TelechargerPdf} sx={{mt: 3, ml: 1}}>
                             {t('telerecher_recu')}
                        </Button>
                    </Typography>
                    <Typography variant="subtitle1" style={{ textAlign : "center"}}>
                        <Button onClick={()=>history.push("/app")} sx={{mt: 3, ml: 1}}>
                            {t('retour_premier_etape')}
                        </Button>
                    </Typography>


                </Grid>

        </Grid>
        </React.Fragment>
    );
}

