import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ModalPolicy(props) {
    const [open, setOpen] = React.useState(false);



    return (<>
            <Typography style={ {fontSize:12, marginTop:14,marginBottom:14,textAlign:"justify"}}>j'ai lu et j'accepte la mention relative à <Link style={{cursor:"pointer"}}  onClick={()=>{ setOpen(true)}} >
                la  protection des données à caractère personnel .</Link>
               </Typography>

                <Dialog
                    open={open}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Protection des données à caractère personnel"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Par le biais de ce formulaire, l’ENSMR vos données
                            personnelles en vue de la gestion de la scolarité . Ce traitement a fait l’objet d’une demanade
                            d’autorisation auprès de la CNDP sous le numéro ………… Les données personnelles
                            collectées peuvent être transmises au ministère de tutelle, CNOPS,
                            CNSS. Vous pouvez vous adresser à<span style={{fontWeight:"bold"}}> inscription@enim.ac.ma</span> , pour exercer
                            vos droits d’accès, de rectification et d’opposition conformément aux
                            dispositions de la loi 09-08
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => {
                            setOpen(false)
                        }} autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>


        </>

    );
}
