import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Flag from 'react-world-flags'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {BASE_URL, baseAxios, Version} from "../../../repository/baseAxios"
import React, {useState} from "react";
import {Copyright, useUserDispatch} from "../../../context/UserContext";
import {useTranslation} from 'react-i18next';
import i18n from "../../../multilingue/i18n";
import Swal from "sweetalert2";
import {Alert, IconButton, OutlinedInput, Stack} from "@mui/material";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import HomeIcon from '@mui/icons-material/Home';

import Paper from "@mui/material/Paper";
import AppMobileStore from "../../../components/Widget/AppMobileStore/AppMobileStore";
import {isMobile} from "react-device-detect";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Close} from "@material-ui/icons";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";
import ModalPolicy from "../../../components/Widget/Cndp/ModalPolicy";
const theme = createTheme();

export default function LoginCondidat() {
    var userDispatch = useUserDispatch();
    const [emailNum, setEmailNum] = useState("");
    const [passwordVal, setPasswordVal] = useState("")
    const [emailNump, setEmailNump] = useState("")
    const [ddnp, setDdnp] = useState("")
    const [cin, setCin] = useState("");
    const [ddnErrorp, setDdnErrorp] = useState(false);
    const [cinError, setCinError] = useState(false);
    const [codeMassar, setCodeMassar] = useState("");
    const [codeMassarError, setCodeMassarError] = useState(false);


    const [open, setOpen] = React.useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirme, setPasswordConfirme] = useState('');

    const [emailNumError, setEmailNumError] = useState(false)
    const [emailNumErrorp, setEmailNumErrorp] = useState(false)
    const [DataErreur, setDataErreur] = useState(false)
    const [MsgErreur, setMsgErreur] = useState('Erreur !')
    const [DataErreurp, setDataErreurp] = useState(false)
    const [MsgErreurp, setMsgErreurp] = useState('Erreur !')

    const [remember, setRemember] = useState(false);

    const [loading, setloading] = useState(false);
    const [loadingReset, setloadingReset] = useState(false);

    const [passwordError, setPasswordError] = useState(false)
    const [passwordConfirmeError, setPasswordConfirmeError] = useState(false)
    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });


    const {t} = useTranslation();
    const history = useHistory();



    var  checkData=new FormData();
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const reset_password_candidat = (params) => {
        baseAxios.post(`${BASE_URL}/candidature/${Version}/candidature/reset_password_candidat`, params)
            .then(res => {

                if (res.data.status === 200 ) {

                    setOpen(false)
                    setDataErreurp(false)
                    setCodeMassar("")
                    setCin("")
                    setEmailNump("")
                    setDdnp("")
                    setPassword("")
                    setPasswordConfirme("")
                    Swal.fire({
                        title: "Mot de passe est bien modifié ",
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    })
                    setloadingReset(false);
                } else if (res.data.status === -1) {


                    setDataErreurp(true)
                    setMsgErreurp(res.data.message)
                    setloadingReset(false);

                    return false;
                } else{

                    setDataErreurp(true)
                    setMsgErreurp(res.data.message)
                    setloadingReset(false);

                    return false;

                }




            })
    }

    const handlformsubmit = () => {
        setDataErreurp(false)
        setMsgErreurp("")
        setloadingReset(false)
        if (codeMassar === '') {
            setCodeMassarError(true)
            return false

        }
        if (cin === '') {
            setCinError(true)
            return false
        }
        if (ddnp === '') {
            setDdnErrorp(true)
            return false
        }
        if (emailNump === '') {
            setEmailNumErrorp(true)
            return false

        }


        if (password === '') {
            setPasswordError(true);
            return false;
        }
        if (passwordConfirme === '') {
            setPasswordConfirmeError(true);
            return false;
        }
        if ( !(password===passwordConfirme)){
            setDataErreurp(true)
            setPasswordConfirmeError(true);
            setMsgErreurp('confirmation mot de passe est incorrect !')
            return  false;
        }

        setloadingReset(true)

        checkData.set('code_massar',codeMassar)
        checkData.set('cin',cin)
        checkData.set('email_tel',emailNump)
        checkData.set('date_naissance',ddnp)
        checkData.set('new_password',password)
        reset_password_candidat(checkData)
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const prielogin = (params) => {
        baseAxios.post(`${BASE_URL}/candidature/${Version}/candidature/login`, params)
            .then(res => {
                if (res.data.status === 200 && res.data.params.access_token && res.data.params.compte) {

                    localStorage.setItem('candidature_token', res.data.params.access_token);
                    localStorage.setItem('redirectCondidat',"yes")
                    if(res.data.params.compte.accept_cndp===true){
                        localStorage.setItem('cndp', "yes");

                    }else{
                        localStorage.setItem('cndp',0);
                    }



                    window.location.reload();
                   // window.location.href="/candidature/steps"



                } else if (res.data.status === -1) {
                    setDataErreur(true)
                    setMsgErreur(res.data.message)
                    setloading(false)

                    return false;
                } else {

                    setloading(false)

                }
                setloading(false)

            })


    }

    const handleForm = () => {
        setDataErreur(false)
        const data = new FormData();
        if (remember) {
            data.set('remember_me', 'yes')

        } else {
            data.set('remember_me', 'no')

        }
        if (emailNum===""){
            setEmailNumError(true);return false;
        }

        data.set('email', emailNum)
        data.set('password', passwordVal)
        setloading(true)
        prielogin(data)




    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Stack spacing={2} direction="row">
                    <Button variant={"contained"} color={"warning"} onClick={()=>{history.push('/')}} sx={{ textAlign: "left",mt:5}} size="medium" >
                        <HomeIcon />
                    </Button>
                </Stack>
                <Box component={"div"} sx={{
                    marginTop: 4,
                    justifyContent: "center",
                    display: 'flex',
                    alignItems: 'center',
                }}>

                    <Stack spacing={2} direction="row">
                        <Button variant="contained" onClick={() => changeLanguage('en')}><Flag code="GB_NIR"
                                                                                               height="16"/></Button>
                        <Button variant="contained" onClick={() => changeLanguage('fr')}><Flag code="GF" height="16"/>
                        </Button>
                        <Button variant="contained" onClick={() => changeLanguage('ar')}><Flag code="MA" height="16"/>
                        </Button>
                    </Stack>

                </Box>

                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {DataErreur ? <Alert variant="filled" severity="error">
                        {MsgErreur}
                    </Alert> : ("")

                    }
                    <Avatar sx={{m: 1, bgcolor: 'success.main', fontSize: 'large'}}>
                        <PersonIcon/>

                    </Avatar>

                    <Typography component="h1" fontWeight={"bold"} variant="h5" color="primary">
                        {t('connexion')}
                    </Typography>
                    <Box component="div" noValidate sx={{mt: 1}}>
                        <TextField
                            required
                            style={{marginTop:10}}

                            value={emailNum}
                            onChange={(e) => {
                                setEmailNum(e.target.value)
                            }}
                            error={emailNumError}
                            helperText={emailNumError ? t('champ_obligatoire') : ' '}

                            fullWidth
                            id="email_num"
                            label={t('email_num')}

                            autoComplete="off"
                            autoFocus

                        />
                        <FormControl variant="outlined" fullWidth sx={{mt: 2}}>
                            <InputLabel htmlFor="outlined-adornment-password">{t('mot_de_passe')}</InputLabel>
                            <OutlinedInput
                                required
                                id="password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={passwordVal}
                                onChange={(e) => {
                                    setPasswordVal(e.target.value)
                                }}
                                autoComplete={"current-password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}

                                            edge="end"
                                        >
                                            {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t('mot_de_passe')}


                            />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox color="primary"
                                               checked={remember}
                                               onChange={(e) => {
                                                   setRemember(e.target.checked)
                                               }}/>}
                            label={t('remember_me')}
                        />
                        <Grid container spacing={3}>
                            <Grid item sx={{marginTop: 1, marginBottom: 1}} xs={12} sm={12}
                                  dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                                <Link
                                    onClick={() => {
                                        setOpen(true)
                                        setloadingReset(false)
                                    }

                                    }

                                    style={{cursor: "pointer"}}
                                    size="medium"
                                >


                                    {t("mot_de_passe_oublie")}

                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <InfoCndp color={"black"} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    type="submit"
                                    color="success"
                                    fullWidth
                                    variant="contained"
                                    disabled={loading}
                                    onClick={handleForm}
                                >
                                    {t('connexion')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} dir={i18n.language === "ar" ? "rtl" : "ltr "}>

                                <Button
                                    fullWidth
                                    onClick={() =>
                                        history.push('/candidature/compte')
                                    }
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                >
                                    {t("cree_un_compte")}


                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Copyright sx={{mt: 4, mb: 4}}/>
                </Box>
                { !isMobile && (<AppMobileStore/> )}
            </Container>
            <Box component="form"  spacing={3} >
                <Grid container spacing={3}>
                    <Dialog
                        style={{padding:10}}
                        open={open}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >

                        {DataErreurp ? <Alert variant="filled" severity="error">
                            {MsgErreurp}
                        </Alert> : ("")

                        }
                        <DialogContent
                            style={{width : isMobile ? "" : "600px" }}
                        >
                            <Grid item xs={12} sm={12} textAlign={"center"}>

                                <TextField
                                    required
                                    style={{marginTop:10}}

                                    value={codeMassar}
                                    onChange={(e) => {
                                        setCodeMassar(e.target.value)
                                    }}
                                    error={codeMassarError}
                                    helperText={codeMassar==="" ? t('champ_obligatoire') : ' '}

                                    fullWidth
                                    id="code_massar_cne"
                                    label={t('code_massar_cne')}
                                    name="code_massar_cne"
                                    autoComplete="off"
                                    autoFocus

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={"center"}>

                                <TextField
                                    required
                                    style={{marginTop:10}}

                                    value={cin}
                                    onChange={(e) => {
                                        setCin(e.target.value)
                                    }}
                                    error={cinError}
                                    helperText={cin==="" ? t('champ_obligatoire') : ' '}

                                    fullWidth
                                    id="carte_de_sejour_cin"
                                    label={t('carte_de_sejour_cin')}
                                    name="carte_de_sejour_cin"
                                    autoComplete="off"
                                    autoFocus

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={"center"}>

                                <TextField
                                    required
                                    style={{marginTop:10}}

                                    value={emailNump}
                                    onChange={(e) => {
                                        setEmailNump(e.target.value)
                                    }}
                                    error={emailNumErrorp}
                                    helperText={emailNump==="" ? t('champ_obligatoire') : ' '}

                                    fullWidth
                                    id="email_num"
                                    label={t('email_num')}
                                    name="email_num"
                                    autoComplete="off"
                                    autoFocus

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={"center"}>

                                <TextField
                                    style={{marginTop:10}}
                                    required
                                    id="date_naissance"
                                    value={ddnp}
                                    onChange={(e) => {
                                        setDdnp(e.target.value)
                                    }}
                                    label={t('date_naissance')}
                                    fullWidth
                                    type={"date"}

                                    InputLabelProps={{ shrink: true }}
                                    autoComplete="off"
                                    variant="outlined"
                                    helperText={ddnp === "" ? t('champ_obligatoire') : ' '}
                                    error={ddnErrorp}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={"center"}>
                                <TextField
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    margin="normal"
                                    fullWidth
                                    value={password}
                                    type={"password"}
                                    id="password"
                                    helperText={password === "" ? t('champ_obligatoire') : ' '}
                                    label={t('mot_de_passe')}
                                    name="password"
                                    autoComplete="off"
                                    autoFocus
                                    error={passwordError}

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={"center"}>
                                <TextField
                                    required
                                    onChange={(e) => setPasswordConfirme(e.target.value)}
                                    margin="normal"
                                    fullWidth
                                    id="password_confirmation"
                                    helperText={passwordConfirme === "" ? t('champ_obligatoire') : ' '}
                                    value={passwordConfirme}
                                    label={t('mot_de_passe_confirmation')}
                                    name="password_confirmation"
                                    autoComplete="off"
                                    autoFocus
                                    type="password"
                                    error={passwordConfirmeError}

                                />

                            </Grid>
                            <Grid item xs={6} sm={6} style={{display:"inline"}}>

                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                                    endIcon={<Close />}
                                    onClick={()=>{ setOpen(false)}}
                                    color={"warning"}

                                >
                                    {t('fermer')}
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                                    endIcon={<SendIcon/>}
                                    disabled={loadingReset}
                                    onClick={handlformsubmit}
                                >
                                    {t('enregistrer')}
                                </Button>
                            </Grid>

                        </DialogContent>
                    </Dialog>
                </Grid>
            </Box>



        </ThemeProvider>
    );
}