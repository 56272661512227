import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import FeedIcon from '@mui/icons-material/Feed';
// styles
import useStyles from "./styles";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
} from "../../context/LayoutContext";
import {useTranslation} from "react-i18next";
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LockResetIcon from "@mui/icons-material/LockReset";
import DescriptionIcon from '@mui/icons-material/Description';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';

function SidebarServiceEnLigne({ location }) {
    var classes = useStyles();
    var theme = useTheme();
    const {t}= useTranslation();

    const structure = [
        { id: 0, label: "Reinscription  ", link: "/service/reinscription", icon: <FeedIcon /> },
        { id: 1, label: "Consultation notes ", link: "/service/consultation-notes", icon: <AssignmentIcon /> },
        { id: 2,
            label: "AMOE Etudiant",
            link: "/service/amo-etudiant",
            icon: <HealthAndSafetyIcon />},

       { id: 3, label: "Bourses", link: "/service/bourse", icon: <MonetizationOnIcon /> },
      //  { id: 4, label: "La présence", link: "/service/abscance", icon: <CastForEducationIcon /> },
      //  { id: 5, label: t('changement_de_mot_de_passe'), link: "/service/change-password", icon:  <LockResetIcon   />  },
        { id: 4, label: "Mes demandes en ligne", link: "/service/mes-demandes", icon:  <DescriptionIcon   />  },
        {  id: 5, label: "Tools", link: "/service/tools", icon:  <BubbleChartIcon   />  }
       ];





        /*     {
           id: 4,
           label: "UI Elements",
           link: "/app/ui",
           icon: <UIElementsIcon />,
           children: [
             { label: "Icons", link: "/app/ui/icons" },
             { label: "Charts", link: "/app/ui/charts" },
             { label: "Maps", link: "/app/ui/maps" },
           ],
         },
         { id: 5, type: "divider" },
         { id: 6, type: "title", label: "HELP" },
         { id: 7, label: "Library", link: "https://flatlogic.com/templates", icon: <LibraryIcon /> },
         { id: 8, label: "Support", link: "https://flatlogic.com/forum", icon: <SupportIcon /> },
         { id: 9, label: "FAQ", link: "https://flatlogic.com/forum", icon: <FAQIcon /> },
         { id: 10, type: "divider" },
         { id: 11, type: "title", label: "PROJECTS" },
         {
           id: 12,
           label: "My recent",
           link: "",
           icon: <Dot size="small" color="warning" />,
         },
         {
           id: 13,
           label: "Starred",
           link: "",
           icon: <Dot size="small" color="primary" />,
         },
         {
           id: 14,
           label: "Background",
           link: "",
           icon: <Dot size="small" color="secondary" />,
         },}*/


    // global
    var { isSidebarOpened } = useLayoutState();
    var layoutDispatch = useLayoutDispatch();

    // local
    var [isPermanent, setPermanent] = useState(true);

    useEffect(function() {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    return (
        <Drawer

            variant={isPermanent ? "permanent" : "temporary"}

            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar} />
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse),
                        }}
                    />
                </IconButton>
            </div>
            <List className={classes.sidebarList}>
                {structure.map(link => (
                    <SidebarLink
                        key={link.id}
                        location={location}
                        isSidebarOpened={isSidebarOpened}
                       {...link}
                    />
                ))}
            </List>
        </Drawer>
    );

    // ##################################################################
    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }
}

export default withRouter(SidebarServiceEnLigne);
