import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import useStyles from "../pages/login/styles";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();
var token_s = localStorage.getItem("token")  ;
var se_token = localStorage.getItem("se_token")  ;
var candidature_token = localStorage.getItem("candidature_token")  ;

var isauth=false;
if(token_s || se_token || candidature_token ){

  isauth=true;

}
function Copyright(props) {
  var classes = useStyles();
  return (
      <Typography variant="body2" color="orange"   align="center" {...props}>
        © {new Date().getFullYear()}-{new Date().getFullYear()+1} <a style={{textDecoration: 'none', color: 'inherit'}}
                                             href="" rel="noopener noreferrer"
                                             target="_blank">{ localStorage.getItem('entity_abrv_name')}</a>. All rights reserved.
      </Typography>
  );
}
function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated:isauth
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {Copyright, UserProvider,useUserState, useUserDispatch,  signOut };

// ###########################################################




function signOut(dispatch, history) {
  localStorage.clear();
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
