import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {BASE_URL, baseAxiosService, se_token, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import Alert from '@mui/material/Alert';

import {useTranslation} from "react-i18next";
import i18n from "../../../multilingue/i18n";
import CloseIcon from '@mui/icons-material/Close';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Dialog,
    Divider,
    ListItem, Select
} from "@mui/material";
import {isMobile} from "react-device-detect";
import Typography from "@mui/material/Typography";
import LogoTitle from "../../../images/logo_title.png";
import Avatar from "@mui/material/Avatar";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import DescriptionIcon from '@mui/icons-material/Description';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {Close} from "@material-ui/icons";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LoopIcon from '@mui/icons-material/Loop';
export default function DemandesEtud(props) {


    const [loading, setloading] = useState(false);
    const [validePlagia, setValidePlagia] = useState(false);
    const [loadingContent, setLoadingContent] = useState(true);

    //********************************************* HPC ****************************************
    const [openFormHpc, setOpenFormHpc] = useState(false);
    const [loadingHpc, setloadingHpc] = useState(false);
    const [demandesHpc, setDemandesHpc] = useState([]);

    const [logiciels, setLogiciels] = useState("");
    const [typeCalculI, setTypeCalculI] = useState("");
    const [logicielsError, setLogicielsError] = useState(false);
    const [nbProcess, setNbProcess] = useState("");
    const [nbProcessError, setNbProcessError] = useState(false);
    const [typeCalcul, setTypeCalcul] = useState("seq");
    const [nbMemoire, setNbMemoire] = useState("");
    const [nbMemoireError, setNbMemoireError] = useState(false);
    const [stockage, setStockage] = useState("");
    const [stockageError, setStockageError] = useState(false);
    const [tempExecuteS, setTempExecuteS] = useState("");
    const [tempExecuteSError, setTempExecuteSError] = useState(false);
    const [doctorat, setDoctorat] = useState(false);
    const [tokenInscription, setTokenInscription] = React.useState("");
    const [loadingS, setLoadingS] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [chooseCertificat, setChooseCertificat] = useState("");
    const info_doc = (param) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/info_doc`)
            .then(res => {
                if (res.data.status === 200) {
                    if (res.data.params.ip && res.data.params.sujet_doc && res.data.params.encadrant) {
                        setDoctorat(true)
                    } else {
                        setDoctorat(false)
                    }
                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: 'ERROR !',
                        text: 'Merci de réssayée au plus tard !',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                } else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                }


            }).catch(function (error) {
            if (error.response) {
                localStorage.clear();
                window.location.href = "/login"
            }
        })
    }

    const is_french = (value) => {
        var letters = /^([a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+\s)*[a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/;

        if (letters.test(value)) {
            return true;
        } else {
            return false;
        }

    }
    const information_step_one = (params) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/information_by_step`, params)

            .then(async res => {
                if (res.data.status === 200) {
                    if (res.data.params.inscription.ip_token!=="") {
                        setTokenInscription(res.data.params.inscription.ip_token)
                    }

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merci de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                } else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                }


            })
    }


    const handlformsubmitHpc = (event) => {
        event.preventDefault();
        setloadingHpc(true)
        setLogicielsError(false)
        setNbProcessError(false)
        setNbMemoireError(false)
        setStockageError(false)
        setTempExecuteSError(false)
        if (logiciels === '' || !is_french(logiciels)) {
            setLogicielsError(true)
            setloadingHpc(false)
            return false
        }
        let seq = '';
        if (typeCalcul === '') {
            Swal.fire({
                title: "Erreur !",
                text: "Merci d'entrer un type de calcul",
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setloadingHpc(false)
            return false;
        } else if (typeCalcul === 'seq') {
            seq = 'true' + '';
        } else {
            seq = 'false' + '';
        }
        if (nbProcess === '' || typeof nbProcess === "number" || nbProcess < 0) {
            setNbProcessError(true)
            setloadingHpc(false)

            return false
        }
        if (nbMemoire === '' || typeof nbMemoire === "number" || nbMemoire < 0) {
            setNbMemoireError(true)
            setloadingHpc(false)
            return false
        }
        if (stockage === '' || typeof stockage === "number" || stockage < 0) {
            setStockageError(true)
            setloadingHpc(false)
            return false
        }
        if (tempExecuteS === '' || typeof tempExecuteS === "number" || tempExecuteS < 0) {
            setTempExecuteSError(true)
            setloadingHpc(false)
            return false
        }

        const linkSource = `${BASE_URL}/inscription/${Version}/preinscription/etudiant/get_demande_hpc?access_token=${se_token}&token_inscription=${tokenInscription}&logiciels=${logiciels}
            &estimation_process=${nbProcess}&memoire=${nbMemoire}&stockage=${stockage}&tmp_exec=${tempExecuteS}&sequentiel=${seq}`;
        window.open(linkSource)
        setOpenFormHpc(false)
        setloadingHpc(false)

        setTempExecuteS("");
        setNbMemoire("")
        setNbProcess("")
        setLogiciels("")
        setStockage("")
        get_demandes_etudiant();

    }
    //******************************************************************************************
    //********************************************* Anti-Plagiat *******************************

    const send_demande = (params) => {


        baseAxiosService.post(`${BASE_URL}/espace-etudiant/${Version}/send_demande`, params)

            .then(async res => {

                if (res.data.status === 200 && res.data.params) {
                    setloading(false)
                    get_demandes_etudiant();

                } else if (res.data.status === -1) {

                    setloading(false)

                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                }


            })
    }
    //******************************************************************************************

    const {t} = useTranslation();


    const theme = createTheme();

    const [showDetailsHpc, setShowDetailsHpc] = useState(false);
    let infoHpcJ = {};
    const handlchangeDemandeHpcfile = (event, id) => {
        setIsImport(true)
        demandesHpc.forEach((d) => {
            if (d.type_demande === "demande_hpc" && d.id === id) {
                let file = event.target.files[0];
                let fileName = document.querySelector('#demande_hpc_' + id).value;
                let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
                if (extension === "pdf") {
                    let datas = new FormData();
                    datas.set('demande_id', id)
                    datas.set('demande_file', file)
                    send_demande_hpc(datas)

                } else {
                    setIsImport(false)
                    Swal.fire({
                        title: "Erreur ! ",
                        text: "Merci de choisir un fichier PDF",
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }
            }

        })
    }
    const showDetails = (id) => {
        setShowDetailsHpc(true)
        demandesHpc.forEach((d) => {
            if (d.type_demande === "demande_hpc" && d.id === id) {

                infoHpcJ = JSON.parse(d.attributes);
                console.log("test : ", infoHpcJ)
                setTempExecuteS(infoHpcJ.tmp_exec);
                setNbMemoire(infoHpcJ.memoire)
                setNbProcess(infoHpcJ.estimation_process)
                setLogiciels(infoHpcJ.logiciels)
                setStockage(infoHpcJ.stockage)
                infoHpcJ.sequentiel === "true" ? setTypeCalculI("Sequentiel") : setTypeCalculI("Parallèle ");

            }

        })
    }

    const handlformsubmit = async (event) => {
        setOpenFormHpc(false);
        setTempExecuteS("");
        setNbMemoire("")
        setNbProcess("")
        setLogiciels("")
        setStockage("")
        let type_demande = event.target.value;
        if (type_demande === "ithenticate") {
            setloading(true);

            await Swal.fire({
                title: t('confirme_demande'),
                icon: 'success',
                confirmButtonText: t('confirmer'),
                confirmButtonColor: 'green',
                color: 'success'
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = new FormData();
                    data.set('type_demande', type_demande);
                    send_demande(data);
                } else {
                    setloading(false);
                }

            })

        } else if (type_demande === "hpc") {
            setOpenFormHpc(true);
        } else if (type_demande === "certificat_scolarite" || type_demande === "certificat_inscription" || type_demande === "attestation_reussite" || type_demande==="releve_notes") {

            setloading(true);

            await Swal.fire({
                title: t('confirme_demande'),
                icon: 'success',
                confirmButtonText: t('confirmer'),
                confirmButtonColor: 'green',
                color: 'success'
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = new FormData();
                    data.set('type_demande', chooseCertificat);
                    send_demande(data);
                } else {
                    setloading(false);
                }

            })
        } else {
            setLoadingS(false);

        }


    }
    const handleChangeChooseAtt = (event) => {
        if (event.target.value !== '') {
            setChooseCertificat(event.target.value);

        } else {
            Swal.fire({
                title: "Erreur Merci de ressayer au plus tard",
                icon: 'error',
                confirmButtonText: t('confirmer'),
                confirmButtonColor: 'red',
                color: 'red'
            })
        }


    }
    async function get_demandes_etudiant() {
        try {
            await baseAxiosService.post(`${BASE_URL}/espace-etudiant/${Version}/get_demandes_etudiant`)
                .then(res => {
                    if (res.data.status === 200 && res.data.params) {

                        res.data.params.forEach((e) => {
                            if (e.type_demande === "ithenticate") {
                                setValidePlagia(true);

                            }
                        })
                        const demandesT = res.data.params;
                        const sortedDemandes = demandesT.sort((a, b) => {
                            return new Date(b.created_at) - new Date(a.created_at);
                        });
                        setDemandesHpc(sortedDemandes);

                        setLoadingContent(false)
                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merci de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: t('confirmer'),
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                    }
                })
        } catch (error) {
            console.error(error);
        }
    }
    async function send_demande_hpc(params) {
        try {
            await baseAxiosService.post(`${BASE_URL}/espace-etudiant/${Version}/send_demande_hpc`, params)
                .then(res => {
                    if (res.data.status === 200 && res.data.params) {
                        setIsImport(false)
                        Swal.fire({
                            title: "Votre demande a été effectuée avec succès.\n",
                            icon: 'success',
                            confirmButtonText: t('confirmer'),
                            confirmButtonColor: 'green',
                            color: 'success'
                        })
                        get_demandes_etudiant()

                    } else if (res.data.status === -1) {
                        setIsImport(false)
                        Swal.fire({
                            title: "Erreur Merci de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: t('confirmer'),
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                    }


                })

        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        let datastep = new FormData();
        datastep.set('step', 'bac')
        information_step_one(datastep);
        info_doc();
        get_demandes_etudiant();

    }, []);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>

            {isMobile && (
                <Typography dir={i18n.language === "ar" ? "rtl" : "ltr "} variant={"h6"} fontFamily={"Open Sans"}
                            fontWeight={"bold"} color={"primary"}>
                    <DescriptionIcon/> {t("mes_demandes")}
                </Typography>)}
            <Container maxWidth={isMobile ? "lg" : "md"} sx={{mb: 4, p: 0}}>

                <Paper
                    variant="outlined"
                    sx={{my: {xs: 1, md: 1}, p: {md: 3}}}
                    dir={i18n.language === "ar" ? "rtl" : "ltr "}
                >
                    <Grid container spacing={3} mt={1}>
                        {!isMobile ? <>  <Grid item align={i18n.language === "ar" ? "right" : "left"} xs={8} sm={8}>
                                <Typography variant={"h5"} fontFamily={"Open Sans"} fontWeight={"bold"} color={"primary"}>
                                    <DescriptionIcon/> {t("mes_demandes")}
                                </Typography>

                            </Grid>
                                <Grid item align={i18n.language === "ar" ? "left" : "right"} xs={4} sm={4}>
                                    <img
                                        style={{width: 90, height: 60}}

                                        alt="Etablissement logo"
                                        src={LogoTitle}

                                    />
                                </Grid> </> :
                            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                <Grid item align={"centre"} alignContent={"center"} xs={12} sm={12}>
                                    <Avatar
                                        sx={{width: 200, height: 60}}
                                        alt="Etablissement logo"
                                        src={LogoTitle}
                                        variant="square"

                                    />
                                </Grid>
                            </Grid>}
                    </Grid>
                    <Divider sx={{mt: 3, mb: 3}} variant="middle" color={"orange"}/>
                    {!loadingContent ?
                        <Grid container spacing={2} paddingX={2} mb={5}>
                            {validePlagia && doctorat &&
                                (
                                    <Grid item xs={12} sm={12}>
                                        <Alert severity="info"
                                               style={{fontWeight: "bold", textAlign: "justify"}}>
                                            Dès la création de compte, un mail de
                                            notification provenant de Ithenticate, contient l'identifiant et
                                            le mot de
                                            passe, vous sera envoyé sur vos adresses institutionnelles.


                                            Pour une première utilisation,
                                            vous pouvez revoir l'enregistrement de la formation organisée
                                            par le CNRST
                                            et
                                            Turnitin sur Ithenticate.<br/>
                                            <Link
                                                href={"https://www.youtube.com/watch?v=U58xautSCMA&t=2289s&ab_channel=CNRST-Cha%C3%AEneofficielle"}
                                                color="inherit">
                                                clique ici
                                            </Link><br/>
                                            Vous pouvez également se
                                            référer à l'aide sur le lien suivant:<br/>

                                            <Link
                                                href={"https://help.turnitin.com/ithenticate/ithenticate-user/ithenticate-user.htm"}
                                                color="inherit">
                                                Lien
                                            </Link><br/>

                                        </Alert>
                                    </Grid>)
                            }
                            <Grid item xs={8} sm={8}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        id="demo-simple-select-helper-label">{t('Documents_administratifs')}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={chooseCertificat}
                                        label={t('Documents_administratifs')}
                                        onChange={handleChangeChooseAtt}
                                    >
                                        <MenuItem
                                            value={'certificat_scolarite'}>{t('certificat_scolarite')}</MenuItem>
                                        <MenuItem
                                            value={'certificat_inscription'}>{t('certificat_inscription')}</MenuItem>
                                        <MenuItem value={'attestation_reussite'}>{t('attestation_reussite')}</MenuItem>
                                        <MenuItem value={'releve_notes'}>{t('releve_notes')}</MenuItem>
                                    </Select>

                                </FormControl>

                            </Grid>
                            <Grid item xs={4} sm={4} textAlign={"end"} marginBottom={1} paddingRight={2} paddingTop={2}>
                                <Button
                                    variant="contained"
                                    disabled={loading}
                                    onClick={handlformsubmit}
                                    value={chooseCertificat}
                                    style={{color: "white"}}
                                    size={"small"}

                                >
                                    {t('envoyer')}
                                </Button>
                            </Grid>
                            {
                                doctorat &&
                                (<>

                                        {!validePlagia &&
                                            (
                                                <Grid item xs={12} md={12}>
                                                    <List>
                                                        <ListItem
                                                        >
                                                            <ListItemAvatar>
                                                                <Avatar style={{background: "#1976d2"}}>
                                                                    <DescriptionIcon/>
                                                                </Avatar>

                                                            </ListItemAvatar>

                                                            <ListItemText
                                                                primary={t('demande_antiplagiat')}
                                                                sx={i18n.language === "ar" ? {textAlign: "right"} : {textAlign: "left"}}
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                disabled={loading}
                                                                onClick={handlformsubmit}
                                                                value={"ithenticate"}
                                                                style={{color: "white"}}
                                                                size={"small"}

                                                            >
                                                                {t('envoyer')}
                                                            </Button>
                                                        </ListItem>


                                                    < /List>
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={12} md={12}>
                                            <List>
                                                <ListItem

                                                >
                                                    <ListItemAvatar>
                                                        <Avatar style={{background: "#1976d2"}}>
                                                            <DescriptionIcon/></Avatar>
                                                    </ListItemAvatar>

                                                    <ListItemText
                                                        primary={t('demande_hpc')}
                                                        sx={i18n.language === "ar" ? {textAlign: "right"} : {textAlign: "left"}}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        disabled={loadingS}
                                                        onClick={handlformsubmit}
                                                        value={"hpc"}
                                                        style={{color: "white"}}
                                                        size={"small"}

                                                    >
                                                        {t('ajouter_une_demande')}
                                                    </Button>
                                                </ListItem>
                                            </List>
                                        </Grid>


                                        <Box component="form" spacing={3}>
                                            <Grid container spacing={3}>

                                                <Dialog
                                                    style={{padding: 10}}
                                                    open={openFormHpc}
                                                    keepMounted
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogContent style={{width: isMobile ? "" : "600px"}}>

                                                        <Grid item xs={12} sm={12} textAlign={"center"}>

                                                            <TextField
                                                                required
                                                                style={{marginTop: 10}}

                                                                value={logiciels}
                                                                onChange={(e) => {
                                                                    setLogiciels(e.target.value)
                                                                }}
                                                                error={logicielsError}
                                                                helperText={logicielsError ? t('champ_obligatoire_fr') : ' '}

                                                                fullWidth
                                                                id="logiciel"
                                                                label={"Logiciels / librairies "}
                                                                autoComplete="off"
                                                                autoFocus

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>
                                                            <FormControl>
                                                                <FormLabel id="demo-row-radio-buttons-group-label">Type
                                                                    de
                                                                    Calcul</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    onChange={(event) => {
                                                                        setTypeCalcul(event.target.value)
                                                                    }}
                                                                    value={typeCalcul}


                                                                >
                                                                    <FormControlLabel value="seq" control={<Radio/>}
                                                                                      label={"Séquentiel"}/>
                                                                    <FormControlLabel value="paral" control={<Radio/>}
                                                                                      label={"Parallèle"}/>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>

                                                            <TextField
                                                                required
                                                                style={{marginTop: 10}}
                                                                type={"number"}
                                                                value={nbProcess}
                                                                onChange={(e) => {
                                                                    setNbProcess(e.target.value)
                                                                }}
                                                                error={nbProcessError}
                                                                helperText={nbProcessError ? t('champ_obligatoire') : ' '}
                                                                fullWidth
                                                                id="nb_process"
                                                                label={"Estimation du Nombre de processeurs  "}
                                                                autoComplete="off"
                                                                autoFocus
                                                                inputProps={{min: 0}}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>

                                                            <TextField
                                                                required
                                                                style={{marginTop: 10}}
                                                                type={"number"}
                                                                value={nbMemoire}
                                                                onChange={(e) => {
                                                                    setNbMemoire(e.target.value)
                                                                }}
                                                                error={nbMemoireError}
                                                                helperText={nbMemoireError ? t('champ_obligatoire') : ' '}
                                                                fullWidth
                                                                id="cap_memoire"
                                                                label={"Estimation de la capacité de mémoire (GB)  "}
                                                                autoComplete="off"
                                                                autoFocus
                                                                inputProps={{min: 0}}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>

                                                            <TextField
                                                                required
                                                                style={{marginTop: 10}}
                                                                type={"number"}
                                                                value={stockage}
                                                                onChange={(e) => {
                                                                    setStockage(e.target.value)
                                                                }}
                                                                error={stockageError}
                                                                helperText={stockageError ? t('champ_obligatoire') : ' '}
                                                                fullWidth
                                                                id="stockage"
                                                                label={"Estimation de l'espace de stockage (GB) "}
                                                                autoComplete="off"
                                                                autoFocus
                                                                inputProps={{min: 0}}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>

                                                            <TextField
                                                                required
                                                                style={{marginTop: 10}}
                                                                type={"number"}
                                                                value={tempExecuteS}
                                                                onChange={(e) => {
                                                                    setTempExecuteS(e.target.value)
                                                                }}
                                                                error={tempExecuteSError}
                                                                helperText={tempExecuteSError ? t('champ_obligatoire') : ' '}
                                                                fullWidth
                                                                id="tmp_execution"
                                                                label={"Estimation du temps d'exécution  (min)"}
                                                                autoComplete="off"
                                                                autoFocus
                                                                inputProps={{min: 0}}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={6} sm={6} style={{display: "inline"}}>

                                                            <Button
                                                                type="submit"
                                                                sx={{ml: 1, textAlign: "center"}}
                                                                endIcon={<Close/>}
                                                                onClick={() => {
                                                                    setOpenFormHpc(false)
                                                                }}
                                                                color={"warning"}
                                                                size="large"
                                                                variant="contained"

                                                            >
                                                                {t('fermer')}
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="large"
                                                                type="submit"
                                                                style={{marginLeft: 20, textAlign: "center"}}
                                                                endIcon={<SendIcon/>}
                                                                onClick={handlformsubmitHpc}
                                                                disabled={loadingHpc}
                                                            >
                                                                {t('confirmer')}
                                                            </Button>
                                                        </Grid>

                                                    </DialogContent>
                                                </Dialog>


                                            </Grid>

                                        </Box>

                                    </>
                                )}

                            <Grid item xs={12} md={12}>
                                <Accordion style={{padding: 0}} defaultExpanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{background: "#007fa4"}}
                                    >
                                        <Typography color={"white"} fontWeight={"bold"}
                                                    fontSize={18}>{t('mes_demandes')}</Typography>
                                    </AccordionSummary>
                                    {demandesHpc.length>0 ?
                                 (demandesHpc.map((e, index) => {

                                        const timestamp = new Date(e.created_at);

                                        const day = timestamp.getDate().toString().padStart(2, '0');
                                        const month = (timestamp.getMonth() + 1).toString().padStart(2, '0');
                                        const year = timestamp.getFullYear();
                                        const formattedDate = `${day}/${month}/${year}`;
                                        let DateLivre="";
                                                    if(e.date_livrer){
                                                        const timestampDate = new Date(e.date_livrer);

                                                        const dayDate = timestampDate.getDate().toString().padStart(2, '0');
                                                        const monthDate = (timestampDate.getMonth() + 1).toString().padStart(2, '0');
                                                        const yearDate = timestampDate.getFullYear();
                                                         DateLivre = `${dayDate}/${monthDate}/${yearDate}`;
                                                    }

                                        let type_demande_d = "";

                                        if (e.type_demande === "demande_hpc") type_demande_d = t('mes_demande_hpc');
                                        if (e.type_demande === "certificat_scolarite") type_demande_d = t('certificat_scolarite');
                                        if (e.type_demande === "certificat_inscription") type_demande_d = t('certificat_inscription');
                                        if (e.type_demande === "attestation_reussite") type_demande_d = t('attestation_reussite');
                                        if (e.type_demande === "ithenticate") type_demande_d = t('demande_antiplagiat');
                                        if (e.type_demande === "releve_notes") type_demande_d = t('releve_notes');


                                        return (
                                            <AccordionDetails key={index}>

                                                {e.type_demande === 'demande_hpc' && doctorat && (
                                                    <List>
                                                        < ListItem
                                                            secondaryAction={
                                                                <IconButton edge="end" aria-label="eye"
                                                                            onClick={() => {
                                                                                showDetails(e.id)
                                                                            }}>
                                                                    <RemoveRedEyeIcon/>
                                                                </IconButton>
                                                            }
                                                        >
                                                            <ListItemAvatar>

                                                                { !e.file_id ?    <Avatar style={{background: "#f7941f"}}>
                                                                    <LoopIcon />
                                                                </Avatar> :  <Avatar style={{background: "green"}}>
                                                                    < OfflinePinIcon/>
                                                                </Avatar>}
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={e.file_id === "" ? formattedDate + ' ' + type_demande_d + ' : ' + t('demande_succes_s') : formattedDate +' '+ type_demande_d +' : ' + t('demande_succes')}
                                                                sx={i18n.language === "ar" ? {textAlign: "right"} : {textAlign: "left"}}
                                                            />
                                                         <Button
                                                                variant="contained"
                                                                disabled={true}
                                                                style={{
                                                                    background: "#f7941f",
                                                                    color: "white"
                                                                }}
                                                                size={"small"}
                                                            >
                                                                {t('demande_en_traitement')}
                                                            </Button>
                                                        </ListItem>
                                                    </List>)}
                                                    {e.type_demande === 'ithenticate' && doctorat  &&
                                                        (
                                                        <List>
                                                        < ListItem>
                                                        <ListItemAvatar>
                                                        <Avatar style={{background: "#f7941f"}}>
                                                        < LoopIcon/>
                                                        </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                        primary={formattedDate + ' ' + type_demande_d + ' : ' + t('demande_succes')}
                                                        sx={i18n.language === "ar" ? {textAlign: "right"} : {textAlign: "left"}}
                                                        />
                                                           <Button
                                                                variant="contained"
                                                                disabled={true}
                                                                style={{
                                                                    background: "#f7941f",
                                                                    color: "white"
                                                                }}
                                                                size={"small"}
                                                            >
                                                                {t('demande_en_traitement')}
                                                            </Button>
                                                        </ListItem>
                                                        </List>)}
                                                        {(!e.file_id && e.type_demande === 'demande_hpc' && doctorat) && (
                                                            <Grid container spacing={2} paddingX={2}>
                                                                <Grid item xs={12} sm={6}>

                                                                    <TextField

                                                                        onChange={(event) => {
                                                                            handlchangeDemandeHpcfile(event, e.id)
                                                                        }}
                                                                        id={"demande_hpc_" + e.id}
                                                                        name="demande_hpc"
                                                                        fullWidth
                                                                        autoComplete="given-name"
                                                                        variant="filled"
                                                                        type={"file"}
                                                                        inputProps={{accept: ".pdf"}}
                                                                    />
                                                                </Grid>
                                                                {isImport && (
                                                                    <Grid item xs={4} sm={4}>
                                                                        <CircularProgress
                                                                            style={{
                                                                                marginTop: 2,
                                                                                marginBottom: 2
                                                                            }}/>

                                                                    </Grid>)}
                                                            </Grid>
                                                        )}


                                                {e.type_demande !== 'ithenticate' &&  e.type_demande !== 'demande_hpc'    &&   <List>
                                                    < ListItem
                                                    >
                                                    <ListItemAvatar>
                                                        {e.etat === "En attente de traitement" || e.etat === "" || e.etat ===null?    <Avatar style={{background: "#f7941f"}}>
                                                    <LoopIcon />
                                                    </Avatar> :  <Avatar style={{background: "green"}}>
                                                            < OfflinePinIcon/>
                                                        </Avatar>}
                                                            </ListItemAvatar>
                                                    <ListItemText
                                                    primary={!e.livrer || e.etat === "En attente de traitement" ? formattedDate + ' ' + type_demande_d + ' : ' + t('demande_succes') : type_demande_d + " "+t('recu_le')+" : "+DateLivre}
                                                    sx={i18n.language === "ar" ? {textAlign: "right"} : {textAlign: "left"}}
                                                    />
                                                        {e.etat === "En attente de traitement" && e.date_livrer===null  ? <Button
                                                            variant="contained"
                                                            disabled={true}
                                                            style={{
                                                                background: "#f7941f",
                                                                color: "white"
                                                            }}
                                                            size={"small"}
                                                        >
                                                            {t('demande_en_traitement')}
                                                        </Button>:
                                                            <Button
                                                                variant="contained"
                                                                disabled={true}
                                                                style={{
                                                                    background: "green",
                                                                    color: "white"
                                                                }}
                                                                size={"small"}
                                                            >
                                                                {t('demande_traiter')}
                                                            </Button>  }
                                                    </ListItem>
                                                    </List> }


                                                    </AccordionDetails>


                                                    )

                                                }
                                                )
                                                )  :   <Grid item xs={12} sm={12} style={{
                                            marginTop: 14,
                                            background: "#FDEDED",
                                            textAlign: "center",
                                            padding: 13,
                                            marginBottom: 14
                                        }}>
                                            <ErrorOutlineIcon sx={{
                                                color: 'red', fontSize: 50
                                            }}/>
                                            <Typography variant={"h5"}>
                                                <strong> {t('aucun_demande')} </strong>
                                            </Typography>

                                        </Grid>
                                                }
                                                <Dialog
                                                    style={{padding: 10}}
                                                    open={showDetailsHpc}
                                                    keepMounted
                                                    aria-describedby="alert-dialog-slide-description"
                                                >
                                                    <DialogActions>
                                                        <Button onClick={() => {
                                                            setShowDetailsHpc(false)
                                                        }}><CloseIcon/></Button>

                                                    </DialogActions>
                                                    <DialogContent style={{width: isMobile ? "" : "600px"}}>

                                                        <Grid item xs={12} sm={12} textAlign={"center"}>

                                                            <TextField
                                                                disabled
                                                                style={{marginTop: 10}}
                                                                value={logiciels}
                                                                fullWidth
                                                                label={"Logiciels / librairies "}
                                                                autoComplete="off"
                                                                autoFocus
                                                                variant="filled"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>
                                                            <TextField
                                                                disabled
                                                                style={{marginTop: 10, marginBottom: 10}}
                                                                value={typeCalculI}
                                                                fullWidth
                                                                id="tmp_execution_i"
                                                                label={"Type de Calcul"}
                                                                autoComplete="off"
                                                                autoFocus
                                                                inputProps={{min: 0}}
                                                                variant="filled"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>
                                                            <TextField
                                                                disabled
                                                                style={{marginTop: 10}}
                                                                value={nbProcess}
                                                                fullWidth
                                                                id="nb_process_i"
                                                                label={"Estimation du Nombre de processeurs  "}
                                                                autoComplete="off"
                                                                autoFocus
                                                                inputProps={{min: 0}}
                                                                variant="filled"

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>

                                                            <TextField
                                                                disabled
                                                                style={{marginTop: 10}}
                                                                value={nbMemoire}
                                                                fullWidth
                                                                label={"Estimation de la capacité de mémoire (GB)  "}
                                                                autoComplete="off"
                                                                autoFocus
                                                                inputProps={{min: 0}}
                                                                variant="filled"

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>

                                                            <TextField
                                                                disabled
                                                                style={{marginTop: 10}}
                                                                value={stockage}
                                                                fullWidth
                                                                id="stockage"
                                                                label={"Estimation de l'espace de stockage (GB) "}
                                                                autoComplete="off"
                                                                autoFocus
                                                                inputProps={{min: 0}}
                                                                variant="filled"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} textAlign={"center"}>

                                                            <TextField
                                                                disabled
                                                                style={{marginTop: 10, marginBottom: 10}}
                                                                type={"number"}
                                                                value={tempExecuteS}
                                                                fullWidth
                                                                id="tmp_execution"
                                                                label={"Estimation du temps d'exécution  (min)"}
                                                                autoComplete="off"
                                                                autoFocus
                                                                inputProps={{min: 0}}
                                                                variant="filled"
                                                            />
                                                        </Grid>
                                                    </DialogContent>
                                                </Dialog>
                                            </Accordion>
                                    </Grid>
                                    </Grid>
                                    :
                                        (
                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                                                    <CircularProgress/>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                        </Paper>
                                        </Container>
                                        </ThemeProvider>
                                        );
                                    }
