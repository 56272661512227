import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import SendIcon from '@mui/icons-material/Send';
import Button from "@mui/material/Button";
import {Autocomplete, CircularProgress} from "@mui/material";
import {BASE_URL, baseAxios, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import i18n from "../../../multilingue/i18n";
import Alert from "@mui/material/Alert";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";
var regimes_couv=[];
export default function CouvMedicale(props) {
    const {t} = useTranslation();
    const [loadingContent, setloadingContent] = useState(true);
    const [loading, setloading] = useState(false);
    const [checkedCompte, setCheckedCompte] = React.useState(false);
    const [checkedCniPere, setCheckedCniPere] = React.useState(true);
    const [checkedCniMere, setCheckedCniMere] = React.useState(true);
    const [checkedecedePere, setCheckedecedePere] = React.useState(false);
    const [checkedecedeMere, setCheckedecedeMere] = React.useState(false);
    const [checkeCouv, setCheckeCouv] = React.useState(false);
    const [regimeCouvertureValue, setRegimeCouvertureValue] = useState("");
    const [etatCivil, setEtatCivil] = useState("c");
    const [rib, setRib] = useState("");
    const [ribError, setRibError] = useState(false);

    const [cinPere, setCinPere] = useState("");
    const [cinPereError, setCinPereError] = useState(false);

    const [nomPere, setNomPere] = useState("");
    const [nomPereError, setNomPereError] = useState(false);

    const [prenomPere, setPrenomPere] = useState("");
    const [prenomPereError, setPrenomPereError] = useState(false);

    const [ddnPere, setDdnPere] = useState("");
    const [ddnPereError, setDdnPereError] = useState(false);

    const [cinMere, setCinMere] = useState("");
    const [cinMereError, setCinMereError] = useState(false);

    const [nomMere, setNomMere] = useState("");
    const [nomMereError, setNomMereError] = useState(false);

    const [prenomMere, setPrenomMere] = useState("");
    const [prenomMereError, setPrenomMereError] = useState(false);

    const [ddnMere, setDdnMere] = useState("");
    const [ddnMereError, setDdnMereError] = useState(false);

    const [DdnDecePere, setDdnDecePere] = useState("");
    const [DdnDecePereError, setDdnDecePereError] = useState(false);

    const [DdnDeceMere, setDdnDeceMere] = useState("");
    const [DdnDeceMereError, setDdnDeceMereError] = useState(false);

    const [cinConjoint, setCinConjoint] = useState("");
    const [cinConjointError, setCinConjointError] = useState(false);

    const [DdnDeceConjoint, setDdnDeceConjoint] = useState("");
    const [DdnDeceConjointError, setDdnDeceConjointError] = useState(false);

    const [DdnDivorceConjoint, setDdnDivorceConjoint] = useState("");
    const [DdnDivorceConjointError, setDdnDivorceConjointError] = useState(false);

    const dateValid = (value) => {
    var date_regex = /^(19|20)\d{2}\-(0[1-9]|1[0-2])\-(0[1-9]|1\d|2\d|3[01])$/;

    if (!(date_regex.test(value))) {
        return false
    }else{
        return true
    }
}

    const is_french= (value)=>{
        var letters =  /^([a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+\s)*[a-zA-Z -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/;

        if(letters.test(value))
        {
            return true;
        }
        else
        {
            return false;
        }

    }

    const create_account_step_3_amo = (params) => {
        baseAxios.post(`${BASE_URL}/inscription/${Version}/etudiant/create_account_step_3_amo`, params)

            .then(async res => {


                if (res.data.status === 1) {
                    await Swal.fire({
                        title: t('step_4'),
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.EtapeSuivant();
                        }
                    })
                    setloading(false)


                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur !",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setloading(false)

                }else{
                    setloading(false)
                }
                setloading(false)

            })
    }

    useEffect(() => {
        const formatDate = (date) => {
            const [day, month, year] = date.split('/');

            const result = [year, month, day].join('-');
            return result;
        }

        const regime_couverture = () => {
            baseAxios.get(`${BASE_URL}/inscription/${Version}/inscription/regime_couverture`)

                .then(async res => {
                    if (res.data.status === 200) {
                        const regime_couvertures = res.data.params;
                        regimes_couv=regime_couvertures;

                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }


                })
        }

        const information_step_one = (params) => {
            baseAxios.post(`${BASE_URL}/inscription/${Version}/etudiant/information_by_step`, params)
                .then(async res => {
                    if (res.data.status === 200 && res.data.params.etudiant && res.data.params.amo) {
                        setTimeout(
                            () => {
                                let amo = res.data.params.amo;
                                let etudiant = res.data.params.etudiant;

                                const regex = new RegExp("^ETR");

                                if (regex.test(etudiant.code_massar)) {
                                    props.EtapeSuivant()
                                }

                                setEtatCivil(etudiant.etat_civile_etudiant);
                                if (amo.couverture_medicale_statut) {
                                    let regim_c = regimes_couv.find(item => item.code_assurance === amo.code_assurance) || null;
                                    setCheckeCouv(true)
                                    setRegimeCouvertureValue(regim_c)
                                }


                                if (amo.rib_etudiant_statut) {
                                    setCheckedCompte(true);
                                    setRib(amo.numero_rib_etudiant)
                                }
                                if (etudiant.etat_civile_etudiant === "c" || etudiant.etat_civile_etudiant === "d" || etudiant.etat_civile_etudiant === "v") {

                                    if (!amo.prenom_pere_etudiant) {
                                        setCheckedCniPere(true);
                                        setCinPere(amo.cni_pere_etudiant)
                                    } else {
                                        setCheckedCniPere(false)
                                        setPrenomPere(amo.prenom_pere_etudiant)
                                        setNomPere(amo.nom_pere_etudiant)
                                        let ddnpere_f = formatDate(amo.date_naissance_pere_etud);
                                        setDdnPere(ddnpere_f)
                                    }
                                    if (!amo.prenom_mere_etudiant) {
                                        setCheckedCniMere(true);
                                        setCinMere(amo.cni_mere_etudiant)
                                    } else {
                                        setCheckedCniMere(false)
                                        setPrenomMere(amo.prenom_mere_etudiant)
                                        setNomMere(amo.nom_mere_etudiant)
                                        let ddnmere_f = formatDate(amo.date_naissance_mere_etud);
                                        setDdnMere(ddnmere_f)
                                    }
                                    if (amo.dece_pere_etud_statut===true) {
                                        setCheckedecedePere(true);
                                        let ddndecepere_f = formatDate(amo.date_dece_pere_etud);
                                        setDdnDecePere(ddndecepere_f)
                                    }
                                    if (amo.dece_mere_etud_statut===true) {
                                        setCheckedecedeMere(true);
                                        let ddndecemere_f = formatDate(amo.date_dece_mere_etud);
                                        setDdnDeceMere(ddndecemere_f)
                                    }
                                }

                                if (amo.cni_conjoint_etudiant ) {
                                    setCinConjoint(amo.cni_conjoint_etudiant)
                                }

                                if (amo.date_dece_conjoint_etud ) {
                                    let date_dece_conjoint_etud = formatDate(amo.date_dece_conjoint_etud);
                                    setDdnDeceConjoint(date_dece_conjoint_etud)
                                }

                                if (amo.date_divorce_etudiant ) {
                                    let date_divorce_etudiant = formatDate(amo.date_divorce_etudiant);
                                    setDdnDivorceConjoint(date_divorce_etudiant)
                                }

                                setloadingContent(false)
                            },1000)


                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }


                })
        }

        let isMounted = true;

        if(isMounted ){
                regime_couverture();
                let datastep = new FormData();
                datastep.set('step', 'couverture_medicale')
                information_step_one(datastep)


        }

        return () => {
            isMounted = false;
        };

    }, [])

    const ValideCin = (value) => {
        var letters = /^[A-Z]{1,2}?[0-9]{1}[0-9]{1,7}[A-Z]{0,1}$/;

        if (letters.test(value)) {
            return true;
        } else {
            return false;
        }
    }
    const ValideRib = (value) => {
        var rib = value.toString();
        var a1 =  rib.substr(0,3);
        var a2 =  rib.substr(3,3);
        var a3 =  rib.substr(6,4);
        var a4 =  rib.substr(10,6);
        var a5 =  rib.substr(16,6);
        var a6 =  rib.substr(22,2);

        var x1 = parseInt(a1) - 97 * parseInt(parseInt(a1) / 97);
        var c1 = x1 * 1000 + parseInt(a2);


        var x2 = parseInt(c1) - 97 * parseInt(parseInt(c1) / 97);
        var c2 = x2 * 10000 + parseInt(a3);

        var x3 = parseInt(c2) - 97 * parseInt(parseInt(c2) / 97);
        var c3 = x3 * 1000000 + parseInt(a4);

        var x4 = parseInt(c3) - 97 * parseInt(parseInt(c3) / 97);
        var c4 = x4 * 1000000 + parseInt(a5);

        var x5 = parseInt(c4) - 97 * parseInt(parseInt(c4) / 97);
        var c5 = x5 * 100;

        var x6 = parseInt(c5) - 97 * parseInt(parseInt(c5) / 97);

        var x7 = 97 - x6;


        return x7 === parseInt(a6);

    }
    const handlformsubmit = (event) => {
        event.preventDefault();

        setCinPereError(false)
        setRibError(false)
        setNomPereError(false)
        setPrenomPereError(false)
        setCinMereError(false)
        setNomMereError(false)
        setPrenomMereError(false)
        setCinConjointError(false)
        setDdnPereError(false)
        setDdnMereError(false)
        setDdnDecePereError(false)
        setDdnDeceMereError(false)
        setDdnDivorceConjointError(false)
        setDdnDeceConjointError(false)
        const data = new FormData();
        if (checkedCompte) {
            if (rib ==="" || !ValideRib(rib)) { setRibError(true)  ; return false}
            data.set('check_compte_bancaire', 'yes')
            data.set('rib', rib)
        } else data.set('check_compte_bancaire', 'no');
        if(etatCivil==="c" || etatCivil==="d" || etatCivil==="v" ){
            if (checkedCniPere) {
                if (cinPere ==="" || !ValideCin(cinPere)) { setCinPereError(true)  ; return false}
                data.set('check_cin_pere', 'yes')
                data.set('cin_pere', cinPere)

            } else {
                if (nomPere==="" || !is_french(nomPere)){ setNomPereError(true); return false}
                if (prenomPere==="" || !is_french(prenomPere) ){ setPrenomPereError(true); return false}

              if(!dateValid(ddnPere)){  setDdnPereError(true) ; return false }
                data.set('check_cin_pere', 'no');
                data.set('nom_pere', nomPere)
                data.set('prenom_pere', prenomPere)
                data.set('ddn_pere', ddnPere)
            }
            if (checkedCniMere) {
                data.set('check_cin_mere', 'yes')
                if (cinMere==="" || !ValideCin(cinMere)) { setCinMereError(true) ; return  false}
                data.set('cin_mere', cinMere)

            } else {
                if (nomMere==="" || !is_french(nomMere)){ setNomMereError(true); return false}
                if (prenomMere==="" || !is_french(prenomMere) ){ setPrenomMereError(true); return false}
                if(!dateValid(ddnMere)){  setDdnMereError(true) ; return false }

                data.set('check_cin_mere', 'no');
                data.set('nom_mere', nomMere)
                data.set('prenom_mere', prenomMere)
                data.set('ddn_mere', ddnMere)
            }
            if (checkedecedePere) {
                data.set('check_decede_pere', 'yes')
                if(!dateValid(DdnDecePere)){  setDdnDecePereError(true) ; return false }

                data.set('ddn_deces_pere', DdnDecePere)
            } else data.set('check_decede_pere', 'no');
            if (checkedecedeMere) {
                data.set('check_decede_mere', 'yes')
                if(!dateValid(DdnDeceMere)){  setDdnDeceMereError(true) ; return false }

                data.set('ddn_deces_mere', DdnDeceMere)
            } else data.set('check_decede_mere', 'no');
        }
        if (etatCivil==="m" || etatCivil==="d" || etatCivil==="v" ) {
            if (cinConjoint==="" || !ValideCin(cinConjoint)){ setCinConjointError(true); return  false }
            data.set('cnie_conjoint', cinConjoint)

        }
        if (etatCivil==="d") {
            if(!dateValid(DdnDivorceConjoint)){  setDdnDivorceConjointError(true) ; return false }

            data.set('date_divorce', DdnDivorceConjoint)

        }
        if (etatCivil==="v") {
            if(!dateValid(DdnDeceConjoint)){  setDdnDeceConjointError(true) ; return false }
            data.set('date_deces_conjoint', DdnDeceConjoint)
        }

        if (checkeCouv) {
            data.set('check_couverture_medicale', 'yes');
            if (regimeCouvertureValue.code_assurance) {
                data.set('regime_couverture', regimeCouvertureValue.code_assurance)
            } else {
                Swal.fire({
                    title: "Erreur !",
                    text: "Erreur dans le champ Regime couverture",
                    icon: 'error',
                    confirmButtonText: 'Confirmer',
                    confirmButtonColor: 'red',
                    color: 'red'
                })
                return false
            }

        } else {
            data.set('check_couverture_medicale', 'no')
        }
        setloading(true)

        create_account_step_3_amo(data);


    }
    return (
        <React.Fragment>
            {!loadingContent ?
                <Box component="form" container spacing={3} dir={i18n.language === "ar" ? "rtl" : "ltr "} onSubmit={handlformsubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Alert severity="info">{t('info_amo_etudiant')}</Alert>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {t('avez_vous_un_compte_bancaire')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch onChange={(event) => setCheckedCompte(event.target.checked)}
                                                     checked={checkedCompte} name="check_compte_bancaire"/>}/>
                            </FormGroup>
                        </Grid>
                        {checkedCompte ? (<Grid item xs={12} sm={12} alignItems="center"
                                                justifyContent="center">
                            <TextField
                                required
                                dir={"ltr"}
                                id="RIB"
                                name="rib"
                                value={rib || ""}
                                onChange={(e)=>{setRib(e.target.value)}}
                                label={t("rib")}
                                fullWidth
                                error={ribError}
                                helperText={ribError ? t('champ_obligatoire') : ' '}
                                autoComplete="off"
                                variant="outlined"
                            />
                        </Grid>) : ("")
                        }
                        {(etatCivil === "m") ? (<><Grid item xs={12} sm={4}>

                            {t('cnie_conjoint')}
                        </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="cnie_conjoint"
                                    value={cinConjoint || ""}
                                    onChange={(e)=>{setCinConjoint(e.target.value)}}
                                    name="cnie_conjoint"
                                    fullWidth
                                    error={cinConjointError}
                                    helperText={cinConjointError ? t('champ_obligatoire') : ' '}
                                    autoComplete="off"
                                    variant="outlined"
                                    dir={"ltr"}
                                />
                            </Grid>

                        </>) : (<> <Grid item xs={12} sm={6}>

                            {t('votre_pere_possede_cnie')}
                        </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch onChange={(event) => setCheckedCniPere(event.target.checked)}
                                                         checked={checkedCniPere} name="check_cin_pere"/>}/>
                                </FormGroup>
                            </Grid>

                            {checkedCniPere ? (<Grid item xs={12} sm={12} alignItems="center"
                                                     justifyContent="center">
                                <TextField
                                    required
                                    dir={"ltr"}
                                    id="cin_pere"
                                    value={cinPere || ""}
                                    error={cinPereError}
                                    helperText={cinPereError ? t('champ_obligatoire') : ' '}
                                    onChange={(e)=>{setCinPere(e.target.value)}}
                                    name="cin_pere"
                                    label={t("cni_pere")}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>) : <> <Grid item xs={12} sm={4} alignItems="center"
                                                justifyContent="center">
                                <TextField
                                    required
                                    dir={"ltr"}
                                    id="nom_pere"
                                    value={nomPere || "" }
                                    error={nomPereError}
                                    helperText={nomPereError ? t('champ_obligatoire_fr') : ' '}
                                    onChange={(e)=>{setNomPere(e.target.value)}}
                                    name="nom_pere"
                                    label={t("nom_pere")}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>
                                <Grid item xs={12} sm={4} alignItems="center"
                                      justifyContent="center">
                                    <TextField
                                        required
                                        dir={"ltr"}
                                        id="prenom_pere"
                                        value={prenomPere || ""}
                                        onChange={(e)=>{setPrenomPere(e.target.value)}}

                                        error={prenomPereError}
                                        helperText={prenomPereError ? t('champ_obligatoire_fr') : ' '}
                                        name="prenom_pere"
                                        label={t("prenom_pere")}
                                        fullWidth
                                        autoComplete="off"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} alignItems="center"
                                      justifyContent="center">
                                    <TextField
                                        required
                                        dir={"ltr"}
                                        id="ddn_pere"
                                        name="ddn_pere"
                                        value={ddnPere || ""}
                                        onChange={(e)=>{setDdnPere(e.target.value)}}
                                       error={ddnPereError}
                                        helperText={ddnPereError ? t('invalide_date') : ' '}
                                        label={t("ddn_pere")}
                                        fullWidth
                                        type="date"
                                        inputProps={{ max: "2006-01-01"}}
                                        InputLabelProps={{shrink: true}}
                                        autoComplete="off"
                                        variant="outlined"
                                    />
                                </Grid>


                            </>}
                            <Grid item xs={12} sm={6}>
                                {t('votre_mere_possede_cnie')}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch onChange={(event) => setCheckedCniMere(event.target.checked)
                                        } checked={checkedCniMere} name="check_cin_mere"/>}/>
                                </FormGroup>
                            </Grid>


                            {checkedCniMere ? (<Grid item xs={12} sm={12} alignItems="center"
                                                     justifyContent="center">
                                <TextField
                                    required
                                    dir={"ltr"}
                                    id="cin_mere"
                                    value={cinMere || ""}
                                    error={cinMereError}
                                    helperText={cinMereError ? t('champ_obligatoire') : ' '}
                                    onChange={(e)=>{setCinMere(e.target.value)}}
                                    name="cin_mere"
                                    label={t("cni_mere")}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>) : <> <Grid item xs={12} sm={4} alignItems="center"
                                                justifyContent="center">
                                <TextField
                                    required
                                    id="nom_mere"
                                    value={nomMere || ""}
                                    error={nomMereError}
                                    helperText={nomMereError ? t('champ_obligatoire_fr') : ' '}
                                    onChange={(e)=>{setNomMere(e.target.value)}}
                                    name="nom_mere"
                                    label={t("nom_mere")}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                    dir={"ltr"}
                                />
                            </Grid>
                                <Grid item xs={12} sm={4} alignItems="center"
                                      justifyContent="center">
                                    <TextField
                                        required
                                        dir={"ltr"}
                                        id="prenom_mere"
                                        value={prenomMere || "" }
                                        error={prenomMereError}
                                        helperText={prenomMereError ? t('champ_obligatoire_fr') : ' '}
                                        onChange={(e)=>{setPrenomMere(e.target.value)}}
                                        name="prenom_mere"
                                        label={t("prenom_mere")}
                                        fullWidth
                                        autoComplete="off"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} alignItems="center"
                                      justifyContent="center">
                                    <TextField
                                        required
                                        dir={"ltr"}
                                        id="ddn_mere"
                                        value={ddnMere || "" }
                                        onChange={(e)=>{setDdnMere(e.target.value)}}
                                        error={ddnMereError}
                                        helperText={ddnMereError ? t('invalide_date') : ' '}
                                        name="ddn_mere"
                                        label={t("ddn_mere")}
                                        fullWidth
                                        type="date"
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{ max: "2006-01-01"}}
                                        autoComplete="off"
                                        variant="outlined"
                                    />
                                </Grid>

                            </>}
                            <Grid item xs={12} sm={6}>
                                {t("votre_pere_decede")}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch onChange={(event) => setCheckedecedePere(event.target.checked)
                                        } checked={checkedecedePere} name="check_decede_pere"/>}/>
                                </FormGroup>
                            </Grid>
                            {checkedecedePere && (<>
                                    <Grid item xs={12} sm={3}>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            dir={"ltr"}
                                            id="ddn_deces_pere"
                                            value={DdnDecePere || "" }
                                            onChange={(e)=>{setDdnDecePere(e.target.value)}}
                                            name="ddn_deces_pere"
                                            label={t('ddn_deces_pere')}
                                            error={DdnDecePereError}
                                            helperText={DdnDecePereError ? t('invalide_date') : ' '}
                                            type="date"
                                            inputProps={{ max: "2024-01-01"}}
                                            InputLabelProps={{shrink: true}}
                                            autoComplete="off"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} sm={6}>
                                {t("votre_mere_decede")}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch onChange={(event) => setCheckedecedeMere(event.target.checked)
                                        } checked={checkedecedeMere} name="check_decede_mere"/>}/>
                                </FormGroup>
                            </Grid>

                            {checkedecedeMere && (<>
                                    <Grid item xs={12} sm={3}>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            dir={"ltr"}
                                            id="ddn_mere"
                                            value={DdnDeceMere || "" }
                                            onChange={(e)=>{setDdnDeceMere(e.target.value)}}
                                            name="ddn_deces_mere"
                                            label={t('ddn_deces_mere')}
                                            error={DdnDeceMereError}
                                            helperText={DdnDeceMereError ? t('invalide_date') : ' '}
                                            fullWidth
                                            type="date"
                                            inputProps={{max: "2024-01-01"}}
                                            InputLabelProps={{shrink: true}}
                                            autoComplete="off"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>

                                    </Grid>
                                </>
                            )}</>)}
                        {etatCivil === "d" && (<>
                            <Grid item xs={12} sm={4}>

                                {t('cnie_conjoint')}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="cnie_conjoint"
                                    name="cnie_conjoint"
                                    value={cinConjoint || ""}
                                    onChange={(e)=>{setCinConjoint(e.target.value)}}
                                    fullWidth
                                    error={cinConjointError}
                                    helperText={cinConjointError ? t('champ_obligatoire') : ' '}
                                    autoComplete="off"
                                    variant="outlined"
                                    dir={"ltr"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>

                                {t('date_divorce')}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="date_divorce"
                                    name="date_divorce"
                                    value={DdnDivorceConjoint  || ""}
                                    onChange={(e)=>setDdnDivorceConjoint(e.target.value)}
                                    fullWidth
                                    type="date"
                                    error={DdnDivorceConjointError}
                                    helperText={DdnDivorceConjointError ? t('invalide_date') : ' '}
                                    inputProps={{max: "2024-01-01"}}
                                    InputLabelProps={{shrink: true}}
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>
                        </>)}
                        {etatCivil === "v" && (<>
                            <Grid item xs={12} sm={4}>

                                {t('cnie_conjoint')}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="cnie_conjoint"
                                    value={cinConjoint || ""}
                                    onChange={(e)=>{setCinConjoint(e.target.value)}}
                                    name="cnie_conjoint"
                                    fullWidth
                                    error={cinConjointError}
                                    helperText={cinConjointError ? t('champ_obligatoire') : ' '}
                                    autoComplete="off"
                                    variant="outlined"
                                    dir={"ltr"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>

                                {t('date_deces_conjoint')}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="date_deces_conjoint"
                                    name="date_deces_conjoint"
                                    value={DdnDeceConjoint  || ""}
                                    onChange={(e)=>setDdnDeceConjoint(e.target.value)}
                                    fullWidth
                                    type="date"
                                    error={DdnDeceConjointError}
                                    helperText={DdnDeceConjointError ? t('invalide_date') : ' '}
                                    inputProps={{max: "2024-01-01"}}
                                    InputLabelProps={{shrink: true}}
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>
                        </>)}
                        <Grid item xs={12} sm={6}>
                            {t("benefficiez_vous_couverture_medicale")}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup>
                                <FormControlLabel control={<Switch onChange={(event) => setCheckeCouv(event.target.checked)
                                } checked={checkeCouv} name="check_couverture_medicale"/>}/>
                            </FormGroup>
                        </Grid>
                        {checkeCouv && (<Grid item xs={12} sm={12} textAlign={"center"}>
                            <Autocomplete
                                id="country-select-demo"
                                dir={"ltr"}
                                options={regimes_couv}
                                autoHighlight
                                getOptionLabel={(option) => option.libelle_assurance || ""}
                                onChange={(event, newValue) => {
                                    setRegimeCouvertureValue(newValue)
                                }}
                                value={regimeCouvertureValue}
                                isOptionEqualToValue={(option, tr) => option.value === tr.value}

                                renderOption={(props, option) => (
                                    <Box component="li"  {...props} key={option.code_assurance}>
                                        {option.libelle_assurance}
                                    </Box>

                                )}

                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t('regime_couverture')}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill

                                        }}

                                    />

                                )}
                            />
                        </Grid>)}
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid  style={{marginTop:14,marginBottom:14}}>
                            <InfoCndp />
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{mt: 3, ml: 1, textAlign: "center"}}
                                endIcon={<SendIcon/>}
                                dir={"ltr"}
                                disabled={loading}
                            >
                                {t('enregistrer')}
                            </Button>
                        </Grid>

                    </Grid>
                </Box>

                :
                <Box sx={{display: 'flex'}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid  style={{marginTop: 14, marginBottom: 14}}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Box>

            }
        </React.Fragment>
    );
}
