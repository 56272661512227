import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {BASE_URL, baseAxiosService, se_token, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import Alert from '@mui/material/Alert';
import styleamoe from "./styleamoe.css"
import {useTranslation} from "react-i18next";
import i18n from "../../../multilingue/i18n";
import {CircularProgress, Divider, ListItem} from "@mui/material";
import {isMobile} from "react-device-detect";
import Typography from "@mui/material/Typography";
import LogoTitle from "../../../images/logo_title.png";
import Avatar from "@mui/material/Avatar";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import DescriptionIcon from '@mui/icons-material/Description';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

import ListItemAvatar from "@mui/material/ListItemAvatar";
export default function AmoEtudiant() {

    const [loadingContent, setLoadingContent] = useState(true);
    const [etatDemande, setEtatDemande] = useState("");
    const [urlAttestation, setUrlAttestation] = useState("");
    const [msg, setMsg] = useState("");
    const {t} = useTranslation();
    const theme = createTheme();
    async function get_etat_amo() {
        try {
            await baseAxiosService.post(`${BASE_URL}/espace-etudiant/${Version}/get_etat_amo`)
                .then( res => {

                        if(res.data.params.html.length > 0){

                            setUrlAttestation(res.data.params.html)



                        } else if (res.data.status === 403) {
                            localStorage.clear();
                            window.location.href = "/login"

                        } else{
                            setEtatDemande(res.data.message.etat_demande)
                        }
                        setLoadingContent(false)


                })

        } catch (error) {
            console.error(error);
        }
    }
    const TelechargerPdf = () => {

        if (urlAttestation) {
            window.open(urlAttestation);
        }
    }


    useEffect(() => {

        get_etat_amo();

    }, []);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>

            {isMobile && (
                <Typography dir={i18n.language === "ar" ? "rtl" : "ltr "} variant={"h6"} fontFamily={"Open Sans"}
                            fontWeight={"bold"} color={"primary"}>
                    <HealthAndSafetyIcon /> {t("amo_etudiants")}
                </Typography>)}
            <Container maxWidth={isMobile ? "lg" : "md"} sx={{mb: 4, p: 0}}>

                <Paper
                    variant="outlined"
                    sx={{my: {xs: 1, md: 1},maxWidth:"1000px", p: {md: 3}}}

                >
                    <Grid container spacing={3} mt={1} dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                        {!isMobile ? <>  <Grid item align={i18n.language === "ar" ? "right" : "left"} xs={8} sm={8}>
                                <Typography variant={"h5"} fontFamily={"Open Sans"} fontWeight={"bold"} color={"primary"}>
                                    <HealthAndSafetyIcon/> {t("amo_etudiants")}
                                </Typography>

                            </Grid>
                                <Grid item align={i18n.language === "ar" ? "left" : "right"} xs={4} sm={4}>
                                    <img
                                        style={{width: 90, height: 60}}

                                        alt="Etablissement logo"
                                        src={LogoTitle}

                                    />
                                </Grid> </> :
                            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                                <Grid item align={"centre"} alignContent={"center"} xs={12} sm={12}>
                                    <Avatar
                                        sx={{width: 200, height: 60}}
                                        alt="Etablissement logo"
                                        src={LogoTitle}
                                        variant="square"

                                    />
                                </Grid>
                            </Grid>}
                    </Grid>
                    <Divider sx={{mt: 3, mb: 3}} variant="middle" color={"orange"}/>

                    { !loadingContent ?
                        (
                            <Grid container spacing={2} paddingX={2}>

                                { urlAttestation ? <div dangerouslySetInnerHTML={{__html: urlAttestation}} id={"div_amoe"}>

                                </div> :    <List>
                                    <ListItem
                                    >
                                        <ListItemAvatar>
                                                <Avatar style={{background: "success"}}> <ErrorOutlineIcon/> </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText
                                            primary={etatDemande  }

                                        />
                                    </ListItem>
                                </List>
                                }
                            </Grid>
                        ) : (  <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                                <CircularProgress/>
                            </Grid>
                        </Grid>)
                    }


                </Paper>
            </Container>
        </ThemeProvider>
    );
}
