import {React,useState} from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "../../images/logo_title.png";
import {useTranslation} from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
export default function ErrorPreinscription(props) {
    var classes = useStyles();
    const {t} =useTranslation();
    return (
        <Grid container className={classes.container}>

            <Paper classes={{ root: classes.paperRoot }}>
                <div className={classes.logotype}>
                    <img className={classes.logotypeIcon} src={logo} alt="logo"    style={{width: "100%" , textAlign:"center",
                    }}
                    />
                </div>
                <Typography
                    variant="h1"
                    color="primary"
                    className={classnames(classes.textRow, classes.errorCode)}
                >
                    <InfoIcon fontSize={"16"}/>
                </Typography>
                <Typography variant="h3" color="primary" className={classes.textRow} >
                    {t('title_error_pre')}


                </Typography>
                <Typography
                    variant="h5"

                    className={classnames(classes.textRow, classes.safetyText)}
                    color="error"

                >
                    {<b>{t('error_preinscription')}</b> }

                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/"
                    size="small"
                    className={classes.backButton}
                >
                    {t('retour')}
                </Button>
            </Paper>
        </Grid>
    );
}
