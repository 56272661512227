import Box from '@mui/material/Box';

import {createTheme, ThemeProvider} from '@mui/material/styles';
import React  from "react";
import Container from "@mui/material/Container";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import CssBaseline from "@mui/material/CssBaseline";

import i18n from "../../multilingue/i18n";

import {Copyright} from "../../context/UserContext";
import {isMobile} from "react-device-detect";

import {useHistory} from "react-router-dom/cjs/react-router-dom";
import Typography from "@mui/material/Typography";

const theme = createTheme();

export default function Privicy() {

    const history = useHistory();
    return (
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth={isMobile ? "xs" : "md"}>
                    <Stack spacing={2} direction="row">
                        <Button variant={"contained"} color={"warning"} onClick={()=>{history.push('/')}} sx={{ textAlign: "left",mt:5}} size="medium" >
                            <HomeIcon />
                        </Button>
                    </Stack>

                    <CssBaseline/>
                    <Box
                        sx={{

                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography component="h3" fontWeight={"bold"} variant="h5" color="primary" textAlign={"center"} marginTop={4}>
                            Politique de confidentialité - ENSMR: Collecte, utilisation et divulgation de vos informations personnelles
                        </Typography>
                        <Typography   color="orange" textAlign={"justify"} component={"h6"} marginTop={2}> Politique de confidentialité - ENSMR</Typography>
                        <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}> Par le biais de ce formulaire, l'ENSMR collecte vos données personnelles en vue de la gestion de la scolarité. Ce traitement a fait l'objet d'une demande d'autorisation auprès de la Commission Nationale de Contrôle de la Protection des Données à Caractère Personnel (CNDP) sous le numéro .....</Typography>
                        <Typography   color="orange" textAlign={"justify"} component={"h6"} marginTop={2}> Utilisation des informations personnelles</Typography>
                        <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}> Les informations personnelles collectées sont utilisées dans le but de :</Typography>

                          <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}>   - Gérer votre scolarité et vous fournir les services associés.</Typography>
                           <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}>  - Communiquer avec vous concernant les mises à jour de l'espace des étudiants, les annonces importantes et les événements liés à l'ENSMR.</Typography>
                          <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}>   - Répondre à vos demandes de renseignements et de support.</Typography>
                           <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}>  - Assurer le respect des règles et réglementations en vigueur.</Typography>
                        <Typography   color="orange" textAlign={"justify"} component={"h6"} marginTop={2}> Divulgation des informations personnelles </Typography>
                      <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}>   Vos informations personnelles peuvent être partagées avec les entités suivantes :</Typography>

                      <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}>   - Le ministère de l'enseignement supérieur, de la recherche scientifique et de l'innovation, conformément aux obligations légales.</Typography>
                      <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}>   - La Caisse Nationale des Organismes de Prévoyance Sociale (CNOPS) et la Caisse Nationale de Sécurité Sociale (CNSS) pour la gestion de votre couverture médicale.</Typography>
                        <Typography   color="orange" textAlign={"justify"} component={"h6"} marginTop={2}> Vos droits</Typography>
                        <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} marginTop={1}> Conformément aux dispositions de la loi 09-08 sur la protection des données à caractère
                            personnel, vous avez le droit d'accéder à vos informations personnelles, de les rectifier et de vous opposer à leur utilisation. Pour exercer ces
                            droits ou pour toute question ou préoccupation concernant notre politique de confidentialité, veuillez nous contacter à  </Typography>
                        <Typography   color="gray" textAlign={"justify"} fontSize={"14px"} fontWeight={"bold"} marginTop={1}>inscription@enim.ac.ma</Typography>
                        <Typography   color="orange" textAlign={"justify"} component={"h6"} marginTop={2} >  Modifications de la politique de confidentialité </Typography>
                        <Typography   color="gray" textAlign={"justify"} fontSize={"14px"}  marginTop={1}>  Nous nous réservons le droit de mettre à jour ou de modifier cette politique de confidentialité à tout moment. Les modifications prendront effet dès leur publication sur cette page.
                            Veuillez consulter régulièrement cette page pour prendre connaissance des éventuelles mises à jour.</Typography>
                        <Copyright sx={{mt: 4, mb: 4}}/>
                    </Box>
                </Container>

            </ThemeProvider>


    );
}