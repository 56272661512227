import React, {useState,useEffect} from "react";
import {
    Grid,

    Typography,
    Button,
} from "@material-ui/core";
import {useHistory, withRouter} from "react-router-dom";
import AppMobileStore from "../../components/Widget/AppMobileStore/AppMobileStore";

import DialogActions from '@mui/material/DialogActions';

// styles
import useStyles from "./styles";

// logo
import logo from "../../images/logo_title.png";
import PlayStoreApp from "../../images/app_play_store.png"
// context
import {Alert, Dialog} from "@mui/material";
import i18n from "../../multilingue/i18n";
import DialogContent from '@mui/material/DialogContent';
import Flag from 'react-world-flags'
import {useTranslation} from "react-i18next";
import LogoTitle from "../../images/logo_title.png";

import {isMobile} from "react-device-detect";
import HelpEtud from "../../components/Widget/HelpEtud"
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {Close} from "@material-ui/icons";
import SendIcon from "@mui/icons-material/Send";
import {BASE_URL, baseAxios, Version} from "../../repository/baseAxios";
import Swal from "sweetalert2";

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser, faCoffee, faPaperPlane, faUserCheck, faUserPlus} from "@fortawesome/free-solid-svg-icons";



function Login(props) {
    var classes = useStyles();
    var history = new useHistory();
    // local
    var [open, setOpen] = useState(true);
    var [openPassword, setOpenPassword] = useState(false);

    const [carteSejourCin, setCarteSejourCin] = useState(false);
    const [carteSejourCinError, setCarteSejourCinError] = useState(false);
    const [DataErreurp, setDataErreurp] = useState(false)
    const [MsgErreurp, setMsgErreurp] = useState('Erreur !')
    const [loadingForgetPassord, setLoadingForgetPassord] = useState(false);
    const [codeMassarErrorp, setCodeMassarErrorp] = useState(false)
    const [codeMassarp, setCodeMassarp] = useState("")
    const [ddnp, setDdnp] = useState("")
    const [ddnErrorp, setDdnErrorp] = useState(false)
    const [anneeBac,setAnneBac]=useState("");
    const [anneeBacError,setAnneBacError]=useState(false);
    let {t} = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);

        setOpen(false);
        localStorage.setItem('changelange',lang)


    }
    var changelangue=  localStorage.getItem('changelange')

    useEffect(() => {
            localStorage.setItem('changelange','fr')
        if (!changelangue){
            localStorage.setItem('changelange','fr')

        }else {
            let  langi18=  localStorage.getItem('changelange')

            i18n.changeLanguage(langi18);

        }
            let token = localStorage.getItem('token')

            return () => {
                if(token ){
                    window.location.href="#/app"
                }
            };
        },
        []);
    const handlformsubmit = () => {
        if (codeMassarp === '') {
            setCodeMassarErrorp(true)
            return false

        }
        if (carteSejourCin === '') {
            setCarteSejourCinError(true);
            return false;
        }
        if (ddnp === '') {
            setDdnErrorp(true)
            return false
        }
        if (anneeBac === '') {
            setAnneBacError(true)
            return false
        }
        setLoadingForgetPassord(true)
        let  checkData=new FormData();
        checkData.set('code_massar',codeMassarp)
        checkData.set('carte_national',carteSejourCin)
        checkData.set('date_naissance',ddnp)
        checkData.set('annee_bac',anneeBac)

        reset_password_and_get_email_academic(checkData);
    }
    const reset_password_and_get_email_academic = (params) => {

        baseAxios.post(`${BASE_URL}/espace-etudiant/${Version}/reset_password_and_get_email_academic`, params)
            .then(res => {
                if (res.data.status === 200 && res.data.params.email && res.data.params.password ) {
                    setOpenPassword(false)
                    Swal.fire({
                        icon: 'success',
                        title: ' ',
                        html : `<h2>Email académique:<span style ="color:red;">${res.data.params.email}</span> </h2><h3>Mot de passe:<span style ="color:red;"> ${res.data.params.password} </span></h3>`,
                        showConfirmButton: true,
                    })
                    setLoadingForgetPassord(false)
                } else if (res.data.status === -1) {
                    setDataErreurp(true)
                    setMsgErreurp(res.data.message)

                    setLoadingForgetPassord(false)
                    return false;
                }

            })
    }
    return (
        <Grid container className={classes.container}>

            <Box component="form"  spacing={3} >
                <Grid container spacing={3}>
                    <Dialog
                        style={{padding:10}}
                        open={openPassword}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent style={{width : isMobile ? "" : "600px" }}>
                            {DataErreurp ? <Alert variant="filled" severity="error">
                                {MsgErreurp}
                            </Alert> : ("")

                            }
                            <Grid item xs={12} sm={12} >

                                <TextField
                                    required
                                    style={{marginTop:10}}

                                    value={codeMassarp}
                                    onChange={(e) => {
                                        setCodeMassarp(e.target.value)
                                    }}
                                    error={codeMassarErrorp}
                                    helperText={codeMassarErrorp ? t('champ_obligatoire') : ' '}

                                    fullWidth
                                    id="code_massar"
                                    label={t('code_massar_cne')}
                                    name="code_massar"
                                    autoComplete="off"
                                    autoFocus

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <TextField
                                    required
                                    id="carte_sejour"
                                    name="carte_sejour"
                                    label={t('carte_de_sejour_cin')}
                                    value={carteSejourCin || ""}
                                    onChange={(e) => {
                                        setCarteSejourCin(e.target.value)
                                    }}
                                    error={carteSejourCinError}
                                    helperText={carteSejourCinError ? t('champ_obligatoire') : ' '}
                                    fullWidth
                                    autoComplete="off"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} >

                                <TextField
                                    required
                                    style={{marginTop:10}}
                                    id="date_naissance"
                                    value={ddnp}
                                    onChange={(e) => {
                                        setDdnp(e.target.value)
                                    }}
                                    label={t('date_naissance')}
                                    fullWidth
                                    type={"date"}
                                    InputLabelProps={{ shrink: true }}
                                    autoComplete="off"
                                    variant="outlined"
                                    helperText={ddnErrorp ? t('champ_obligatoire') : ' '}
                                    error={ddnErrorp}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    dir={'ltr'}
                                    id="anneeBac"
                                    label={t('annee_bac')}
                                    value={anneeBac || ""}
                                    onChange={(e) => {
                                        setAnneBac(e.target.value)
                                    }}
                                    helperText={anneeBacError ? t('champ_obligatoire') : ' '}
                                    error={anneeBacError}
                                    fullWidth
                                    autoComplete={"off"}
                                    variant="outlined"

                                />
                            </Grid>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                                    endIcon={<Close />}
                                    onClick={()=>{ setOpenPassword(false)}}
                                    style={{background:"#007fa4"}}
                                    size="medium"

                                >
                                    {t('fermer')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    type="submit"
                                    style={{ marginLeft: 15, textAlign: "center"}}
                                    endIcon={<SendIcon/>}
                                    onClick={handlformsubmit}
                                    disabled={loadingForgetPassord}
                                >
                                    {t('envoyer')}
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Box>

            <div className={classes.formContainer+' center'} >


                    <Grid container >
                        <Grid   md={12}  sm={12} xs={12}  className={classes.loginGrid}>
                            <div className={classes.logotype} style={{ textAlign:"center"}}>
                                <img className={classes.imageLogo } src={logo} alt="logo"  style={{ textAlign:"center",}}
                                />
                            </div>
                        </Grid>
                        <Grid   md={6}  sm={12} xs={12}   className={classes.loginGrid}>
                            <div className={classes.formButtons}>

                                <Button
                                    onClick={() =>
                                        history.push('/prienscription')
                                    }
                                    variant="contained"
                                    color="#b7b1b1"
                                    size="large"
                                    fullWidth
                                    style={{background:"#007fa4"}}
                                >
                                    <div className={classes.divWith} >
                                        <FontAwesomeIcon icon={faUserPlus}  fontSize={'3em'}/>
                                        <br/>
                                        <b>  {t('acceder_a_la_plateforme_de_nv_inscription')}</b>
                                            <div className={classes.formDivider2}/>
                                            <div className={classes.formDivider2}/>
                                        <span style={{fontSize:10}}>
                                      <b > {t('acceder_a_la_plateforme_de_nv_inscription_txt')}  </b><br/>
                                  </span>
                                    </div>
                                </Button>

                            </div>
                        </Grid>
                        <Grid   md={6} sm={12} xs={12}  className={classes.loginGrid}>
                            <div className={classes.formButtons}>
                                <Button
                                    onClick={() =>
                                        history.push('/PersonnelLogin')
                                    }
                                    variant="contained"
                                    style={{background:"#007fa4"}}
                                    size="large"
                                    fullWidth
                                    classes={classes.formBtn}
                                >
                                    <div className={classes.divWith} >
                                        <FontAwesomeIcon icon={faUserCheck}  fontSize={'3em'}/>
                                        <br/>
                                        <b>  {t('reinscription')}</b>
                                        <div className={classes.formDivider2}/>
                                        <div className={classes.formDivider2}/>
                                        <span style={{fontSize:10}}>
                                      <b > {t('centre_de_reinscription')}  </b><br/>
                                  </span>
                                    </div>
                                </Button>

                            </div>
                        </Grid>
                        <Grid   md={6} sm={12} xs={12}  className={classes.loginGrid}>
                            <div className={classes.formButtons}>
                                <Button
                                    onClick={() =>
                                        history.push('/candidature/login')
                                    }

                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    style={{background:"#007fa4"}}
                                >
                                        <div className={classes.divWith} >
                                            <FontAwesomeIcon icon={faPaperPlane}  fontSize={'3em'}/>
                                            <br/>
                                            <b>  {t('acceder_a_la_plateforme_de_candidature_en_ligne')}</b>
                                            <div className={classes.formDivider2}/>
                                            <div className={classes.formDivider2}/>
                                            <span style={{fontSize:10}}>
                                      <b > {t('centre_de_candidature')}  </b><br/>
                                  </span>
                                        </div>


                                </Button>

                            </div>
                        </Grid>
                        <Grid   md={6}  sm={12} xs={12}   className={classes.loginGrid}>
                            <div className={classes.formButtons}>
                                <Button
                                    onClick={() =>
                                        history.push('/PersonnelLogin')
                                    }
                                    variant="contained"
                                    color="default"
                                    size="large"
                                    fullWidth
                                    style={{background:"#007fa4"}}
                                >


                                    <div className={classes.divWith} >
                                        <FontAwesomeIcon icon={faCircleUser}  fontSize={'3em'}/>
                                        <br/>
                                        <b>  {t('service_en_ligne')}</b>
                                        <div className={classes.formDivider2}/>
                                        <div className={classes.formDivider2}/>
                                        <span style={{fontSize:10}}>
                                      <b > {t('espace_numerique')}  </b><br/>
                                  </span>
                                    </div>


                                </Button>


                            </div>
                        </Grid>
                        <Grid   md={12} sm={12} xs={12}  className={classes.loginGrid}>
                            <div className={classes.formDividerContainer}>
                                <div className={classes.formDivider2}/>
                            </div>
                        </Grid>
                        <Grid   md={12} sm={12} xs={12}  className={classes.loginGrid}>
                            <div className={classes.formButtons}>
                                <Button
                                    onClick={() =>
                                        setOpenPassword(true)
                                    }
                                    variant="contained"
                                    style={{background:"#179bd7"}}
                                    size="large"
                                    fullWidth
                                >
                                    <div className={classes.divWith} >
                                        <b>  {t('recuperation_compte_academique')}</b><br />

                                    </div>
                                </Button>
                            </div>
                        </Grid>

                        <Grid   md={12} sm={12} xs={12} >
                        <HelpEtud  />
                        </Grid>

                    </Grid>



                <Typography color="primary" className={classes.copyright}>
                    © {new Date().getFullYear()} - {new Date().getFullYear()+1} <a style={{textDecoration: 'none', color: 'inherit'}}
                                                         href="https://enim.ac.ma" rel="noopener noreferrer"
                                                         target="_blank">{ localStorage.getItem('entity_abrv_name')}</a>. All rights reserved.
                </Typography>
            </div>

        </Grid>
);
}

export default withRouter(Login);
