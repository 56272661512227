import React from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "../../images/logo_title.png";
import {useTranslation} from "react-i18next";

export default function Error() {
  var classes = useStyles();
    const {t} =useTranslation();
  return (
    <Grid container className={classes.container}>

      <Paper classes={{ root: classes.paperRoot }}>
        <div className={classes.logotype}>
          <img className={classes.logotypeIcon} src={logo} alt="logo"    style={{width: "100%" , textAlign:"center",
          }}
          />
        </div>
        <Typography
          variant="h1"
          color="primary"
          className={classnames(classes.textRow, classes.errorCode)}
        >
          404
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          Le service demandé sera disponible prochainement
        </Typography>
        <Typography
          variant="h6"
          color="text"
          colorBrightness="secondary"
          className={classnames(classes.textRow, classes.safetyText)}
        >
          الخدمة المطلوبة ستكون متاحة قريبا
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          size="large"
          className={classes.backButton}
        >
          {t('retour')}
        </Button>
      </Paper>
    </Grid>
  );
}
