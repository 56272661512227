import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import  translationEN from "../multilingue/locales/en/translation.json"
import  translationFR from "../multilingue/locales/fr/translation.json"
import  translationAR from "../multilingue/locales/ar/translation.json"

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation:translationEN
    },
    fr: {
        translation:translationFR

    },
    ar: {
        translation:translationAR

    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "fr",
        keySeparator : false,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;