import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import {BASE_URL, baseAxiosService, Version} from "../../../repository/baseAxios";
import CompleteInfo from "../CompleteInfo"
import CardHeader from '@mui/material/CardHeader';
import {isMobile} from 'react-device-detect';
import CardContent from '@mui/material/CardContent';
import Swal from "sweetalert2";
import SendIcon from '@mui/icons-material/Send';
import {useTranslation} from "react-i18next";
import {Alert, AlertTitle, CircularProgress} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ModalPolicy from "../../../components/Widget/Cndp/ModalPolicy";
import i18n from "../../../multilingue/i18n";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Link from "@mui/material/Link";

export default function ReinChoixFilieres(props) {
    const [loading, setloading] = useState(false);
    const [loadingContent, setloadingContent] = useState(true);
    const [DataErreurCndp, setDataErreurCndp] = useState(false)
    const [MsgErreurCndp, setMsgErreurCndp] = useState('Erreur !');
    const [AucunReiInscription, setAucunReiInscription] = useState(true);
    const [infoEncadrant, setInfoDoctorat] = useState("");
    const [infoIpDoc, setInfoIpDoc] = useState("");
    const [infoSDoc, setInfoSDoc] = useState("");
    const [aucunDoctorat, setAucunDoctorat] = useState(false);


    const [etablissements, setEtablissements] = useState(false);
    const [value, setValue] = React.useState('');
    const [doctorat, setDoctorat] = React.useState(false);
    const [lienEtatAvc, setLienEtatAvc] = useState("");
    const [Nvdoctorat, setNvdoctorat] = useState(0);
    const {t} = useTranslation();
    // local
    const add_reisinscription = (params) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/add_reisinscription`, params)

            .then(res => {
                if (res.data.status === 200) {
                    let dataM = new FormData();
                    dataM.set('type', "reinscription")
                    get_registration_RI(dataM)
                    Swal.fire({
                        title: t('step_1'),
                        icon: 'success',
                        confirmButtonText: t('confirmer'),
                        confirmButtonColor: 'green',
                        color: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            props.EtapeSuivant();
                        }
                    })

                    setloading(false)
                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merci de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setloading(false)

                    props.EtapeSuivant();

                } else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                } else {
                    setloading(false)
                }
                setloading(false)


            })
    }
    const info_doc = (param) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/info_doc`)
            .then(res => {
                if (res.data.status === 200) {
                    if ( res.data.params.ip ) {
                        if(res.data.params.encadrant.nom && res.data.params.sujet_doc ){
                            setloading(false);
                            let niveau= res.data.params.niveau;
                            setInfoDoctorat(res.data.params.encadrant.nom);
                            setInfoIpDoc(res.data.params.ip)
                            setInfoSDoc(res.data.params.sujet_doc)
                            setDoctorat(true)


                            if (niveau>0 && niveau<=3){
                                setLienEtatAvc('https://ent.enim.ac.ma/dw/doc/reinsc/part-ufx-1.docx')
                            }else if(niveau>3 && niveau<=5){
                                setLienEtatAvc('https://ent.enim.ac.ma/dw/doc/reinsc/part-yth-2.docx')

                            }else if(niveau>5){
                                setLienEtatAvc('https://ent.enim.ac.ma/dw/doc/reinsc/part-uaj-3.docx')
                            }

                            setNvdoctorat(niveau)
                            setloadingContent(false)
                        } else {

                            setAucunReiInscription(true)
                            setDoctorat(false)
                            setAucunDoctorat(true);
                            setloadingContent(false)
                        }
                        }else{
                        setloadingContent(false)
                        setAucunReiInscription(true)
                        setDoctorat(false)
                        setAucunDoctorat(true);
                        setloadingContent(false)
                    }


                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: 'ERROR !',
                        text: 'Merci de réssayée au plus tard !',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                } else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                } else {
                    setloadingContent(false)
                    setAucunReiInscription(true)
                }


            }).catch(function (error) {
            if (error.response) {
                localStorage.clear();
                window.location.href = "/login"
            }
        })
    }
    const authorized_reinscription_RI = (param) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/authorized_reinscription`)

            .then(res => {
                if (res.data.status === 200 ) {
                    if (res.data.params.length > 0){
                        const allfilieres = res.data.params;
                        setEtablissements(allfilieres)
                        setAucunReiInscription(false)

                        allfilieres.forEach((e) => {
                            Object.values(e.filieres).forEach((f, indexf) => {
                                if (f.doctorat===true) {
                                    setloadingContent(true)
                                    setloading(true)
                                    info_doc();
                                }
                            })
                        })
                            }
                    }

                else if (res.data.status === -1) {

                    Swal.fire({
                        title: 'ERROR !',
                        text: 'Merci de réssayée au plus tard !',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                } else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                }


            }).catch(function (error) {
            if (error.response) {
                localStorage.clear();
                window.location.href = "/login"
            }
        })
    }
    const [checked, setChecked] = React.useState(false);
    const [openCndp, setOpenCndp] = useState(false);
    const [reinsFiliere, setReinsFiliere] = useState("");
    const [demandeFile, setDemandeFile] = useState("");
    const [bourseFile, setBourseFile] = useState("");
    const [rapportFile, setRapportFile] = useState("");
    const [checkedBourse, setCheckedBourse] = React.useState(true);
    const acceptCndpBeforeLogin = () => {
        setDataErreurCndp(false)
        setMsgErreurCndp("")
        if (checked) {
            let cndpData = new FormData();
            cndpData.set('accept_cndp', "yes");
            accept_cndp(cndpData);
        } else {
            setDataErreurCndp(true);
            setMsgErreurCndp("Errur ! Accepter   les conditions générales d'utilisation")
        }
    }
    const accept_cndp = (params) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/accept_cndp`, params)

            .then(res => {

                if (res.data.status === 1) {

                    localStorage.setItem('cndp', 'yes')
                    setOpenCndp(false)


                } else if (res.data.status === -1) {
                    setDataErreurCndp(true)
                    setMsgErreurCndp(res.data.message)

                    return false;
                } else {
                    setOpenCndp(false)
                    Swal.fire({
                        title: "Erreur !",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    setloading(false);

                }


            }).catch(function (error) {
            if (error.response) {
                localStorage.clear();
                window.location.href = "/login"
            }
        })
    }
    const get_registration_RI = (params) => {
        baseAxiosService.post(`${BASE_URL}/inscription/${Version}/etudiant/get_registration`, params)

            .then(res => {

                if (res.data.status === 200  ) {
             if(res.data.params.ips && res.data.params.ips.length > 0){
                 setAucunReiInscription(true);
                 setReinsFiliere(res.data.params.ips);
                 setloadingContent(false);
             }else {
                 setloadingContent(false);
             }


                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur ",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                } else if (res.data.status === 403) {
                    localStorage.clear();
                    window.location.href = "/login";
                }
            }).catch(function (error) {
            if (error.response) {
                localStorage.clear();
                window.location.href = "/login"
            }
        })
    }
    useEffect(() => {

        let accept_cndp = localStorage.getItem("cndp");


        if (accept_cndp !== "yes") {
            setOpenCndp(false);
        }


        let isMounted = true;


        if (isMounted) {
            let data = new FormData();
            data.set('type', "reinscription")
            setTimeout(()=>{
                authorized_reinscription_RI();
                get_registration_RI(data);
            },500)
        }

    }, []);
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleChangeBourse = (event) => {
        setCheckedBourse(event.target.checked)
    }
    const handlchangeBourseFile = (event) => {
        let file = event.target.files[0];
        let fileName = document.querySelector('#bourse').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setBourseFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setBourseFile("")

        }


    }

    const handlchangerapportFile = (event) => {
        let file = event.target.files[0];
        let fileName = document.querySelector('#rapporte').value;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (extension === "pdf") {
            setRapportFile(file)
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un fichier PDF",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            setRapportFile("")

        }


    }
    const handlformsubmit = (event) => {
        event.preventDefault();
        setloading(true)
        if (etablissements && value) {
            etablissements.forEach((e) => {
                Object.values(e.filieres).forEach((f, indexf) => {
                    if (value === f.filiere_token && f.filiere_token) {
                        let data = new FormData();
                        data.set("token_filiere", f.filiere_token)
                        data.set("id_etudiant_opi_opi_etab", f.id_etudiant_opi_etab)
                        Object.values(f.bacs).forEach((b, indexf) => {
                            data.set("id_opi_bac", b.id_opi)
                        })
                        if (doctorat) {
                            if(infoSDoc.token_sujet_doc){
                                data.set('token_sujet_doc',infoSDoc.token_sujet_doc)
                            }else{
                                Swal.fire({
                                    title: "Erreur ! ",
                                    text: " sujet Introuvable",
                                    icon: 'error',
                                    confirmButtonText: 'Confirmer',
                                    confirmButtonColor: 'red',
                                    color: 'red'
                                })
                            }
                            if (checkedBourse) {
                                if (bourseFile) {
                                    data.set("file_attestation_non_travail", bourseFile)
                                } else {
                                    Swal.fire({
                                        title: "Erreur ! ",
                                        text: "merci d'importer l'attestation de non travail !",
                                        icon: 'error',
                                        confirmButtonText: 'Confirmer',
                                        confirmButtonColor: 'red',
                                        color: 'red'
                                    })
                                    setloading(false)
                                    return false;
                                }
                            }

                            if (rapportFile) {
                                data.set("file_rapport_avancement", rapportFile)
                            } else {
                                Swal.fire({
                                    title: "Erreur ! ",
                                    text: "merci d'importer le rapporte d'avancement !",
                                    icon: 'error',
                                    confirmButtonText: 'Confirmer',
                                    confirmButtonColor: 'red',
                                    color: 'red'
                                })
                                setloading(false)
                                return false;
                            }
                        }
                        add_reisinscription(data)
                    }
                })
            })
        } else {
            Swal.fire({
                title: "Erreur ! ",
                text: t('erreur_filiere'),
                icon: 'error',
                confirmButtonText: t('retour'),
                confirmButtonColor: 'red',
                color: 'red'
            })
            setloading(false)
        }
    }

    return (
        <React.Fragment>
            {!loadingContent ?
                <Box component="form" container spacing={3} onSubmit={handlformsubmit}>
                    <Grid
                        container
                        spacing={2}
                        marginTop={2}
                        marginBottom={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        dir={i18n.language === "ar" ? "rtl" : "ltr "}
                    >
                        {(!AucunReiInscription && !reinsFiliere ) ?
                            (<>
                                    {doctorat && (
                                        <>
                                            <Grid item xs={12} sm={12} dir={"ltr"}>
                                            <Alert variant="filled"
                                                   sx={{fontSize: 18, bgcolor: "#007fa4", textTransform: "uppercase"}}
                                            >
                                                <AlertTitle
                                                    sx={{fontSize: 18}}><strong
                                                    color={"Warning"}>{infoIpDoc.libelle_filiere}</strong></AlertTitle>
                                                <strong> Directeur de thèse </strong> :
                                                <strong style={{marginBottom: 3}}> {infoEncadrant}  </strong><br/>
                                                <strong> Sujet de thèse
                                                    :<Typography> {infoSDoc.intitule_sujet} </Typography> </strong><br/>

                                            </Alert>

                                        </Grid>


                                            <Grid item xs={12} sm={12}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                           checked={checkedBourse}
                                                                           onChange={handleChangeBourse}
                                                                           inputProps={{'aria-label': 'controlled'}}

                                                        />}
                                                        sx={{
                                                            "& .MuiFormControlLabel-label": {
                                                                fontSize: 20
                                                            }
                                                        }}
                                                        label={t('possedez_bourse')}/>
                                                </FormGroup>
                                            </Grid>
                                            {(checkedBourse) && (<>   <Grid item xs={12} sm={4}>
                                                    <Typography fontSize={20}>
                                                        {t("certificat_chomage")}
                                                    </Typography>
                                                </Grid><Grid item xs={12} sm={6}>

                                                    <TextField
                                                        onChange={handlchangeBourseFile}
                                                        id="bourse"
                                                        fullWidth
                                                        autoComplete="given-name"
                                                        variant="filled"
                                                        type={"file"}
                                                        inputProps={{accept: ".pdf"}}

                                                    />

                                                </Grid> <Grid item xs={12} sm={2}>

                                                </Grid></>

                                            )}


                                            <Grid item xs={12} sm={4}>
                                                <Typography fontSize={20}>
                                                    {t("fichie_rapport")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    onChange={handlchangerapportFile}
                                                    id="rapporte"
                                                    name="rapporte"
                                                    fullWidth
                                                    autoComplete="given-name"
                                                    variant="filled"
                                                    type={"file"}
                                                    inputProps={{accept: ".pdf"}}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>

                                            </Grid>
                                        </>)}
                                    {(etablissements )  && (etablissements.map((e, index) => {
                                        return (<> <Grid item xs={12} key={index}>
                                            {
                                                !isMobile ? (<CardHeader dir={"ltr"}
                                                                         style={{textTransform: 'uppercase'}}
                                                                         avatar={
                                                                             <Avatar
                                                                                 style={{float: "right"}}
                                                                                 sx={{
                                                                                     width: 200, height: 56,

                                                                                     "& .css-1pqm26d-MuiAvatar-img": {
                                                                                         objectFit: "contain"
                                                                                     }

                                                                                 }}
                                                                                 alt="Etablissement logo"
                                                                                 src={e.etablissement.logo}
                                                                                 variant="square"
                                                                             />
                                                                         }
                                                                         title={e.etablissement.slug}
                                                                         subheader={e.etablissement.name}


                                                />) : (<CardContent sx={{flex: '1 0 auto'}} dir={"ltr"}>
                                                    <Typography component="div" variant="h5">
                                                        <Avatar
                                                            sx={{
                                                                width: 200, height: 56,
                                                                "& .css-1pqm26d-MuiAvatar-img": {
                                                                    objectFit: "contain"
                                                                }

                                                            }}

                                                            alt="Remy Sharp"
                                                            src={e.etablissement.logo}
                                                            variant="square"
                                                        />
                                                    </Typography>
                                                    <Typography variant="h6" component="div">

                                                        {e.etablissement.name}

                                                    </Typography>
                                                </CardContent>)
                                            }


                                        </Grid>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                    value={value}
                                                    onChange={handleChange}
                                                >
                                                    {e.filieres && (Object.values(e.filieres).map((f, indexf) => {
                                                            if (f.filiere_token) {
                                                                return (
                                                                    <FormControlLabel value={f.filiere_token}
                                                                                      key={indexf+1}
                                                                                      control={<Radio sx={{
                                                                                          '& .MuiSvgIcon-root': {
                                                                                              fontSize: 28,
                                                                                          },
                                                                                          '& .css-ahj2mt-MuiTypography-root': {
                                                                                              fontSize: "1.2 rem"
                                                                                          }
                                                                                      }}
                                                                                      />
                                                                                      } label={f.libelle_filiere}/>
                                                                )
                                                            }
                                                        }
                                                    ))
                                                    }
                                                </RadioGroup>
                                            </FormControl>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                alignContent="center"
                                                style={{textAlign: "center"}}
                                            >
                                                <Button variant="contained" disabled={loading}
                                                        type={"submit"}>{t('enregistrer')}</Button>
                                            </Grid>
                                        </>)
                                    }))
                                    }

                                </>
                            ) : (<>
                                    {Object.values(reinsFiliere).map((r, index) => {
                                        return (
                                            <Grid item xs={12} sm={12} key={index}>
                                                <Alert
                                                    variant="filled"
                                                    sx={{fontSize: 18, bgcolor: "#007fa4", textTransform: "uppercase"}}
                                                >
                                                    <AlertTitle sx={{fontSize: 18}}>
                                                        <strong>{r.filiere.libelle_filiere}</strong>
                                                    </AlertTitle>
                                                    <strong>{r.etablissement.abreviation_etablissement} </strong> —
                                                    <strong>
                                                        {r.diplome.libelle_diplome_fr} : {r.annee_inscription} <br/>
                                                    </strong>
                                                </Alert>
                                            </Grid>
                                        )
                                    })}

                                    {reinsFiliere && (<Button
                                        variant="contained"
                                        type="submit"
                                        sx={{mt: 3, mb: 3, ml: 1, textAlign: "center"}}
                                        endIcon={<SendIcon/>}
                                        onClick={() => props.EtapeSuivant()}
                                    >
                                        {t('suivant')}
                                    </Button>)}
                                </>
                            )
                        }
                        {
                            ( !reinsFiliere && AucunReiInscription) && ( <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                                {(!aucunDoctorat)  ?(<>    <Grid item xs={12} sm={12} style={{
                                marginTop: 14,
                                background: "#FDEDED",
                                textAlign: "center",
                                padding: 13,
                                marginBottom: 14
                            }}>
                                <ErrorOutlineIcon sx={{
                                    color: 'red', fontSize: 50
                                }}/>
                                    <Typography variant={"h5"}> Aucune Réinscription n'est
                                    <strong> disponible !</strong>
                                    </Typography>      </Grid> </>):(

                                <CompleteInfo />
                                )}
                        </Grid> )}
                    </Grid>
                </Box>
                :
                <Box sx={{display: 'flex'}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Box>
            }
            <Box component="form" spacing={3}>
                <Grid container spacing={3}>

                    <Dialog
                        style={{padding: 10}}
                        open={openCndp}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >

                        <DialogTitle id="alert-dialog-title">
                            les conditions générales d'utilisation
                        </DialogTitle>
                        <DialogContent>
                            {DataErreurCndp ? <Alert variant="filled" severity="error">
                                {MsgErreurCndp}
                            </Alert> : ("")

                            }
                            <Grid item xs={12} sm={12} textAlign={"center"}>
                                <FormControlLabel
                                    value="top"
                                    label={""}
                                    style={{marginTop: 7, marginBottom: 5, float: "left"}}
                                    control={<Checkbox value="remember" color="primary"/>}
                                    checked={checked} id={"accept"} onChange={(event) => {
                                    setChecked(event.target.checked)
                                }}
                                />
                                <ModalPolicy/>
                            </Grid>
                            <Grid item xs={6} sm={6} style={{display: "inline"}}>


                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{mt: 3, ml: 1, textAlign: "center"}}
                                    endIcon={<SendIcon/>}
                                    onClick={acceptCndpBeforeLogin}
                                >
                                    {t('accepter')}
                                </Button>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>

            </Box>

        </React.Fragment>
    );
}
