import {
    Alert,
    AlertTitle,
    Autocomplete,
    CircularProgress, Dialog,
    IconButton,
    OutlinedInput,
    Select,
    Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import "../../preinscriptionSteps/filiere.css"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {isMobile} from "react-device-detect";

import Grid from "@mui/material/Grid";
import i18n from "../../../multilingue/i18n";
import Link from "@mui/material/Link";
import InfoCndp from "../../../components/Widget/Cndp/InfoCndp";

import SendIcon from "@mui/icons-material/Send";
import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";

import {useHistory} from "react-router-dom";
import {BASE_URL, baseAxios, baseAxiosCondidature, Version} from "../../../repository/baseAxios";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Close, Edit} from "@material-ui/icons";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import {Download} from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

var type_diplomes = [];
let id_t=0;
let annee_univ_exist=false;
export default function ChoixDiplomes(props) {
    const [loading, setloading] = useState(false);
    const [loadingContent, setloadingContent] = useState(true);
    const [diplomes, setDiplomes] = useState({});
    const [parcours, setParcours] = useState([]);
    const [parcourValue, setParcourValue] = useState(0);

    const [haveNote, setHaveNote] = useState(false);
    const [etablissements, setEtablissements] = useState({});

    const [parcourChoisi, setParcourChoisi] = useState({});
    const [hideParcours, setHideParcours] = useState(false);

    const [loadingOne, setLoadingOne] = useState(false);
    const [etatCivil, setEtatCivil] = useState();
    const [canModify, setCanModify] = useState(true);
    const [niveaUniversitaire, setNiveaUniversitaire] = useState(null);
    const [open, setOpen] = useState(false);
    function createData(name, description, ) {
        return { name, description, };
    }

    const rows = [
        createData('Frozen yoghurt', 159, ),
        createData('Ice cream sandwich', 237, ),
        createData('Eclair', 262, ),
        createData('Cupcake', 305, ),
        createData('Gingerbread', 356, ),
    ];
    var history = useHistory();
    var {t} = useTranslation();

    const save_parcours_data = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/save_parcours_data`, params)

            .then(async res => {
                if (res.data.status === 200 && res.data.params) {
                    if (res.data.params) {
                        setloading(false)
                        await Swal.fire({
                            title: "Les données du parcours universitaire sont bien enregistrées.",
                            icon: 'success',
                            confirmButtonText: t('suivant'),
                            confirmButtonColor: 'green',
                            color: 'success'
                        }).then((result) => {
                            if (result.isConfirmed) {

                                props.EtapeSuivant();
                            }
                        })
                    } else {
                        setHideParcours(false)
                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                    }

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merce de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }


            })
    }

    const get_data_by_parcours = (params) => {
        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/commun/get_data_by_parcours`, params)

            .then(async res => {
                if (res.data.status === 200 && res.data.params) {
                    if (res.data.params) {
                        setTimeout(
                            () => {
                                setDiplomes(res.data.params)
                                type_diplomes = res.data.params.diplomes
                               // setUniversitys(res.data.params.univirsites);
                               // setEtabsUniv(res.data.params.etablissements);
                                setHideParcours(true)

                                setCanModify(true)
                                setloadingContent(false)
                            }, 1000)
                    } else {
                        setHideParcours(false)
                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                    }

                } else if (res.data.status === -1) {

                    Swal.fire({
                        title: "Erreur Merce de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }


            })
    }
    const get_parcours_data = (params) => {

        baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/get_parcours_data`, params)

            .then(async res => {
                if (res.data.status === 200 && res.data.params.semesters.length>0) {
                    setTimeout(
                        () => {
                            if (res.data.params.semesters.length>0) {
                                setHaveNote(true)
                                let semesters = res.data.params.semesters
                                semesters.map((v, index) => {

                                        let semester = document.getElementById("semester_" + v.semestre_id+"_parc_"+v.type_diplome_id);
                                        let annee_v_semestre=document.getElementById("annee_v_semester_" + v.semestre_id+"_parc_"+v.type_diplome_id);
                                        if(semesters && annee_v_semestre ){


                                            if (v.note && v.annee_validation ){
                                                semester.value=v.note;
                                                annee_v_semestre.value=v.annee_validation;

                                                setCanModify(false)
                                            }
                                        }
                                    }
                                )


                            }else{
                                setHaveNote(false)
                            }

                            if (res.data.params.typeDiplome.length>0) {
                                setHaveNote(true)
                                let diplomes = res.data.params.typeDiplome
                                diplomes.map((dip) => {

                                    id_t=dip.parcour_id;
                                    setParcourValue(id_t)
                                        let diplome = document.querySelector("#diplome_" + dip.type_diplome_id )
                                        let intitule_dip = document.querySelector("#intitule_dip_" + dip.type_diplome_id )
                                        let etablissement_id = document.querySelector("#etablissement_id_" + dip.type_diplome_id )
                                        let universite_id = document.querySelector("#universite_id_" + dip.type_diplome_id ) ? document.querySelector("#universite_id_" + dip.type_diplome_id) : null;

                                        if(diplome && intitule_dip){
                                            diplome.value=dip.diplome_id;
                                            intitule_dip.value=dip.diplome_name;
                                            annee_univ_exist=true;
                                            universite_id.value=dip.universite_id;
                                            handlchangeuniv(dip.type_diplome_id);
                                            etablissement_id.value=dip.etablissement_id;
                                            setCanModify(false);
                                        }else{
                                            setCanModify(true)
                                        }




                                    }
                                )


                            }else{
                                setHaveNote(false)
                            }


                        }, 1000)

                } else if (res.data.status === -1) {
                    setCanModify(true)
                    Swal.fire({
                        title: "Erreur Merce de ressayer au plus tard",
                        text: res.data.message,
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })

                }else{
                    setCanModify(true)
                }


            })
    }
    useEffect(() => {
        if (hideParcours) {

            get_parcours_data()
        }
    }, [hideParcours]);

    useEffect(() => {

        let data = new FormData();


        data.set('niveau', niveaUniversitaire)



        const get_parcours = (params) => {
            baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/commun/get_parcours`, params)

                .then(async res => {
                    if (res.data.status === 200) {
                        setParcours([]);
                        setTimeout(
                            () => {
                                setParcours(res.data.params)
let p=res.data.params.find(item => item.type_diplome_id === id_t) || null;
                         setParcourChoisi(p);
                         setParcourValue(id_t)


                                setloadingContent(false)
                            }, 1200)
                    } else if (res.data.status === -1) {

                        Swal.fire({
                            title: "Erreur Merce de ressayer au plus tard",
                            text: res.data.message,
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })

                    }


                })
        }
        setTimeout(()=>{
            get_parcours_data()
            get_parcours(data)

        },200)



    }, [niveaUniversitaire]);
    const removeParcours = (id) => {
        Swal.fire({
            title: "Toutes les informations concernant votre parcours universitaire, y compris les notes et les fichiers importés, seront supprimées ! ",
            showCancelButton: true,
            confirmButtonText: 'Supprimer',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                baseAxiosCondidature.post(`${BASE_URL}/candidature/${Version}/candidature/remove_parcours`)
                    .then(res => {
                        if (res.data.status === 200) {
                            Swal.fire("Votre parcours a bien été supprimé.", '', 'success')
                            setHaveNote(false)
                            setNiveaUniversitaire(null);
                        } else if (res.data.status === -1) {

                            Swal.fire({

                                text: res.data.message,
                                icon: 'error',
                                confirmButtonText: t('fermer'),
                                confirmButtonColor: 'red',
                                color: 'red'
                            })

                        }


                    })
            }
        })


    }
    const handlChange = (id) => {
        Swal.fire({

            text: t('info_parcours_confirm'),
            icon: 'info',
            confirmButtonText: 'Confirmer',
            confirmButtonColor: 'primary',
            color: 'red'
        })
        setParcourValue(id);

    }
    const handleChangeniveaUniversitaire = (event) => {
        if (event.target.value){
            setNiveaUniversitaire(event.target.value)
        }



    }
    const etapeget_data_by_parcours = () => {
        annee_univ_exist=false;
        get_parcours_data()
        if (parcourValue!==0 || id_t!==0) {
            setloadingContent(true)
            let data = new FormData()
            let id_parcour=parcourValue ? parcourValue : id_t;
            data.set('type_diplome_id',id_parcour )
            get_data_by_parcours(data)
            setLoadingOne(false)
        }
    }
    const handlformsubmit = (event) => {
        event.preventDefault();

        setLoadingOne(true)

        if (parcourValue!==0) {
            setloadingContent(true)
            let data = new FormData()
            data.set('type_diplome_id', parcourValue)
            get_data_by_parcours(data)
            setLoadingOne(false)
        } else {

            setLoadingOne(false)
            Swal.fire({
                title: "Erreur ! ",
                text: "Merci de choisir un parcours",
                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
        }

    }
    const handlformsubmitDiplome = (event) => {
        event.preventDefault();
        setloading(true);
        let semestre = [];
        let diplomes_v = [];
        let type_diplomes_val = [];
        let error = false;
        var file_diplome_id_v = "";
        var file_note_id_v="";
        var data = new FormData();
        var fileName = "";
        var fileName_releve = "";
        if (canModify) {
            Object.values(diplomes).map((parc, index) => {
                    semestre = []
                    diplomes_v = []
                    document.getElementById('error_' + parc.parcours_id).style.display = "none";
                {
                    parc.possed_pas_note===false &&
                    (      parc.semester.map((sem, index) => {
                        document.getElementById('error_s' + sem.semestre_id).style.display = "none";

                        var note_semestre = document.getElementById("semester_" + sem.semestre_id+"_parc_"+parc.type_diplome_id) ? document.getElementById("semester_" + sem.semestre_id+"_parc_"+parc.type_diplome_id) : null;
                        var annee_valid_semestre = document.getElementById("annee_v_semester_" + sem.semestre_id+"_parc_"+parc.type_diplome_id) ? document.getElementById("annee_v_semester_" + sem.semestre_id+"_parc_"+parc.type_diplome_id) : null;


                        if (note_semestre) {
                            if (note_semestre.value <= 20) {
                                if ("semester_" + sem.semestre_id+"_parc_"+parc.type_diplome_id === note_semestre.id && "annee_v_semester_"+ sem.semestre_id+"_parc_"+parc.type_diplome_id === annee_valid_semestre.id) {
                                    let regex = /^\d{4}$/;


                                    if (annee_valid_semestre.value && note_semestre.value && regex.test(annee_valid_semestre.value)) {

                                        semestre.push({
                                                "semestre_id": sem.semestre_id,
                                                "note_semestre": note_semestre.value,
                                                "annee_validation": annee_valid_semestre.value
                                            }
                                        )
                                    } else {
                                        setloading(false)
                                        Swal.fire({
                                            title: "Erreur ! ",
                                            text: "Note S" + sem.semestre_id + " ou année validaion est invalide ",
                                            icon: 'error',
                                            confirmButtonText: 'Confirmer',
                                            confirmButtonColor: 'red',
                                            color: 'red'
                                        })
                                        error = true;

                                        return false;
                                    }

                                }

                            } else {
                                document.getElementById('error_s' + sem.semestre_id+"_parc_"+parc.type_diplome_id).style.display = "block";
                                setloading(false)
                                Swal.fire({
                                    title: "Erreur ! ",
                                    text: "Note S" + sem.semestre_id + " est invalide",
                                    icon: 'error',
                                    confirmButtonText: 'Confirmer',
                                    confirmButtonColor: 'red',
                                    color: 'red'
                                })
                                error = true;

                                return false;

                            }
                        } else {
                            setloading(false)
                            Swal.fire({
                                title: "Erreur ! ",
                                text: "Note semestre n'existe pas ou année validation non valide",
                                icon: 'error',
                                confirmButtonText: 'Confirmer',
                                confirmButtonColor: 'red',
                                color: 'red'
                            })
                            error = true
                            return false;

                        }

                    }) )
                }
                let etablissement_id = document.querySelector("#etablissement_id_" + parc.type_diplome_id ) ? document.querySelector("#etablissement_id_" + parc.type_diplome_id) : null;
                let universite_id = document.querySelector("#universite_id_" + parc.type_diplome_id ) ? document.querySelector("#universite_id_" + parc.type_diplome_id) : null;

                    let diplome_value = document.querySelector("#diplome_" + parc.type_diplome_id ) ? document.querySelector("#diplome_" + parc.type_diplome_id) : null;
                let intitule_dip = document.querySelector("#intitule_dip_" + parc.type_diplome_id ) ? document.querySelector("#intitule_dip_" + parc.type_diplome_id) : null;

                let file_releve = document.querySelector("#file_releve_note_" + parc.type_diplome_id ) ? document.querySelector("#file_releve_note_" + parc.type_diplome_id) : null;

                let    type_diplome  =diplome_value.options[diplome_value.selectedIndex].text
                if (!intitule_dip.value || !intitule_dip){
                    setloading(false)
                    document.getElementById("error_intitule_"+parc.type_diplome_id).style.display="block"
                        Swal.fire({
                            title: "Erreur ! ",
                            text: "merci d'entrer l'intitule de diplome  ",
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                        error = true;

                        return  false;


                }else{
                    document.getElementById("error_intitule_"+parc.type_diplome_id).style.display="none"

                }
                if (!etablissement_id.value || !etablissement_id){
                    setloading(false)
                    Swal.fire({
                        title: "Erreur ! ",
                        text: "merci de choisir  l'établissement  ",
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    error = true;

                    return  false;


                }
                if (!universite_id.value || !universite_id){
                    setloading(false)
                    Swal.fire({
                        title: "Erreur ! ",
                        text: "merci de choisir  l'université  ",
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    error = true;
                    return  false;

                }
                    if (diplome_value ) {
                        let filev = document.querySelector('#file_' + parc.parcours_id).value;
                        fileName = document.querySelector('#file_' + parc.parcours_id).files[0];
                        let extension = filev.substring(filev.lastIndexOf('.') + 1);
                        file_diplome_id_v = "file_diplome_" + diplome_value.value

                        if (extension === "pdf") {

                            diplomes_v.push(
                                {
                                    "diplome_name": type_diplome,
                                    "diplome_intitule": intitule_dip.value,
                                    "diplome_id": diplome_value.value,
                                    "etablissement_id":etablissement_id.value,
                                }
                            )
                        } else {
                            setloading(false)
                            document.getElementById('error_' + parc.parcours_id).style.display = "block";
                            Swal.fire({
                                title: "Erreur ! ",
                                text: "Merci de choisir un fichier PDF",
                                icon: 'error',
                                confirmButtonText: 'Confirmer',
                                confirmButtonColor: 'red',
                                color: 'red'
                            })
                            error = true
                            return  false;
                        }

                    } else {
                        setloading(false)
                        Swal.fire({
                            title: "Erreur ! ",
                            text: "Diplome n'existe pas",
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                        error = true
                       return false;
                    }
                if (file_releve ) {

                    let filev = document.querySelector('#file_releve_note_' + parc.parcours_id).value;
                    fileName_releve = document.querySelector('#file_releve_note_' + parc.parcours_id).files[0];
                    let extension = filev.substring(filev.lastIndexOf('.') + 1);
                    file_note_id_v = "file_releve_note_" + diplome_value.value;

                    if (extension !== "pdf") {

                        document.getElementById('error_releve_note_' + parc.parcours_id).style.display = "block";
                        Swal.fire({
                            title: "Erreur ! ",
                            text: "Merci de choisir un fichier PDF",
                            icon: 'error',
                            confirmButtonText: 'Confirmer',
                            confirmButtonColor: 'red',
                            color: 'red'
                        })
                        error = true
                        return  false;
                    }else{
                        document.getElementById('error_releve_note_' + parc.parcours_id).style.display = "none";

                    }

                } else {
                    setloading(false)
                    Swal.fire({
                        title: "Erreur ! ",
                        text: "Relevée de cnotes n'existe pas",
                        icon: 'error',
                        confirmButtonText: 'Confirmer',
                        confirmButtonColor: 'red',
                        color: 'red'
                    })
                    error = true
                    return false;
                }

                    if (!error) {

                        type_diplomes_val.push({
                            "semestres": semestre,
                            "diplome": diplomes_v,
                            "type_diplome_id": parc.type_diplome_id,
                            "libelle_type_diplome": parc.libelle_type_diplome
                        })
                        data.set(file_diplome_id_v, fileName)
                        data.set(file_note_id_v, fileName_releve)

                    }
                }
            )


            if (!error) {
                console.log(type_diplomes_val)
                data.set("data", JSON.stringify(type_diplomes_val))
                data.set("parcour_id", parcourValue)
                save_parcours_data(data)
            }else{
                setloading(false)
                return  false
            }
        }else{
            setloading(false)
            Swal.fire({
                title: "Erreur ! Merce de ressayer au plus tard",

                icon: 'error',
                confirmButtonText: 'Confirmer',
                confirmButtonColor: 'red',
                color: 'red'
            })
            return false;
        }


        //  props.EtapeSuivant();


    }

const handlchangeuniv = (id_type_diplome) => {
    annee_univ_exist=true;
    let universite_id = document.querySelector("#universite_id_"+id_type_diplome ) ? document.querySelector("#universite_id_" +id_type_diplome) : null;
    var x = document.getElementById("etablissement_id_"+id_type_diplome);
    var i, L = x.options.length - 1;

    for(i = L; i >= 0; i--) {
        x.remove(i);
    }

    Object.values(diplomes).map((parc) => {
        Object.values(parc.etablissements).map((et) => {

            if (parseInt(universite_id.value)===parseInt(et.universite_id)){

                var opt = document.createElement('option');
                opt.value = et.etablissement_id;
                opt.innerHTML = et.libelle_etablissement_fr;
                x.appendChild(opt);
            }
    })


})
}

    return (
        <React.Fragment>
            {
                !loadingContent ?
                    (<>
                        {!hideParcours ?
                            <>
                                <Box component="form" container spacing={3}
                                     onSubmit={handlformsubmit}>
                                    <Grid container
                                          direction="column"
                                          alignItems="center"
                                          justify="center" spacing={2}
                                    >
                                        <Grid item xs={12} sm={12} dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                                            <Alert severity="error" ><Typography fontWeight={"bold"}>{t('info_parcours')}</Typography></Alert>
                                        </Grid>
                                        {   haveNote  ?
                                       <>     <Grid item xs={12} sm={12} >
                                            <Alert variant="filled" sx={{fontSize: 18, bgcolor: "#007fa4",textTransform:"uppercase"}}
                                                   action={

                                                           <ButtonGroup variant="contained"
                                                                        aria-label="outlined  button group">
                                                               <Button variant="contained" color={"error"}
                                                                       onClick={() => removeParcours()}
                                                                       size={"small"} startIcon={<DeleteIcon/>}>
                                                               </Button>
                                                           </ButtonGroup>

                                                   }
                                            >
                                            <AlertTitle
                                            sx={{fontSize: 18}}>parcours universitaire choisi:  <strong>{
                                                parcourChoisi.libelle_type_diplome.replace(/_/g, ' => ')

                                            }</strong></AlertTitle>


                                            </Alert>

                                            </Grid>    <Grid item xs={12}>
                                           <Button

                                               variant="contained"
                                               sx={{mt: 3, ml: 1, textAlign: "center"}}
                                               endIcon={<SendIcon/>}
                                               onClick={()=>{etapeget_data_by_parcours()}}
                                           >
                                               {t('suivant')}
                                           </Button>
                                       </Grid></>:  <>
                                             {/*   <Grid item xs={12} sm={12} >
                                                    <Button
                                                        type="button"
                                                        sx={{ ml: 1, textAlign: "center"}}

                                                        onClick={()=>{ setOpen(true)}}
                                                        color={"primary"}
                                                        size="medium"

                                                    >
                                                        liste des abreviation des parcours
                                                    </Button>
                                                    <Dialog
                                                        style={{padding:10}}
                                                        open={open}
                                                        keepMounted
                                                        aria-describedby="alert-dialog-slide-description"
                                                    >
                                                        <DialogContent style={{width : isMobile ? "" : "600px" }}>
                                                            <TableContainer component={Paper}>
                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell >Abreviation diplome</TableCell>
                                                                            <TableCell >description</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {rows.map((row) => (
                                                                            <TableRow
                                                                                key={row.name}

                                                                            >
                                                                                <TableCell component="td" >
                                                                                    {row.name}
                                                                                </TableCell>
                                                                                <TableCell  component="td" >{row.description}</TableCell>
                                                                              </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            <DialogActions>
                                                                <Button
                                                                    type="button"
                                                                    sx={{textAlign: "center"}}
                                                                    endIcon={<Close />}
                                                                    onClick={()=>{ setOpen(false)}}
                                                                    size="medium"


                                                                >
                                                                    {t('fermer')}
                                                                </Button>

                                                            </DialogActions>
                                                        </DialogContent>
                                                    </Dialog>
                                                </Grid>*/}
                                            <Grid item xs={12} sm={12} >
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="controlled-radio-buttons-group"
                                                        value={niveaUniversitaire}
                                                        onChange={(event) => handleChangeniveaUniversitaire(event)}
                                                    >
                                                        <FormControlLabel
                                                            value="2"
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 30,
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <span style={{ fontSize: '18px',fontWeight:"bold" }}> {/* Augmentez la taille de la police ici */}
                                                                    Parcours Bac+2 : Si vous avez obtenu un diplôme de niveau Bac+2 ou équivalent, cliquez ici.
            </span>
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            value="3"
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 30,
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <span style={{ fontSize: '18px',fontWeight:"bold" }}> {/* Augmentez la taille de la police ici */}
                                                                    Parcours Bac+3 : Si vous avez obtenu un diplôme de niveau Bac+3 ou équivalent, cliquez ici.
            </span>
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            value="5"
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: 30,
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <span style={{ fontSize: '18px',fontWeight:"bold" }}> {/* Augmentez la taille de la police ici */}
                                                                    Parcours Bac+5 : Si vous avez obtenu un diplôme de niveau Bac+5 ou équivalent, cliquez ici.
            </span>
                                                            }
                                                        />
                                                    </RadioGroup>

                                                </FormControl>
                                            </Grid>
                                                { niveaUniversitaire &&
                                                <>
                                                    <Grid item xs={12} sm={12} style={{marginTop: 8, marginBottom: 8}}>
                                                    <FormControl>
                                                        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>

                                                        {
                                                          parcours.length>0 ?  (Object.values(parcours).map((parc, index) => {

                                                                        return (

                                                                            <label className={"container"}
                                                                                   style={{marginBottom: 8, marginTop: 8}}
                                                                                   key={parc.type_diplome_id}>
                                                                                <input className={"test"} name={'bac_opi_'}
                                                                                       onClick={() => handlChange(parc.type_diplome_id)}
                                                                                       type={'radio'}
                                                                                       id={parc.libelle_type_diplome}
                                                                                       value={parcourValue}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                                <span
                                                                                    className="textmark">{parc.libelle_type_diplome}</span>


                                                                            </label>


                                                                        )


                                                                    }
                                                                )
                                                            )  :  <Grid item xs={12} sm={12} >
                                                              <CircularProgress/>
                                                          </Grid>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                                    <Grid item xs={3}>
                                                    <Button
                                                    variant="contained"
                                                    type="submit"
                                                    sx={{mt: 3, textAlign: "center"}}
                                                    endIcon={<SendIcon/>}
                                                    disabled={loadingOne}
                                                    >
                                                {t('enregistrer')}
                                                    </Button>
                                                    </Grid> </> }</>}

                                        <Grid item xs={3}>
                                        <Button variant={"contained"} color={"warning"} onClick={props.EtapePrec} sx={{mt: 3, ml: 1}} size="medium" startIcon={<ArrowBackIcon />}>
                                            {t('retour_etape')}
                                        </Button>
                                        </Grid>

                                    </Grid>
                                </Box> </>
                            :
                            <>
                                <Box component={"form"} onSubmit={handlformsubmitDiplome}>
                                    <Grid item xs={12} sm={12} >
                                        <Alert severity="error" ><Typography fontWeight={"bold"}>Les candidats qui possèdent un relevé de notes annuel doivent
                                            dupliquer la moyenne générale annuelle dans les champs correspondants aux semestres.</Typography></Alert>
                                    </Grid>
                                    {!canModify && (
                                        <Grid item xs={6} dir={i18n.language === "ar" ? "rtl" : "ltr "}>
                                            <Button

                                                variant="contained"
                                                sx={{mt: 3, ml: 1, textAlign: "center"}}
                                                endIcon={<Edit style={{marginLeft: 4 ,marginRight:4}}/>}
                                                color={"success"}
                                                onClick={(event)=>{event.preventDefault();setCanModify(true);}}
                                            >
                                                {t('modifier_informations')}
                                            </Button>
                                        </Grid>
                                    )}
                                    {diplomes && (Object.values(diplomes).map((dip, indexdip) => {
                                                return (
                                                        <fieldset
                                                            style={{padding: 15, marginTop: 10, borderColor: "orange"}} key={dip.type_diplome_id}>
                                                            <legend>{dip.libelle_type_diplome}</legend>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                            >
                                                                {(dip.semester && dip.possed_pas_note===false ) &&
                                                                    (
                                                                        dip.semester.map((sem, indexse) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid item xs={6} sm={3} key={sem.semestre_id} >
                                                                                            <TextField
                                                                                                required
                                                                                                id={"semester_" + sem.semestre_id+"_parc_"+dip.type_diplome_id}
                                                                                                label={sem.nom}
                                                                                                placeholder={"Note semestre " + sem.semestre_id}
                                                                                                InputProps={{
                                                                                                    inputProps: {
                                                                                                        min: 0,
                                                                                                        max: 20,
                                                                                                        step: "0.01"
                                                                                                    }
                                                                                                }}
                                                                                                InputLabelProps={{shrink:  true }}
                                                                                                type={"number"}
                                                                                                fullWidth
                                                                                                autoComplete="off"
                                                                                                variant="outlined"
                                                                                                disabled={!canModify}
                                                                                            />
                                                                                            <span
                                                                                                id={"error_s" + sem.semestre_id}
                                                                                                style={{
                                                                                                    display: "none",
                                                                                                    color: "red",
                                                                                                    fontSize: 12,
                                                                                                    paddingLeft: 12,
                                                                                                    paddingTop: 5
                                                                                                }}>Note  S{sem.semestre_id} est invalide</span>
                                                                                        </Grid>
                                                                            <Grid item xs={6} sm={3} key={indexse} >
                                                                                <TextField
                                                                                    required
                                                                                    id={"annee_v_semester_" + sem.semestre_id+"_parc_"+dip.type_diplome_id}
                                                                                    label={"Année validation"}
                                                                                    placeholder={"année validation  S" + sem.semestre_id}
                                                                                    InputLabelProps={{shrink:  true }}
                                                                                    type={"text"}
                                                                                    fullWidth
                                                                                    autoComplete="off"
                                                                                    variant="outlined"
                                                                                    disabled={!canModify}
                                                                                    helperText={"example : XXXX"}
                                                                                />

                                                                            </Grid>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        )
                                                                    )
                                                                }

                                                                <Grid item xs={12} sm={4}  >
                                                                    <TextField
                                                                        required
                                                                        id={"intitule_dip_" +  dip.type_diplome_id}
                                                                        label={"Spécialité de diplôme"}
                                                                        placeholder={"Spécialité de diplôme"}
                                                                        InputLabelProps={{shrink:  true }}
                                                                        type={"text"}
                                                                        fullWidth
                                                                        autoComplete="off"
                                                                        variant="outlined"
                                                                        disabled={!canModify}
                                                                    />
                                                                    <span
                                                                        id={"error_intitule_" + dip.type_diplome_id}
                                                                        style={{
                                                                            display: "none",
                                                                            color: "red",
                                                                            fontSize: 12,
                                                                            paddingLeft: 12,
                                                                            paddingTop: 5
                                                                        }}>Spécialité de diplôme   est invalide</span>
                                                                </Grid>
                                                                <Grid item xs={6} sm={4} textAlign={"right"}  >
                                                                <Typography fontSize={18}>Votre diplôme</Typography>
                                                                </Grid>

                                                                <Grid item xs={12} sm={4}>
                                                                        <select
                                                                            required
                                                                            className={"minimal"}
                                                                            style={{   width: isMobile ? 256: 330,height:56}}
                                                                            id={"diplome_" + dip.type_diplome_id}

                                                                            disabled={!canModify}
                                                                        >
                                                                            {dip.diplomes &&
                                                                                (
                                                                                    dip.diplomes.map((d, index) => {



                                                                                            return (

                                                                                                <option key={d.diplome_id} value={d.diplome_id}>{d.libelle_diplome_fr}</option>


                                                                                            )

                                                                                        }
                                                                                    )
                                                                                )
                                                                            }
                                                                        </select>


                                                                </Grid>


                                                                <Grid item xs={6} sm={2} textAlign={"right"} >

                                                                    <Typography fontSize={18}>  Université </Typography>
                                                                </Grid>

                                                                <Grid item xs={12} sm={4}>
                                                                    <select
                                                                        required
                                                                        className={"minimal"}
                                                                        style={{   width: isMobile ? 256: 320,height:56}}
                                                                        id={"universite_id_" + dip.type_diplome_id}
                                                                        onChange={()=> {
                                                                            handlchangeuniv(dip.type_diplome_id)
                                                                        }}
                                                                        disabled={!canModify}
                                                                    >
                                                                        {dip.univirsites &&
                                                                            (
                                                                                dip.univirsites.map((un, index) => {
                                                                                        return (

                                                                                            <option key={un.id} value={un.id}>{un.univ_fr}</option>


                                                                                        )
                                                                                    }
                                                                                )
                                                                            )
                                                                        }
                                                                    </select>


                                                                </Grid>
                                                                <Grid item xs={6} sm={2} textAlign={"left"}  >
                                                                    <Typography fontSize={18}>  Etablissement  </Typography>
                                                                </Grid>

                                                                <Grid item xs={12} sm={4}>
                                                                    <select
                                                                        required
                                                                        className={"minimal"}
                                                                        style={{   width: isMobile ? 256: 330,height:56}}
                                                                        id={"etablissement_id_" + dip.type_diplome_id}
                                                                        disabled={!canModify}
                                                                    >
                                                                        {dip.etablissements   &&
                                                                            (
                                                                                dip.etablissements.map((et, index) => {
                                                                                    if (annee_univ_exist){


                                                                                        return (

                                                                                            <option key={et.etablissement_id} value={et.etablissement_id}>{et.libelle_etablissement_fr}</option>


                                                                                        )
                                                                                    }
                                                                                    }
                                                                                )
                                                                            )
                                                                        }
                                                                    </select>


                                                                </Grid>
                                                                {canModify  ? <Grid item xs={12} sm={6}>
                                                                        <TextField
                                                                            required
                                                                            id={"file_" + dip.parcours_id}
                                                                            label={t('diplome_bac_two')}
                                                                            fullWidth
                                                                            autoComplete="given-name"
                                                                            variant="outlined"
                                                                            type={"file"}
                                                                            inputProps={{accept: ".pdf"}}
                                                                            InputLabelProps={{shrink: true}}
                                                                        />
                                                                        <span id={"error_" + dip.parcours_id} style={{
                                                                            display: "none",
                                                                            color: "red",
                                                                            fontSize: 12
                                                                        }}>Importer le fichier format PDF </span>
                                                                    </Grid> :
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Alert variant="filled" severity="success">
                                                                            {t('importe_avec_succes')}
                                                                        </Alert>

                                                                    </Grid>
                                                                }
                                                                {canModify  ? <Grid item xs={12} sm={6}>
                                                                        <TextField
                                                                            required
                                                                            id={"file_releve_note_" + dip.parcours_id}
                                                                            label={"importer  relevés de notes (PDF)"}
                                                                            fullWidth
                                                                            autoComplete="given-name"
                                                                            variant="outlined"
                                                                            type={"file"}
                                                                            inputProps={{accept: ".pdf"}}
                                                                            InputLabelProps={{shrink: true}}
                                                                        />
                                                                        <span id={"error_releve_note_" + dip.parcours_id} style={{
                                                                            display: "none",
                                                                            color: "red",
                                                                            fontSize: 12
                                                                        }}>Error ! Importer le fichier relevés de notes format PDF </span>
                                                                    </Grid> :
                                                                      <Grid item xs={12} sm={6}>
                                                                    <Alert variant="filled" severity="success">
                                                                {t('importe_avec_succes')}
                                                                    </Alert>

                                                                    </Grid>
                                                                }

                                                            </Grid>
                                                        </fieldset>
                                                )
                                            }
                                        )
                                    )
                                    }

                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                                            <InfoCndp/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {canModify ? <Button
                                                variant="contained"
                                                type="submit"
                                                sx={{mt: 3, ml: 1, textAlign: "center"}}
                                                endIcon={<SendIcon/>}
                                                disabled={loading}

                                            >
                                                {t('enregistrer')}
                                            </Button> :
                                                <>
                                                <Grid item xs={3}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    sx={{mt: 3, mb: 3, ml: 1, textAlign: "center"}}
                                                    endIcon={<SendIcon/>}
                                                    onClick={() => props.EtapeSuivant()}
                                                >
                                                    {t('suivant')}
                                                </Button>
                                                </Grid>
                                                </>
                                            }
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button variant={"contained"} color={"warning"} onClick={()=>{setHideParcours(false); setParcourValue(0); annee_univ_exist=false;}} sx={{mt: 3, ml: 1}} size="medium" startIcon={<ArrowBackIcon />}>
                                                RETOUR AU CHOIX PARCOURS
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </>
                        }

                    </>)

                    :
                    <Box sx={{display: 'flex'}}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={12} sm={12} style={{marginTop: 14, marginBottom: 14}}>
                                <CircularProgress/>
                            </Grid>
                        </Grid>
                    </Box>


            }
        </React.Fragment>
    );

}